import React, {useState, useEffect} from 'react'
import { Dialog, DialogTitle, DialogContent, Table, TableBody, TableRow, TableCell } from '@mui/material'
import localization from '../utils/localization'
import UserUtils from '../utils/user'
import moment from 'moment'

function ModalUserInfo(props) {
  const [userInfo, setUserInfo] = useState(UserUtils.getUserInfo())

  useEffect(() => {
    if (props.open) setUserInfo(UserUtils.getUserInfo())
  }, [props.open])

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogTitle>{localization.strings.userInfo}</DialogTitle>
      <DialogContent>
        <table>
          <tbody>
            <tr>
              <td style={{
                width: '1rem',
                height: '2rem',
                whiteSpace: 'nowrap',
              }}>{localization.strings.account}</td>
              <td style={{
                textAlign: 'left',
                paddingLeft: '2rem',
              }}><pre style={{
                fontSize: '1rem',
                margin: '0rem',
                width: '20rem',
              }}>{userInfo.account}</pre></td>
            </tr>
            <tr>
              <td style={{
                width: '1rem',
                height: '2rem',
                whiteSpace: 'nowrap',
              }}>{localization.strings.expiryTime}</td>
              <td style={{
                textAlign: 'left',
                paddingLeft: '2rem',
              }}><pre style={{
                fontSize: '1rem',
                margin: '0rem',
                width: '20rem',
              }}>{moment(userInfo.expiry_time*1000).format('YYYY-MM-DD HH:mm:ss')}</pre></td>
            </tr>
          </tbody>
        </table>
      </DialogContent>
    </Dialog>
  )
}

export default ModalUserInfo