import ServerUtils from './server.js'
import AesUtils from './aes.js'
import UserUtils from './user.js'
import ColorUtils from './colors'
import localization from './localization.js'

const tagType = 0;
const passwordType = 1;
const textType = 2;
const totpType = 3;
const blockchainType = 4;

var contents = []

var passListener = null
var callPassListener = function() {
  if (typeof passListener === 'function') {
    passListener(contents.filter(item => item.type === passwordType))
  }
}

var textListener = null
var callTextListener = function() {
  if (typeof textListener === 'function') {
    textListener(contents.filter(item => item.type === textType))
  }
}

var totpListener = null
var callTotpListener = function() {
  if (typeof totpListener === 'function') {
    totpListener(contents.filter(item => item.type === totpType))
  }
}

var blockchainListener = null
var callBlockChainListener = function() {
  if (typeof blockchainListener === 'function') {
    blockchainListener(contents.filter(item => item.type === blockchainType))
  }
}

var contentListener = null
var callContentListener = function() {
  if (typeof contentListener === 'function') {
    contentListener(contents)
  }
}

function callListeners() {
  callPassListener()
  callTextListener()
  callTotpListener()
  callBlockChainListener()
  callContentListener()
}

export default {
  getTagType: function() {
    return tagType
  },
  getPasswordType: function() {
    return passwordType
  },
  getTextType: function() {
    return textType
  },
  getTotpType: function() {
    return totpType
  },
  getBlockChainType: function() {
    return blockchainType
  },
  getContent: function() {
    ServerUtils.getContent().then(async (resp) => {
      if (!resp.ok) {
        try {
          let respJson = await resp.json()
          if (respJson.errno !== 0) {
            return
          }
        } catch(e) {

        }
        return
      }
      let respJson = await resp.json()
      if (respJson.errno !== 0) {
        return
      }
      var userInfo = UserUtils.getUserInfo()
      var encryptedContents = respJson.data.contents
      contents = []
      for (var i=0;i<encryptedContents.length;i++) {
        var contentStr = AesUtils.decryptData(userInfo.account, UserUtils.getMasterPass(), encryptedContents[i].encrypted_data)
        if (contentStr === "") continue

        // 可能会失败
        let decryptedContent = JSON.parse(contentStr)

        if (decryptedContent.tags === undefined) {
          decryptedContent.tags = []
        }
        contents.push({
          id: encryptedContents[i].id,
          version: encryptedContents[i].version,
          type: decryptedContent.type,
          title: decryptedContent.title,
          content: decryptedContent.content,
          account: decryptedContent.account,
          last_modify_time: encryptedContents[i].last_modify_time,
          color: decryptedContent.color,
          extra: decryptedContent.extra,
          tags: decryptedContent.tags,
        })
      }
      callListeners()
    })
  },
  addContent: function(content) {
    contents = [content].concat(contents)
    callListeners()
  },
  updateContent: function(content) {
    for (var i=0;i<contents.length;i++) {
      if (contents[i].id === content.id) {
        contents[i] = content
      }
    }
    callListeners()
  },
  updateContents: function(contentsIn) {
    for (var i=0;i<contents.length;i++) {
      for (var j=0;j<contentsIn.length;j++) {
        if (contents[i].id === contentsIn[j].id) {
          contents[i] = contentsIn[j]
        }
      }
    }
    callListeners()
  },
  deleteContent: function(id) {
    for (var i=0;i<contents.length;i++) {
      if (contents[i].id === id) {
        contents.splice(i, 1)
        break
      }
    }
    callListeners()
  },
  setPassListener: function(pl) {
    passListener = pl
    callListeners()
  },
  setTextListener: function(tl) {
    textListener = tl
    callListeners()
  },
  setTotpListener: function(totpl) {
    totpListener = totpl
    callListeners()
  },
  setBlockChainListener: function(bl) {
    blockchainListener = bl
    callListeners()
  },
  setContentListener: function(cl) {
    contentListener = cl
    callListeners()
  },
  listContents: function(keyword) {
    if (keyword === '') return [];
    return contents.filter(function(item) {
      if (item.title.search(keyword) >= 0) {
        return true
      }
      return false;
    })
  },
  mapContents: function(mapFunc) {
    return contents.map(mapFunc)
  },
  encryptContent: function(account, masterPassword, content) {
    return AesUtils.encryptData(account, masterPassword, JSON.stringify({
      type: content.type,
      title: content.title,
      content: content.content,
      account: content.account,
      color: content.color,
      extra: content.extra,
      tags: content.tags,
    }))
  },
  getIconByType: function(t) {
    switch (t) {
      case passwordType:
        return 'key'
      case textType:
        return 'file-alt'
      case totpType:
        return 'stopwatch'
      case blockchainType:
        return 'bitcoin'
      case 5:
        return 'th-large'
    }
    return 'tag'
  },
  getContentIcon: function(contentType) {
    switch (contentType) {
      case passwordType:
        return 'icon-key1'
      case textType:
        return 'icon-file-text'
      case totpType:
        return 'icon-stopwatch'
      case blockchainType:
        return 'icon-bitcoin'
      default:
        return 'icon-tag1'
    }
  },
  getContentIconColor: function(contentType) {
    switch (contentType) {
      case passwordType:
        return ColorUtils.passwordIconColor
      case textType:
        return ColorUtils.textIconColor
      case totpType:
        return ColorUtils.totpIconColor
      case blockchainType:
        return ColorUtils.blockchainIconColor
      default:
        return ColorUtils.tagIconColor
    }
  },
  getContentIconColorBg: function(contentType) {
    switch (contentType) {
      case passwordType:
        return ColorUtils.passwordIconColorBg
      case textType:
        return ColorUtils.textIconColorBg
      case totpType:
        return ColorUtils.totpIconColorBg
      case blockchainType:
        return ColorUtils.blockchainIconColorBg
      default:
        return ColorUtils.tagIconColorBg
    }
  },
  getContentKey: function(contentType) {
    switch (contentType) {
      case passwordType:
        return localization.strings.password
      case textType:
        return localization.strings.text
      case totpType:
        return localization.strings.totpSecret
      default:
        return ''
    }
  },
  getContentTypeName: function(contentType) {
    switch (contentType) {
      case passwordType:
        return localization.strings.password
      case textType:
        return localization.strings.text
      case totpType:
        return localization.strings.totp
      case blockchainType:
        return localization.strings.blockchain
      default:
        return ''
    }
  },
}