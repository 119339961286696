import React, {useState, useEffect} from 'react'
import ContentUtils from '../utils/content'

function ContentIcon(props) {
  return (
    <div style={{
      border: `1px solid ${ContentUtils.getContentIconColor(props.contentType)}`,
      backgroundColor: ContentUtils.getContentIconColorBg(props.contentType),
      color: ContentUtils.getContentIconColor(props.contentType),
      height: props.size,
      width: props.size,
      lineHeight: props.size,
      textAlign: 'center',
      borderRadius: '99rem',
      display: 'block',
    }}>
      <i className={`iconfont ${ContentUtils.getContentIcon(props.contentType)}`} style={{
        fontSize: props.fontSize,
      }}></i>
    </div>
  )
}

export default ContentIcon;