import React, {useState, useEffect} from 'react'
import toast from 'react-hot-toast'
import { Dialog, DialogTitle, DialogContent, Button, Divider } from '@mui/material'
import localization from '../utils/localization'
import FormInput from './FormInput'
import ContentItem from './ContentItem'
import UserUtils from '../utils/user'
import ServerUtils from '../utils/server'
import AesUtils from '../utils/aes'
import ContentUtils from '../utils/content'
import ToolsUtils from '../utils/tools'

function ModalTag(props) {
  const [submit, setSubmit] = useState(false)
  const [keyword, setKeyword] = useState('')
  let initSelectedContentMap = {}
  if (props.contentItemList) {
    for (let i in props.contentItemList) {
      initSelectedContentMap[props.contentItemList[i].id] = true
    }
  }
  const [selectedContentMap, setSelectedContentMap] = useState(initSelectedContentMap)
  const [tagName, setTagName] = useState(props.tagName)
  const [tagNameErr, setTagNameErr] = useState('')
  const [renderContentList, setRenderContentList] = useState(props.contentList ? ToolsUtils.sortContentList([...props.contentList], props.sortType) : [])

  useEffect(() => {
    if (!props.open) return
    setTagName(props.tagName)
    setTagNameErr('')
    let tmpSelectedContentMap = {}
    if (props.contentItemList) {
      for (let i in props.contentItemList) {
        tmpSelectedContentMap[props.contentItemList[i].id] = true
      }
    }
    setSelectedContentMap(tmpSelectedContentMap)
    setRenderContentList(props.contentList ? ToolsUtils.sortContentList([...props.contentList], props.sortType) : [])
  }, [props.open, props.tagName, props.contentItemList, props.sortType, props.contentList])

  function submitTag() {
    if (submit === true) return
    setSubmit(true)
    let hasErr = false
    if (tagName === '') {
      setTagNameErr(localization.strings.required)
      hasErr = true
    } else if (tagName in props.groupMap && props.tagName !== tagName) {
      setTagNameErr(localization.strings.exists)
      hasErr = true
    }
    if (hasErr) {
      setSubmit(false)
      return
    }
    if (Object.keys(selectedContentMap).length <= 0) {
      toast.error(localization.strings.contentRequired)
      hasErr = true
    }
    if (hasErr) {
      setSubmit(false)
      return
    }
    let needUpdateContents = []
    for (let i in props.contentList) {
      let item = props.contentList[i]
      if (item.tags === undefined) {
        item.tags = []
      }
      if (item.tags.includes(tagName) && !selectedContentMap[item.id]) {
        let copyItem = JSON.parse(JSON.stringify(item))
        copyItem.tags = copyItem.tags.filter((tagItem) => tagItem !== tagName)
        needUpdateContents.push(copyItem)
      } else if (!item.tags.includes(tagName) && selectedContentMap[item.id]) {
        let copyItem = JSON.parse(JSON.stringify(item))
        copyItem.tags.push(tagName)
        if (props.tagName && props.tagName !== tagName) { // rename to delete old tag
          copyItem.tags = copyItem.tags.filter((tagItem) => tagItem !== props.tagName)
        }
        needUpdateContents.push(copyItem)
      } else if (props.tagName && props.tagName !== tagName && item.tags.includes(props.tagName)) { // rename to delete old tag
        let copyItem = JSON.parse(JSON.stringify(item))
        copyItem.tags = copyItem.tags.filter((tagItem) => tagItem !== props.tagName)
        needUpdateContents.push(copyItem)
      }
    }
    let userInfo = UserUtils.getUserInfo()
    let lastModifyTime = new Date().getTime() / 1000
    ServerUtils.updateContent({
      version: userInfo.version,
      contents: needUpdateContents.map(function(contentItem) {
        return {
          content_id: contentItem.id,
          encrypted_data: AesUtils.encryptData(userInfo.account, UserUtils.getMasterPass(), JSON.stringify({
            title: contentItem.title,
            content: contentItem.content,
            type: contentItem.type,
            color: contentItem.color,
            account: contentItem.account,
            extra: contentItem.extra,
            tags: contentItem.tags,
          })),
        }
      }),
    }).then(async (resp) => {
      setSubmit(false)
      if (!resp.ok) {
        try {
          let respJson = await resp.json()
          if (respJson.errno !== 0) {
            toast.error(ServerUtils.handleError(respJson))
            return
          }
        } catch(e) {

        }
        toast.error(localization.strings.networkErr)
        return
      }
      let respJson = await resp.json()
      if (respJson.errno !== 0) {
        toast.error(ServerUtils.handleError(respJson))
        return
      }
      ContentUtils.updateContents(needUpdateContents.map(function(contentItem) {
        return {
          id: contentItem.id,
          version: userInfo.version,
          title: contentItem.title,
          content: contentItem.content,
          type: contentItem.type,
          color: contentItem.color,
          account: contentItem.account,
          last_modify_time: lastModifyTime,
          extra: contentItem.extra,
          tags: contentItem.tags,
        }
      }))
      toast.success(localization.strings.successModify)
      props.onTagName(tagName)
      props.onClose()
      setSelectedContentMap({})
      setTagName('')
    })
  }
  
  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent style={{
        minWidth: '300px',
        overflowY: 'visible',
      }}>
        <FormInput label={localization.strings.tagName} placeholder={localization.strings.inputTagNameHint} 
          defaultValue={props.tagName} size="small" onChange={(event) => {
          setTagName(event.target.value)
          setTagNameErr('')
        }} error={tagNameErr}/>
        <div style={{
          marginTop: '0.8rem',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
          <Button variant="outlined" size="small" color="secondary" onClick={props.onClose} style={{
            marginRight: '0.5rem',
            flex: 1,
          }}>
            {localization.strings.cancel}
          </Button>
          <Button variant="contained" size="small" color="primary" style={{
            flex: 1,
          }} onClick={submitTag}>
            {localization.strings.confirm}
          </Button>
        </div>
        <Divider style={{
          margin: '1rem 0rem',
        }}/>
        <FormInput label={localization.strings.keyword} placeholder={localization.strings.keywordHint} size="small" onChange={(event) => {
          setKeyword(event.target.value)
        }}/>
        <div style={{
          margin: '0.5rem 0rem 0rem',
        }}>
          {
            renderContentList.filter((item) => {
              if (keyword === '') return true
              return item.title.indexOf(keyword) >= 0
            }).map((item) => {
              return (
                <div key={item.id} style={{
                  padding: '0rem 0.5rem 0.5rem',
                }}>
                  <ContentItem checkBox={true} contentType={item.type} text={item.title} active={selectedContentMap[item.id]} onClick={() => {
                    let tmpMap = Object.assign({}, selectedContentMap)
                    if (tmpMap[item.id]) {
                      tmpMap[item.id] = false
                    } else {
                      tmpMap[item.id] = true
                    }
                    setSelectedContentMap(tmpMap)
                  }}/>
                </div>
              )
            })
          }
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default ModalTag