import React, {useState, useEffect} from 'react'
import { Dialog, DialogTitle, DialogContent, Button } from '@mui/material'
import localization from '../utils/localization'
import ColorUtils from '../utils/colors'
import ServerUtils from '../utils/server'
import FormInput from '../components/FormInput'
import toast from 'react-hot-toast'
import ConstUtils from '../utils/const'

function ModalFeedback(props) {
  const [feedbackContent, setFeedbackContent] = useState('')
  const [feedbackContentErr, setFeedbackContentErr] = useState('')

  function feedback() {
    if (feedbackContent === '') {
      setFeedbackContentErr(localization.strings.required)
      return
    }
    ServerUtils.feedback({
      'app_id': ConstUtils.appID,
      'type': 1,
      'message': feedbackContent,
    }).then(async (resp) => {
      toast.success(localization.strings.thankFeedback)
      props.onClose()
    })
  }

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogTitle>{localization.strings.feedback}</DialogTitle>
      <DialogContent style={{
        minWidth: '300px',
        overflowY: 'visible',
      }}>
        <FormInput label={localization.strings.feedbackContent} multiline={true} value={feedbackContent}
          placeholder={localization.strings.inputFeedbackContentHint} error={feedbackContentErr} onChange={(event) => {
            setFeedbackContent(event.target.value)
          }}/>
        <div style={{
          marginTop: '0.8rem',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
          <Button variant="outlined" size="small" color="secondary" onClick={props.onClose} style={{
            width: '0px',
            flex: 1,
          }}>
            {localization.strings.cancel}
          </Button>
          <div style={{width: '0.5rem'}}></div>
          <Button variant="contained" size="small" color="primary" style={{
            width: '0px',
            flex: 1,
          }} onClick={feedback}>
            {localization.strings.confirm}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default ModalFeedback