import {sha256} from 'js-sha256'
import aesjs from 'aes-js'

function getCode(byte) {
  const text = byte.toString(16);
  if (byte < 16) {
    return '%0' + text
  }
  return '%' + text
};

function bytesToString(bytes) {
  var result = ''
  for (var i = 0; i < bytes.length; ++i) {
      result += getCode(bytes[i])
  }
  return decodeURIComponent(result)
}

export default {
  sha256: function(data) {
    return aesjs.utils.hex.fromBytes(sha256.array(data))
  },
  encryptData: function(account, masterPassword, rawData) {
    var key = sha256.array(masterPassword)
    var iv = sha256.array('easypass'+masterPassword).slice(0, 16)
    var textBytes = aesjs.padding.pkcs7.pad(aesjs.utils.utf8.toBytes(rawData))
    var aesCbc = new aesjs.ModeOfOperation.cbc(key, iv)
    return aesjs.utils.hex.fromBytes(aesCbc.encrypt(textBytes))
  },
  decryptData: function(account, masterPassword, encryptedData) {
    var key = sha256.array(masterPassword)
    var iv = sha256.array('easypass'+masterPassword).slice(0, 16)
    var encryptedBytes = aesjs.utils.hex.toBytes(encryptedData)
    var aesCbc = new aesjs.ModeOfOperation.cbc(key, iv)
    try {
      var decryptedBytes = aesCbc.decrypt(encryptedBytes)
      // return aesjs.utils.utf8.fromBytes(aesjs.padding.pkcs7.strip(decryptedBytes)) // 解析不了uft8mb4
      return bytesToString(aesjs.padding.pkcs7.strip(decryptedBytes))
    } catch (e) {
      console.log(e)
    }
    return ""
  },
}