import React, {useState, useEffect, forwardRef, useImperativeHandle} from 'react'
import { Button, Slider, FormControl, FormGroup, FormControlLabel, Checkbox, Divider } from '@mui/material'
import FormInput from './FormInput'
import localization from '../utils/localization'
import ExtraForm from './ExtraForm'
import ContentUtils from '../utils/content'

const PasswordForm = forwardRef((props, ref) => {
  const [title, setTitle] = useState(props.contentInfo.id ? props.contentInfo.title : '')
  const [titleErr, setTitleErr] = useState('')
  const [account, setAccount] = useState(props.contentInfo.id ? props.contentInfo.account : '')
  const [password, setPassword] = useState(props.contentInfo.id ? props.contentInfo.content : '')
  const [passwordErr, setPasswordErr] = useState('')
  const [passwordLength, setPasswordLength] = useState(12)
  const [littleLetter, setLittleLetter] = useState(true)
  const [bigLetter, setBigLetter] = useState(true)
  const [number, setNumber] = useState(true)
  const [special1, setSpecial1] = useState(false)
  const [special2, setSpecial2] = useState(false)
  const [special3, setSpecial3] = useState(false)
  const [special4, setSpecial4] = useState(false)
  const [extra, setExtra] = useState(props.contentInfo.id ? props.contentInfo.extra : {})

  useEffect(() => {
    if (props.contentInfo.id) {
      setTitle(props.contentInfo.title)
      setTitleErr('')
      setAccount(props.contentInfo.account)
      setPassword(props.contentInfo.content)
      setPasswordErr('')
      setExtra(props.contentInfo.extra)
    }
  }, [props.contentInfo])

  function generatePassword() {
    let passElement = ''
    if (littleLetter) {
      passElement += 'abcdefghijklmnopqrstuvwsyz'
    }
    if (bigLetter) {
      passElement += 'ABCDEFGHIJKLMNOPQRSTUVWSYZ'
    }
    if (number) {
      passElement += '01234567890123456789'
    }
    if (special1) {
      passElement += '!!!!!'
    }
    if (special2) {
      passElement += '#####'
    }
    if (special3) {
      passElement += '@@@@@'
    }
    if (special4) {
      passElement += '$$$$$'
    }
    var passTmp = ''
    if (passElement !== '') {
      for (var i=0;i<passwordLength;i++) {
        passTmp += passElement[parseInt(Math.random()*passElement.length, 10)]
      }
    }
    setPassword(passTmp)
    setPasswordErr('')
  }

  useImperativeHandle(ref, () => ({
    checkForm() {
      let hasErr = false
      if (title === '') {
        setTitleErr(localization.strings.required)
        hasErr = true
      }
      if (password === '') {
        setPasswordErr(localization.strings.required)
        hasErr = true
      }
      return hasErr
    },
    getContentData() {
      return {
        title: title,
        content: password,
        type: ContentUtils.getPasswordType(),
        color: 'blue',
        account: account,
        extra: extra,
      }
    },
  }))

  return (
    <div>
      <div style={{
        margin: '0rem 0rem 0rem',
      }}>
        <FormInput label={localization.strings.title} value={title}
          placeholder={localization.strings.inputTitleHint} error={titleErr} onChange={(event) => {
            setTitle(event.target.value)
            setTitleErr('')
          }}/>
      </div>
      <div style={{
        margin: '1rem 0rem 0rem',
      }}>
        <FormInput label={`${localization.strings.account} (${localization.strings.optional})`} value={account}
          placeholder={localization.strings.inputAccountHint} onChange={(event) => {
            setAccount(event.target.value)
          }}/>
      </div>
      <div style={{
        margin: '1rem 0rem 0rem',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      }}>
        <FormInput wrapContent={true} label={localization.strings.password} value={password}
          placeholder={localization.strings.inputPasswordHint} error={passwordErr} onChange={(event) => {
            setPassword(event.target.value)
            setPasswordErr('')
          }} style={{
            flex: 1,
          }}/>
        <Button variant="outlined" color="secondary" style={{
          marginLeft: '1rem',
        }} onClick={generatePassword}>
          {localization.strings.genPassword}
        </Button>
      </div>
      <div style={{
        padding: '1rem 1rem 0rem',
      }}>
        <Slider value={passwordLength} step={1} min={6} max={18} valueLabelDisplay="auto" getAriaValueText={(value) => {
          return `${value} ${localization.strings.length}`
        }} marks={[
          {
            value: 6,
            label: `6 ${localization.strings.length}`,
          },
          {
            value: 12,
            label: `12 ${localization.strings.length}`,
          },
          {
            value: 18,
            label: `18 ${localization.strings.length}`,
          },
        ]} onChange={(event) => {
          setPasswordLength(parseInt(event.target.value))
        }}/>
      </div>
      <FormControl>
        <FormGroup aria-label="position" row>
          <FormControlLabel
            control={<Checkbox checked={littleLetter} onChange={(event) => {
              setLittleLetter(event.target.checked)
            }}/>}
            label="a-z"
            labelPlacement="end"/>
          <FormControlLabel
            control={<Checkbox checked={bigLetter} onChange={(event) => {
              setBigLetter(event.target.checked)
            }}/>}
            label="A-Z"
            labelPlacement="end"/>
          <FormControlLabel
            control={<Checkbox checked={number} onChange={(event) => {
              setNumber(event.target.checked)
            }}/>}
            label="0-9"
            labelPlacement="end"/>
          <FormControlLabel
            control={<Checkbox checked={special1} onChange={(event) => {
              setSpecial1(event.target.checked)
            }}/>}
            label="!"
            labelPlacement="end"/>
          <FormControlLabel
            control={<Checkbox checked={special2} onChange={(event) => {
              setSpecial2(event.target.checked)
            }}/>}
            label="#"
            labelPlacement="end"/>
          <FormControlLabel
            control={<Checkbox checked={special3} onChange={(event) => {
              setSpecial3(event.target.checked)
            }}/>}
            label="@"
            labelPlacement="end"/>
          <FormControlLabel
            control={<Checkbox checked={special4} onChange={(event) => {
              setSpecial4(event.target.checked)
            }}/>}
            label="$"
            labelPlacement="end"/>
        </FormGroup>
      </FormControl>
      <Divider style={{
        margin: '1rem 0rem',
      }}/>
      <ExtraForm extra={extra} onChange={(e) => {
        setExtra(e)
      }}/>
    </div>
  )
})

export default PasswordForm