import React, { useState, useEffect } from "react";
import {
  Container,
  Box,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
  Grid,
  Paper,
} from "@mui/material";
import ColorUtils from "../utils/colors";
import logo256 from "../assets/logo_256.png";
import phonePage from "../assets/PhonePage.png";
import webPage from "../assets/WebPage.png";
import localization from "../utils/localization";
import KeyFeature from "../components/KeyFeature";
import ServerUtils from "../utils/server";
import toast from "react-hot-toast";
import IndexCard from "../components/IndexCard";
import { useNavigate } from "react-router-dom";

export function Index(props) {
  const [pwdList, setPwdList] = useState([]);
  const navigate = useNavigate();

  function refreshPwdList() {
    ServerUtils.listNewest().then(async (resp) => {
      if (!resp.ok) {
        toast.error(localization.strings.networkErr);
        return;
      }
      let respJson = await resp.json();
      if (respJson.errno !== 0) {
        toast.error(ServerUtils.handleError(respJson));
        return;
      }
      setPwdList(respJson.data.slice(0, 3));
    });
  }

  useEffect(() => {
    refreshPwdList();
  }, []);

  return (
    <Box
      style={{
        backgroundColor: ColorUtils.write,
        width: "100%",
        height: "100%",
        overflowY: "scroll",
        overflowX: "hidden",
      }}
    >
      <Container maxWidth="lg">
        <div
          style={{
            height: "4rem",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <img
            src={logo256}
            alt="EasyPass Logo"
            className="brand-image img-circle elevation-3"
            style={{
              display: "block",
              height: "3rem",
              width: "3rem",
            }}
          />
          <div
            style={{
              fontSize: "1.2rem",
              fontWeight: "bold",
              color: ColorUtils.textColor,
              marginLeft: "0.6rem",
            }}
          >
            EasyPass
          </div>
          <div style={{ flex: 1 }}></div>
          <Button
            variant="outlined"
            style={{
              marginRight: "1rem",
            }}
            onClick={() => {
              navigate(`/login.html?lang=${localization.language.lang}`);
            }}
          >
            {localization.strings.login}
          </Button>
          <FormControl size="small" sx={{ minWidth: 120 }}>
            <InputLabel
              id="demo-dialog-select-label"
              style={{
                color: ColorUtils.textColor,
              }}
            >
              {localization.strings.language}
            </InputLabel>
            <Select
              labelId="demo-dialog-select-label"
              id="demo-dialog-select"
              defaultValue={localization.language.lang}
              input={<OutlinedInput label={localization.language.lang} />}
              onChange={(event) => {
                window.location.href = "?lang=" + event.target.value;
              }}
              size="small"
              style={{
                color: ColorUtils.textColor,
              }}
            >
              <MenuItem value="en">English</MenuItem>
              <MenuItem value="zh">中文</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div
          style={{
            padding: "5rem 0rem",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <div
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              height: "30rem",
              justifyContent: "center",
            }}
          >
            <h1
              style={{
                color: ColorUtils.textColor,
                fontSize: "3rem",
                lineHeight: "1.3",
              }}
            >
              <span
                style={{
                  color: ColorUtils.red,
                }}
              >
                {localization.strings.secure}
              </span>
              {localization.strings.indexTitle}
            </h1>
            <p
              style={{
                color: ColorUtils.textColorGrey,
                lineHeight: "1.3",
                margin: "0rem 0rem 1.5rem",
              }}
            >
              {localization.strings.indexTitleDesc}
            </p>
            <div>
              <Button
                variant="contained"
                color="success"
                style={{
                  margin: "0rem 0.5rem 0.5rem 0rem",
                  paddingTop: "0rem",
                  paddingBottom: "0rem",
                }}
                href="https://apps.apple.com/cn/app/easypass%E5%AF%86%E7%A0%81%E7%AE%A1%E7%90%86%E5%B7%A5%E5%85%B7/id1534324173"
                target="_blank"
              >
                <i
                  className="iconfont icon-ios"
                  style={{
                    marginRight: "0.5rem",
                    fontSize: "1.6rem",
                  }}
                ></i>
                AppStore
              </Button>
              <Button
                variant="contained"
                color="primary"
                style={{
                  margin: "0rem 0.5rem 0.5rem 0rem",
                  paddingTop: "0rem",
                  paddingBottom: "0rem",
                }}
                href="https://play.google.com/store/apps/details?id=com.musketeer.easypass"
                target="_blank"
              >
                <i
                  className="iconfont icon-google-play"
                  style={{
                    marginRight: "0.5rem",
                    fontSize: "1.6rem",
                  }}
                ></i>
                GooglePaly
              </Button>
              <Button
                variant="contained"
                color="info"
                style={{
                  margin: "0rem 0.5rem 0.5rem 0rem",
                  paddingTop: "0rem",
                  paddingBottom: "0rem",
                }}
                href="https://easypass-tech.oss-cn-hangzhou.aliyuncs.com/app-release.apk"
                target="_blank"
              >
                <i
                  className="iconfont icon-android-fill"
                  style={{
                    marginRight: "0.5rem",
                    fontSize: "1.6rem",
                  }}
                ></i>
                Android APK
              </Button>
              <Button
                variant="contained"
                color="warning"
                style={{
                  margin: "0rem 0.5rem 0.5rem 0rem",
                  paddingTop: "0rem",
                  paddingBottom: "0rem",
                }}
                href={`https://chromewebstore.google.com/detail/easypass/hepcelaldempfnmhkdehiggikjdkiahh`}
                target="_blank"
              >
                <i
                  className="iconfont icon-extension"
                  style={{
                    marginRight: "0.5rem",
                    fontSize: "1.6rem",
                  }}
                ></i>
                {localization.strings.extension}
              </Button>
              <Button
                variant="contained"
                color="secondary"
                style={{
                  margin: "0rem 0.5rem 0.5rem 0rem",
                  paddingTop: "0rem",
                  paddingBottom: "0rem",
                }}
                href={`/login.html?lang=${localization.language.lang}`}
                target="_blank"
              >
                <i
                  className="iconfont icon-chrome"
                  style={{
                    marginRight: "0.5rem",
                    fontSize: "1.6rem",
                  }}
                ></i>
                Web UI
              </Button>
            </div>
          </div>
          <div
            style={{
              flex: 2,
              position: "relative",
              paddingLeft: "1rem",
            }}
          >
            <img
              src={phonePage}
              style={{
                display: "block",
                height: "30rem",
                marginTop: "5rem",
              }}
            />
            <img
              src={webPage}
              style={{
                position: "absolute",
                top: "0rem",
                left: "6rem",
                display: "block",
                height: "35rem",
                zIndex: -1,
              }}
            />
          </div>
        </div>
      </Container>
      <Container
        maxWidth="lg"
        style={{
          padding: "2rem 4rem",
        }}
      >
        <h1
          style={{
            textAlign: "center",
            margin: "1rem 0rem 0rem",
            color: ColorUtils.textColor,
          }}
        >
          {localization.strings.indexSection1Title}
        </h1>
        <p
          style={{
            color: ColorUtils.textColorGrey,
            margin: "0.5rem 0rem 2rem",
            textAlign: "center",
          }}
        >
          {localization.strings.indexSection1SubTitle}
        </p>
        <Grid
          container
          style={{
            marginBottom: "1rem",
          }}
        >
          <Grid item xs={4}>
            <KeyFeature
              icon="icon-check"
              iconColor={ColorUtils.indexGreen}
              iconColorBg={ColorUtils.indexGreenBg}
              title={localization.strings.indexKeyFeature1Title}
              desc={localization.strings.indexKeyFeature1Desc}
            />
          </Grid>
          <Grid item xs={4}>
            <KeyFeature
              icon="icon-cloud"
              iconColor={ColorUtils.indexBlue}
              iconColorBg={ColorUtils.indexBlueBg}
              title={localization.strings.indexKeyFeature2Title}
              desc={localization.strings.indexKeyFeature2Desc}
            />
          </Grid>
          <Grid item xs={4}>
            <KeyFeature
              icon="icon-lock"
              iconColor={ColorUtils.indexRed}
              iconColorBg={ColorUtils.indexRedBg}
              title={localization.strings.indexKeyFeature3Title}
              desc={localization.strings.indexKeyFeature3Desc}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={1.33}></Grid>
          <Grid item xs={4}>
            <KeyFeature
              icon="icon-bitcoin"
              iconColor={ColorUtils.indexOrange}
              iconColorBg={ColorUtils.indexOrangeBg}
              title={localization.strings.indexKeyFeature4Title}
              desc={localization.strings.indexKeyFeature4Desc}
            />
          </Grid>
          <Grid item xs={1.34}></Grid>
          <Grid item xs={4}>
            <KeyFeature
              icon="icon-envelope"
              iconColor={ColorUtils.indexPurple}
              iconColorBg={ColorUtils.indexPurpleBg}
              title={localization.strings.indexKeyFeature5Title}
              desc={localization.strings.indexKeyFeature5Desc}
            />
          </Grid>
          <Grid item xs={1.33}></Grid>
        </Grid>
      </Container>
      <div
        style={{
          backgroundColor: ColorUtils.lightGrey,
        }}
      >
        <Container
          maxWidth="lg"
          style={{
            padding: "2rem 4rem",
          }}
        >
          <h1
            style={{
              textAlign: "center",
              margin: "1rem 0rem 0rem",
              color: ColorUtils.textColor,
            }}
          >
            {localization.strings.indexSection2Title}
          </h1>
          <p
            style={{
              color: ColorUtils.textColorGrey,
              margin: "0.5rem 0rem 0.5rem",
              textAlign: "center",
            }}
          >
            {localization.strings.indexSection2SubTitle}
          </p>
          <Button
            variant="contained"
            color="info"
            style={{
              margin: "0rem auto 2rem",
              display: "block",
            }}
            onClick={refreshPwdList}
          >
            {localization.strings.refresh}
          </Button>
          <Grid container>
            {pwdList.map((item, index) => {
              return (
                <Grid key={index} item xs={4}>
                  <IndexCard pwd={item} />
                </Grid>
              );
            })}
          </Grid>
        </Container>
      </div>
      <Container
        maxWidth="lg"
        style={{
          padding: "2rem 4rem",
        }}
      >
        <h1
          style={{
            textAlign: "center",
            margin: "1rem 0rem 1rem",
            color: ColorUtils.textColor,
          }}
        >
          {localization.strings.pricing}
        </h1>
        <Grid container>
          <Grid item xs={6}>
            <Paper
              elevation={5}
              style={{
                backgroundColor: ColorUtils.white,
                width: "22rem",
                margin: "0rem auto",
              }}
            >
              <div
                style={{
                  padding: "1.5rem 1.5rem 0.5rem",
                  fontSize: "1.4rem",
                  color: ColorUtils.textColor,
                }}
              >
                {localization.strings.freeUser}
              </div>
              <div
                style={{
                  padding: "0rem 1.5rem 0.5rem",
                  fontSize: "1rem",
                  color: ColorUtils.textColor,
                }}
              >
                <i
                  className="iconfont icon-check-"
                  style={{
                    fontSize: "1rem",
                    color: ColorUtils.green,
                    marginRight: "0.5rem",
                  }}
                ></i>
                {localization.strings.editPassword}
              </div>
              <div
                style={{
                  padding: "0rem 1.5rem 0.5rem",
                  fontSize: "1rem",
                  color: ColorUtils.textColor,
                }}
              >
                <i
                  className="iconfont icon-check-"
                  style={{
                    fontSize: "1rem",
                    color: ColorUtils.green,
                    marginRight: "0.5rem",
                  }}
                ></i>
                {localization.strings.editText}
              </div>
              <div
                style={{
                  padding: "0rem 1.5rem 0.5rem",
                  fontSize: "1rem",
                  color: ColorUtils.textColor,
                }}
              >
                <i
                  className="iconfont icon-check-"
                  style={{
                    fontSize: "1rem",
                    color: ColorUtils.green,
                    marginRight: "0.5rem",
                  }}
                ></i>
                {localization.strings.editTotp}
              </div>
              <div
                style={{
                  padding: "0rem 1.5rem 0.5rem",
                  fontSize: "1rem",
                  color: ColorUtils.textColor,
                }}
              >
                <i
                  className="iconfont icon-check-"
                  style={{
                    fontSize: "1rem",
                    color: ColorUtils.green,
                    marginRight: "0.5rem",
                  }}
                ></i>
                {localization.strings.editBlockchain}
              </div>
              <div
                style={{
                  padding: "0rem 1.5rem 0.5rem",
                  fontSize: "1rem",
                  color: ColorUtils.textColor,
                }}
              >
                <i
                  className="iconfont icon-check-"
                  style={{
                    fontSize: "1rem",
                    color: ColorUtils.green,
                    marginRight: "0.5rem",
                  }}
                ></i>
                {localization.strings.editMessage}
              </div>
              <div
                style={{
                  padding: "0rem 1.5rem 0.5rem",
                  fontSize: "1rem",
                  color: ColorUtils.textColor,
                }}
              >
                <i
                  className="iconfont icon-14CIRCLE"
                  style={{
                    fontSize: "1rem",
                    color: ColorUtils.red,
                    marginRight: "0.5rem",
                  }}
                ></i>
                {localization.strings.editTag}
              </div>
              <div
                style={{
                  padding: "0rem 1.5rem 0.5rem",
                  fontSize: "1rem",
                  color: ColorUtils.textColor,
                }}
              >
                <i
                  className="iconfont icon-14CIRCLE"
                  style={{
                    fontSize: "1rem",
                    color: ColorUtils.red,
                    marginRight: "0.5rem",
                  }}
                ></i>
                {localization.strings.addExtraKeys}
              </div>
              <div
                style={{
                  padding: "0rem 1.5rem 0.5rem",
                  fontSize: "1rem",
                  color: ColorUtils.textColor,
                }}
              >
                <i
                  className="iconfont icon-14CIRCLE"
                  style={{
                    fontSize: "1rem",
                    color: ColorUtils.red,
                    marginRight: "0.5rem",
                  }}
                ></i>
                {localization.strings.tagAddContent}
              </div>
              <div
                style={{
                  padding: "0rem 1.5rem 0.5rem",
                  fontSize: "1rem",
                  color: ColorUtils.textColor,
                }}
              >
                <i
                  className="iconfont icon-14CIRCLE"
                  style={{
                    fontSize: "1rem",
                    color: ColorUtils.red,
                    marginRight: "0.5rem",
                  }}
                ></i>
                {localization.strings.autocomplete}
              </div>
              <div
                style={{
                  padding: "0rem 1.5rem 1rem",
                }}
              >
                <Button
                  variant="outlined"
                  color="info"
                  style={{
                    margin: "0rem auto",
                  }}
                >
                  {localization.strings.newUserVipTime}
                </Button>
              </div>
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <Paper
              elevation={5}
              style={{
                backgroundColor: ColorUtils.white,
                width: "22rem",
                margin: "0rem auto",
              }}
            >
              <div
                style={{
                  padding: "1.5rem 1.5rem 0.5rem",
                  fontSize: "1.4rem",
                  color: ColorUtils.textColor,
                }}
              >
                {localization.strings.vipUser}
              </div>
              <div
                style={{
                  padding: "0rem 1.5rem 0.5rem",
                  fontSize: "1rem",
                  color: ColorUtils.textColor,
                }}
              >
                <i
                  className="iconfont icon-check-"
                  style={{
                    fontSize: "1rem",
                    color: ColorUtils.green,
                    marginRight: "0.5rem",
                  }}
                ></i>
                {localization.strings.editPassword}
              </div>
              <div
                style={{
                  padding: "0rem 1.5rem 0.5rem",
                  fontSize: "1rem",
                  color: ColorUtils.textColor,
                }}
              >
                <i
                  className="iconfont icon-check-"
                  style={{
                    fontSize: "1rem",
                    color: ColorUtils.green,
                    marginRight: "0.5rem",
                  }}
                ></i>
                {localization.strings.editText}
              </div>
              <div
                style={{
                  padding: "0rem 1.5rem 0.5rem",
                  fontSize: "1rem",
                  color: ColorUtils.textColor,
                }}
              >
                <i
                  className="iconfont icon-check-"
                  style={{
                    fontSize: "1rem",
                    color: ColorUtils.green,
                    marginRight: "0.5rem",
                  }}
                ></i>
                {localization.strings.editTotp}
              </div>
              <div
                style={{
                  padding: "0rem 1.5rem 0.5rem",
                  fontSize: "1rem",
                  color: ColorUtils.textColor,
                }}
              >
                <i
                  className="iconfont icon-check-"
                  style={{
                    fontSize: "1rem",
                    color: ColorUtils.green,
                    marginRight: "0.5rem",
                  }}
                ></i>
                {localization.strings.editBlockchain}
              </div>
              <div
                style={{
                  padding: "0rem 1.5rem 0.5rem",
                  fontSize: "1rem",
                  color: ColorUtils.textColor,
                }}
              >
                <i
                  className="iconfont icon-check-"
                  style={{
                    fontSize: "1rem",
                    color: ColorUtils.green,
                    marginRight: "0.5rem",
                  }}
                ></i>
                {localization.strings.editMessage}
              </div>
              <div
                style={{
                  padding: "0rem 1.5rem 0.5rem",
                  fontSize: "1rem",
                  color: ColorUtils.textColor,
                }}
              >
                <i
                  className="iconfont icon-check-"
                  style={{
                    fontSize: "1rem",
                    color: ColorUtils.green,
                    marginRight: "0.5rem",
                  }}
                ></i>
                {localization.strings.editTag}
              </div>
              <div
                style={{
                  padding: "0rem 1.5rem 0.5rem",
                  fontSize: "1rem",
                  color: ColorUtils.textColor,
                }}
              >
                <i
                  className="iconfont icon-check-"
                  style={{
                    fontSize: "1rem",
                    color: ColorUtils.green,
                    marginRight: "0.5rem",
                  }}
                ></i>
                {localization.strings.addExtraKeys}
              </div>
              <div
                style={{
                  padding: "0rem 1.5rem 0.5rem",
                  fontSize: "1rem",
                  color: ColorUtils.textColor,
                }}
              >
                <i
                  className="iconfont icon-check-"
                  style={{
                    fontSize: "1rem",
                    color: ColorUtils.green,
                    marginRight: "0.5rem",
                  }}
                ></i>
                {localization.strings.tagAddContent}
              </div>
              <div
                style={{
                  padding: "0rem 1.5rem 0.5rem",
                  fontSize: "1rem",
                  color: ColorUtils.textColor,
                }}
              >
                <i
                  className="iconfont icon-check-"
                  style={{
                    fontSize: "1rem",
                    color: ColorUtils.green,
                    marginRight: "0.5rem",
                  }}
                ></i>
                {localization.strings.autocomplete}
              </div>
              <div
                style={{
                  padding: "0rem 1.5rem 1rem",
                }}
              >
                <Button
                  variant="outlined"
                  color="info"
                  style={{
                    margin: "0rem auto",
                  }}
                >
                  {localization.strings.vipPayInfo}
                </Button>
              </div>
            </Paper>
          </Grid>
        </Grid>
      </Container>
      <div
        style={{
          backgroundColor: ColorUtils.lightGrey,
        }}
      >
        <Container
          maxWidth="lg"
          style={{
            padding: "1rem 4rem",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <p
              style={{
                flex: 1,
                color: ColorUtils.textColor,
                margin: "0rem",
              }}
            >
              Copyright © 2020-2024.EasyPass All rights reserved.{" "}
              <Button href="https://beian.miit.gov.cn/" target="_blank">
                浙ICP备18002047号-2
              </Button>
            </p>
            <p
              style={{
                textAlign: "right",
                color: ColorUtils.textColor,
              }}
            >
              <a
                href="mailto:easypass.inc@gmail.com"
                style={{
                  color: ColorUtils.textColor,
                }}
              >
                <i
                  className="iconfont icon-envelope"
                  style={{
                    display: "inline-block",
                    marginRight: "0.5rem",
                  }}
                ></i>
                easypass.inc@gmail.com
              </a>
            </p>
          </div>
        </Container>
      </div>
    </Box>
  );
}

export default Index;
