import React, {useState, useEffect} from 'react'
import ColorUtils from '../utils/colors'
import { Button, Menu, MenuItem, Dialog, DialogTitle, DialogContent } from '@mui/material';
import FormInput from '../components/FormInput'
import GroupItem from '../components/GroupItem'
import ContentItem from '../components/ContentItem'
import localization from '../utils/localization'
import ContentUtils from '../utils/content'
import ContentIcon from '../components/ContentIcon'
import TextLine from '../components/TextLine'
import moment from 'moment'
import ModalTotp from '../components/ModalTotp'
import ModalWallet from '../components/ModalWallet'
import ModalTag from '../components/ModalTag'
import ModalAlert from '../components/ModalAlert'
import UserUtils from '../utils/user'
import ServerUtils from '../utils/server'
import toast from 'react-hot-toast'
import AesUtils from '../utils/aes'
import ModalCreateMenu from '../components/ModalCreateMenu'
import ModalContent from '../components/ModalContent'
import content from '../utils/content'
import { pinyin } from 'pinyin-pro'
import ToolsUtils from '../utils/tools'

const SortEnumList = [{
  type: 'sortByTimeDesc',
  text: localization.strings.sortByTimeDesc,
  icon: 'icon-sort-amount-down1',
}, {
  type: 'sortByTimeAsc',
  text: localization.strings.sortByTimeAsc,
  icon: 'icon-sort-amount-up',
}, {
  type: 'sortByAlphaDesc',
  text: localization.strings.sortByAlphaDesc,
  icon: 'icon-sort-alpha-down-alt',
}, {
  type: 'sortByAlphaAsc',
  text: localization.strings.sortByAlphaAsc,
  icon: 'icon-sort-alpha-up-alt',
}]

function MainContent() {
  const [contentList, setContentList] = useState([])
  const [groupKeyword, setGroupKeyword] = useState('')
  const [groupList, setGroupList] = useState([])
  const [renderGroupList, setRenderGroupList] = useState([])
  const [groupMap, setGroupMap] = useState({})
  const [selectedGroup, setSelectedGroup] = useState('')
  const [contentKeyword, setContentKeyword] = useState('')
  const [contentItemList, setContentItemList] = useState([])
  const [renderItemList, setRenderItemList] = useState([])
  const [selectedItem, setSelectedItem] = useState({})
  const [totpModalOpen, setTotpModalOpen] = useState(false)
  const [walletModalOpen, setWalletModalOpen] = useState(false)
  const [createTagModalOpen, setCreateTagModalOpen] = useState(false)
  const [editTagModalOpen, setEditTagModalOpen] = useState(false)
  const [alertModalOpen, setAlertModalOpen] = useState(false)
  const [alertModalContent, setAlertModalContent] = useState('')
  const [alertModalHint, setAlertModalHint] = useState('')
  const [alertModalMode, setAlertModalMode] = useState('')
  const [createMenuModalOpen, setCreateMenuModalOpen] = useState(false)
  const [contentModalOpen, setContentModalOpen] = useState(false)
  const [contentModalType, setContentModalType] = useState(0)
  const [contentModalTitle, setContentModalTitle] = useState('')
  const [contentModalItem, setContentModalItem] = useState({})
  const [masterPassOpen, setMasterPassOpen] = useState(false)
  const [masterPassword, setMasterPassword] = useState('')
  const [masterPasswordErr, setMasterPasswordErr] = useState('')

  // sort
  const [anchorEl, setAnchorEl] = useState(null);
  const sortMenuOpen = Boolean(anchorEl);
  const [sortType, setSortType] = useState('sortByTimeDesc')
  const [sortIcon, setSortIcon] = useState('icon-sort-amount-down1')

  useEffect(() => {
    let tmpGroupList = ToolsUtils.sortTagList([...groupList], sortType)
    setRenderGroupList(tmpGroupList.filter((item) => !groupKeyword || item.text.toLowerCase().includes(groupKeyword.toLowerCase())))
  }, [groupKeyword, groupList, sortType])

  useEffect(() => {
    let tmpItemList = ToolsUtils.sortContentList([...contentItemList], sortType)
    setRenderItemList(tmpItemList.filter((item) => !contentKeyword || item.title.toLowerCase().includes(contentKeyword.toLowerCase())))
  }, [contentItemList, contentKeyword, sortType])

  function watchSelectedGroup() {
    let tmpItemList = []
    if (selectedGroup in groupMap) {
      tmpItemList = groupMap[selectedGroup]
    }
    setContentItemList(tmpItemList)
    for (let i in tmpItemList) {
      if (tmpItemList[i].id === selectedItem.id) {
        setSelectedItem(tmpItemList[i])
        break
      }
    }
  }

  useEffect(watchSelectedGroup, [selectedGroup, groupMap])

  useEffect(() => {
    ContentUtils.setContentListener(async (rawContentList) => {
      var tmpGroupList = []
      var tmpGroupMap = {}
      for (var i=0;i<rawContentList.length;i++) {
        if (rawContentList[i].type === ContentUtils.getBlockChainType()) {
          rawContentList[i].detail = JSON.parse(rawContentList[i].content)
        }
        var contentItem = rawContentList[i]
        if (typeof contentItem.tags === 'object'){
          for (var j=0;j<contentItem.tags.length;j++) {
            var tagItem = contentItem.tags[j]
            if (tagItem in tmpGroupMap) {
              tmpGroupMap[tagItem].push(contentItem)
            } else {
              tmpGroupList.push({
                id: tagItem,
                type: 0,
                text: tagItem,
              })
              tmpGroupMap[tagItem] = [contentItem]
            }
          }
        }
      }
      setContentList(rawContentList)
      setGroupList(tmpGroupList)
      tmpGroupMap[localization.strings.allPassword] = rawContentList.filter((item) => item.type === ContentUtils.getPasswordType())
      tmpGroupMap[localization.strings.allText] = rawContentList.filter((item) => item.type === ContentUtils.getTextType())
      tmpGroupMap[localization.strings.allTotp] = rawContentList.filter((item) => item.type === ContentUtils.getTotpType())
      tmpGroupMap[localization.strings.allDigitalWallet] = rawContentList.filter((item) => item.type === ContentUtils.getBlockChainType())
      setGroupMap(tmpGroupMap)
    })
  }, [])

  function alertModalCallback() {
    let userInfo = UserUtils.getUserInfo()
    switch (alertModalMode) {
      case 'delete_tag':
        let needUpdateContents = []
        for (let i in contentItemList) {
          let item = contentItemList[i]
          if (item.tags === undefined) {
            item.tags = []
          }
          if (item.tags.includes(selectedGroup)) {
            let copyItem = JSON.parse(JSON.stringify(item))
            copyItem.tags = copyItem.tags.filter((tagItem) => tagItem !== selectedGroup)
            needUpdateContents.push(copyItem)
          }
        }
        let lastModifyTime = new Date().getTime() / 1000
        ServerUtils.updateContent({
          version: userInfo.version,
          contents: needUpdateContents.map(function(contentItem) {
            return {
              content_id: contentItem.id,
              encrypted_data: AesUtils.encryptData(userInfo.account, UserUtils.getMasterPass(), JSON.stringify({
                title: contentItem.title,
                content: contentItem.content,
                type: contentItem.type,
                color: contentItem.color,
                account: contentItem.account,
                extra: contentItem.extra,
                tags: contentItem.tags,
              })),
            }
          }),
        }).then(async (resp) => {
          if (!resp.ok) {
            try {
              let respJson = await resp.json()
              if (respJson.errno !== 0) {
                toast.error(ServerUtils.handleError(respJson))
                return
              }
            } catch(e) {

            }
            toast.error(localization.strings.networkErr)
            return
          }
          let respJson = await resp.json()
          if (respJson.errno !== 0) {
            toast.error(ServerUtils.handleError(respJson))
            return
          }
          ContentUtils.updateContents(needUpdateContents.map(function(contentItem) {
            return {
              id: contentItem.id,
              version: userInfo.version,
              title: contentItem.title,
              content: contentItem.content,
              type: contentItem.type,
              color: contentItem.color,
              account: contentItem.account,
              last_modify_time: lastModifyTime,
              extra: contentItem.extra,
              tags: contentItem.tags,
            }
          }))
          toast.success(localization.strings.successDelete)
          setSelectedGroup('')
          setSelectedItem({})
          setAlertModalOpen(false)
        })
        return
      case 'delete_content':
        let id = selectedItem.id
        ServerUtils.deleteContent(id, userInfo.version).then(async (resp) => {
          if (!resp.ok) {
            try {
              let respJson = await resp.json()
              if (respJson.errno !== 0) {
                toast.error(ServerUtils.handleError(respJson))
                return
              }
            } catch(e) {

            }
            toast.error(localization.strings.networkErr)
            return
          }
          let respJson = await resp.json()
          if (respJson.errno !== 0) {
            toast.error(ServerUtils.handleError(respJson))
            return
          }
          ContentUtils.deleteContent(id)
          setSelectedItem({})
          setAlertModalOpen(false)
          toast.success(localization.strings.successDelete)
        })
        return
    }
  }

  function deleteTag() {
    setAlertModalContent(`${localization.strings.deleteTag} "${selectedGroup}"`)
    setAlertModalHint(localization.strings.deleteTagHint)
    setAlertModalMode('delete_tag')
    setAlertModalOpen(true)
  }

  function deleteContent() {
    setAlertModalContent(`${localization.strings.deleteContent} "${selectedItem.title}"`)
    setAlertModalHint('')
    setAlertModalMode('delete_content')
    setAlertModalOpen(true)
  }

  function checkMasterPass() {
    let userInfo = UserUtils.getUserInfo()
    if (masterPassword === '') {
      setMasterPasswordErr(localization.strings.required)
      return
    }
    var decryptedAccount = AesUtils.decryptData(userInfo.account, masterPassword, userInfo.signed_account)
    if (decryptedAccount !== userInfo.account) {
      setMasterPasswordErr(localization.strings.errorMasterPassword)
      return
    }
    setMasterPassOpen(false)
    setWalletModalOpen(true)
  }

  return (
    <div style={{
      height: '100%',
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
    }}>
      {/* group sidebar */}
      <div style={{
        height: '100%',
        width: '20rem',
        display: 'flex',
        flexDirection: 'column',
        borderRight: `1px solid ${ColorUtils.divider}`,
      }}>
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          padding: '0.5rem 0.5rem',
        }}>
          <div style={{
            backgroundColor: ColorUtils.purpleBg,
            marginRight: '0.5rem',
            color: ColorUtils.purple,
            padding: '0.5rem',
            borderRadius: '0.5rem',
            cursor: 'pointer',
          }} onClick={(event) => {
            setAnchorEl(event.currentTarget);
          }}>
            <i className={`iconfont ${sortIcon}`} style={{
              fontSize: '1.3rem',
            }}></i>
          </div>
          <Menu
            anchorEl={anchorEl}
            open={sortMenuOpen}
            onClose={() => {
              setAnchorEl(null);
            }}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}>
            {
              SortEnumList.map((item) => {
                return (
                  <MenuItem key={item.type} onClick={() => {
                    setSortType(item.type)
                    setSortIcon(item.icon)
                    setAnchorEl(null);
                  }}>
                    <i className={`iconfont ${item.icon}`} style={{
                      fontSize: '1rem',
                      marginRight: '0.5rem',
                    }}></i>
                    {item.text}
                  </MenuItem>
                )
              })
            }
          </Menu>
          <div style={{
            flex: 1,
          }}>
            <FormInput start_icon="icon-search1" placeholder={localization.strings.keywordHint} size="small" onChange={(event) => {
              setGroupKeyword(event.target.value)
            }}/>
          </div>
          <div style={{
            backgroundColor: ColorUtils.greenBg,
            marginLeft: '0.5rem',
            color: ColorUtils.green,
            padding: '0.5rem',
            borderRadius: '0.5rem',
            cursor: 'pointer',
          }} onClick={() => {
            setCreateTagModalOpen(true)
          }}>
            <i className="iconfont icon-add" style={{
              fontSize: '1.3rem',
            }}></i>
          </div>
        </div>
        <div style={{
          flex: 1,
          overflowY: 'scroll',
        }}>
          {
            (!groupKeyword || localization.strings.allPassword.toLowerCase().includes(groupKeyword.toLowerCase())) && (
              <div style={{
                padding: '0rem 0.5rem 0.5rem',
              }}>
                <GroupItem icon="icon-key1" contentType={ContentUtils.getPasswordType()} text={localization.strings.allPassword}
                  active={selectedGroup === localization.strings.allPassword} onClick={() => {
                    setSelectedGroup(localization.strings.allPassword)
                  }}/>
              </div>
            )
          }
          {
            (!groupKeyword || localization.strings.allText.toLowerCase().includes(groupKeyword.toLowerCase())) && (
              <div style={{
                padding: '0rem 0.5rem 0.5rem',
              }}>
                <GroupItem icon="icon-file-text" contentType={ContentUtils.getTextType()} text={localization.strings.allText} active={selectedGroup === localization.strings.allText} onClick={() => {
                    setSelectedGroup(localization.strings.allText)
                  }}/>
              </div>
            )
          }
          {
            (!groupKeyword || localization.strings.allTotp.toLowerCase().includes(groupKeyword.toLowerCase())) && (
              <div style={{
                padding: '0rem 0.5rem 0.5rem',
              }}>
                <GroupItem icon="icon-stopwatch" contentType={ContentUtils.getTotpType()} text={localization.strings.allTotp} active={selectedGroup === localization.strings.allTotp} onClick={() => {
                    setSelectedGroup(localization.strings.allTotp)
                  }}/>
              </div>
            )
          }
          {
            (!groupKeyword || localization.strings.allDigitalWallet.toLowerCase().includes(groupKeyword.toLowerCase())) && (
              <div style={{
                padding: '0rem 0.5rem 0.5rem',
              }}>
                <GroupItem icon="icon-bitcoin" contentType={ContentUtils.getBlockChainType()} text={localization.strings.allDigitalWallet} active={selectedGroup === localization.strings.allDigitalWallet} onClick={() => {
                    setSelectedGroup(localization.strings.allDigitalWallet)
                  }}/>
              </div>
            )
          }
          {
            renderGroupList.map((item) => {
              return (
                <div key={item.text} style={{
                  padding: '0rem 0.5rem 0.5rem',
                }}>
                  <GroupItem icon="icon-tag1" contentType={ContentUtils.getTagType()} text={item.text}
                    active={selectedGroup === item.text} onClick={() => {
                      setSelectedGroup(item.text)
                    }}/>
                </div>
              )
            })
          }
        </div>
      </div>

      {/* items sidebar */}
      <div style={{
        height: '100%',
        width: [localization.strings.allPassword, localization.strings.allText, localization.strings.allTotp,
          localization.strings.allDigitalWallet].includes(selectedGroup) ? '20rem' : '23rem',
        borderRight: `1px solid ${ColorUtils.divider}`,
      }}>
        {
          selectedGroup && (
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              overflowY: 'hidden',
            }}>
              <div style={{
                height: '3.5rem',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                padding: '0rem 0.5rem',
              }}>
                {
                  ![localization.strings.allPassword, localization.strings.allText, localization.strings.allTotp,
                    localization.strings.allDigitalWallet].includes(selectedGroup) && (
                    <div style={{
                      backgroundColor: ColorUtils.lightRed,
                      marginRight: '0.5rem',
                      color: ColorUtils.red,
                      padding: '0.5rem',
                      borderRadius: '0.5rem',
                      cursor: 'pointer',
                    }} onClick={deleteTag}>
                      <i className="iconfont icon-delete" style={{
                        fontSize: '1.3rem',
                      }}></i>
                    </div>
                  )
                }
                {
                  ![localization.strings.allPassword, localization.strings.allText, localization.strings.allTotp,
                    localization.strings.allDigitalWallet].includes(selectedGroup) && (
                    <div style={{
                      backgroundColor: ColorUtils.lightBlue,
                      marginRight: '0.5rem',
                      color: ColorUtils.blue,
                      padding: '0.5rem',
                      borderRadius: '0.5rem',
                      cursor: 'pointer',
                    }} onClick={() => {
                      setEditTagModalOpen(true)
                    }}>
                      <i className="iconfont icon-pen01" style={{
                        fontSize: '1.3rem',
                      }}></i>
                    </div>
                  )
                }
                <div style={{
                  flex: 1,
                }}>
                  <FormInput start_icon="icon-search1" placeholder={localization.strings.keywordHint} size="small" onChange={(event) => {
                    setContentKeyword(event.target.value)
                  }}/>
                </div>
                <div style={{
                  backgroundColor: ColorUtils.greenBg,
                  marginLeft: '0.5rem',
                  color: ColorUtils.green,
                  padding: '0.5rem',
                  borderRadius: '0.5rem',
                  cursor: 'pointer',
                }} onClick={() => {
                  setCreateMenuModalOpen(true)
                }}>
                  <i className="iconfont icon-add" style={{
                    fontSize: '1.3rem',
                  }}></i>
                </div>
              </div>
              <div style={{
                flex: 1,
                overflowY: 'scroll',
              }}>
                {
                  renderItemList.map((item) => {
                    return (
                      <div key={item.id} style={{
                        padding: '0rem 0.5rem 0.5rem',
                      }}>
                        <ContentItem contentType={item.type} text={item.title} active={item.id === selectedItem.id} onClick={() => {
                          if (item.type === ContentUtils.getBlockChainType()) {
                            item.detail = JSON.parse(item.content)
                          }
                          setSelectedItem(item)
                        }}/>
                      </div>
                    )
                  })
                }
              </div>
            </div>
          )
        }
      </div>

      {/* content */}
      {
        selectedItem.id && (
          <div style={{
            flex: 1,
            height: '100%',
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            overflowY: 'scroll',
            minWidth: '30rem',
          }}>
            <div style={{
              padding: '15% 2rem',
            }}>
              <div style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                marginBottom: '1rem',
              }}>
                <div style={{
                  width: '0px',
                  flex: 9,
                  textAlign: 'right',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'end',
                }}>
                  <ContentIcon contentType={selectedItem.type} size="3rem" fontSize="2rem"/>
                </div>
                <div style={{
                  width: '1rem',
                }}></div>
                <div style={{
                  lineHeight: '3rem',
                  fontSize: '2rem',
                  color: ColorUtils.textColor,
                  flex: 11,
                  overflowX: 'hidden',
                  textOverflow: 'ellipsis',
                }}>{selectedItem.title}</div>
              </div>
              {
                selectedItem.type === ContentUtils.getPasswordType() && (
                  <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                    <div style={{
                      width: '0px',
                      flex: 9,
                      textAlign: 'right',
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'end',
                      fontSize: '1rem',
                    }}>{localization.strings.account}</div>
                    <div style={{
                      width: '1rem',
                    }}></div>
                    <div style={{
                      flex: 11,
                      overflowX: 'hidden',
                      textOverflow: 'ellipsis',
                    }}>
                      <TextLine text={selectedItem.account}/>
                    </div>
                  </div>
                )
              }

              {/* blockchain detail */}
              {
                selectedItem.type === ContentUtils.getBlockChainType() && (
                  <div>
                    <div style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                      <div style={{
                        width: '0px',
                        flex: 9,
                        textAlign: 'right',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'end',
                        fontSize: '1rem',
                      }}>{localization.strings.coinName}</div>
                      <div style={{
                        width: '1rem',
                      }}></div>
                      <div style={{
                        flex: 11,
                      }}>
                        <pre style={{
                          fontSize: '1rem',
                        }}>{selectedItem.detail.coinName}</pre>
                      </div>
                    </div>
                    <div style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                      <div style={{
                        width: '0px',
                        flex: 9,
                        textAlign: 'right',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'end',
                        fontSize: '1rem',
                      }}>{localization.strings.address}</div>
                      <div style={{
                        width: '1rem',
                      }}></div>
                      <div style={{
                        flex: 11,
                      }}>
                        <TextLine text={selectedItem.detail.address}/>
                      </div>
                    </div>
                    <div style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                      <div style={{
                        width: '0px',
                        flex: 9,
                        textAlign: 'right',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'end',
                        fontSize: '1rem',
                      }}>{localization.strings.walletDetail}</div>
                      <div style={{
                        width: '1rem',
                      }}></div>
                      <div style={{
                        flex: 11,
                      }}>
                        <Button variant="contained" size="small" color="info" onClick={() => {
                          setMasterPassOpen(true)
                        }}>
                          {localization.strings.showWalletDetail}
                        </Button>
                      </div>
                    </div>
                  </div>
                )
              }

              {
                ContentUtils.getContentKey(selectedItem.type) && (
                  <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                    <div style={{
                      width: '0px',
                      flex: 9,
                      textAlign: 'right',
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'end',
                      fontSize: '1rem',
                    }}>{ContentUtils.getContentKey(selectedItem.type)}</div>
                    <div style={{
                      width: '1rem',
                    }}></div>
                    <div style={{
                      flex: 11,
                      overflowX: 'hidden',
                      textOverflow: 'ellipsis',
                    }}>
                      <TextLine text={selectedItem.content}/>
                    </div>
                  </div>
                )
              }
              {
                selectedItem.type === ContentUtils.getTotpType() && (
                  <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                    <div style={{
                      width: '0px',
                      flex: 9,
                      textAlign: 'right',
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'end',
                      fontSize: '1rem',
                    }}>{localization.strings.googleAuthCode}</div>
                    <div style={{
                      width: '1rem',
                    }}></div>
                    <div style={{
                      flex: 11,
                    }}>
                      <Button variant="contained" size="small" color="info" onClick={() => {
                        setTotpModalOpen(true)
                      }}>
                        {localization.strings.showGoogleAuthCode}
                      </Button>
                    </div>
                  </div>
                )
              }
              {
                typeof selectedItem.extra === 'object' && Object.keys(selectedItem.extra).map((key) => {
                  return (
                    <div key={key} style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                      <div style={{
                        width: '0px',
                        flex: 9,
                        textAlign: 'right',
                        fontSize: '1rem',
                        overflowX: 'hidden',
                        textOverflow: 'ellipsis',
                      }}>{key}</div>
                      <div style={{
                        width: '1rem',
                      }}></div>
                      <div style={{
                        flex: 11,
                        overflowX: 'hidden',
                        textOverflow: 'ellipsis',
                      }}>
                        <TextLine text={selectedItem.extra[key]}/>
                      </div>
                    </div>
                  )
                })
              }
              <div style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
                <div style={{
                  width: '0px',
                  flex: 9,
                  textAlign: 'right',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'end',
                  fontSize: '1rem',
                }}>{localization.strings.modifyTime}</div>
                <div style={{
                  width: '1rem',
                }}></div>
                <div style={{
                  flex: 11,
                }}>
                  <pre style={{
                    fontSize: '1rem',
                  }}>{moment.unix(selectedItem.last_modify_time).format('YYYY-MM-DD HH:mm:ss')}</pre>
                </div>
              </div>
            </div>

            {/* item actions */}
            <div style={{
              position: 'absolute',
              top: '1rem',
              right: '1rem',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}>
              <div style={{
                backgroundColor: ColorUtils.lightBlue,
                marginLeft: '0.6rem',
                color: ColorUtils.darkBlue,
                padding: '0.5rem',
                borderRadius: '0.5rem',
                cursor: 'pointer',
                width: '1.2rem',
                height: '1.2rem',
                lineHeight: '1.2rem',
                textAlign: 'center',
              }} onClick={() => {
                setContentModalType(selectedItem.type)
                switch (selectedItem.type) {
                  case ContentUtils.getPasswordType():
                    setContentModalTitle(localization.strings.modifyPassword)
                    break
                  case ContentUtils.getTextType():
                    setContentModalTitle(localization.strings.modifyText)
                    break
                  case ContentUtils.getTotpType():
                    setContentModalTitle(localization.strings.modifyTotp)
                    break
                  case ContentUtils.getBlockChainType():
                    setContentModalTitle(localization.strings.modifyBlockchain)
                    break
                }
                setContentModalItem(selectedItem)
                setContentModalOpen(true)
              }}>
                <i className="iconfont icon-pen01" style={{
                  fontSize: '1.1rem',
                }}></i>
              </div>
              <div style={{
                backgroundColor: ColorUtils.lightRed,
                marginLeft: '0.6rem',
                color: ColorUtils.darkRed,
                padding: '0.5rem',
                borderRadius: '0.5rem',
                cursor: 'pointer',
                width: '1.2rem',
                height: '1.2rem',
                lineHeight: '1.2rem',
                textAlign: 'center',
              }} onClick={deleteContent}>
                <i className="iconfont icon-delete" style={{
                  fontSize: '1.1rem',
                }}></i>
              </div>
            </div>
          </div>
        )
      }

      {
        selectedItem.type === ContentUtils.getTotpType() && (
          <ModalTotp open={totpModalOpen} secret={selectedItem.content} onClose={() => {
            setTotpModalOpen(false)
          }}/>
        )
      }
      {
        selectedItem.type === ContentUtils.getBlockChainType() && (
          <ModalWallet open={walletModalOpen} contentInfo={selectedItem} onClose={() => {
            setWalletModalOpen(false)
          }}/>
        )
      }
      <ModalTag title={localization.strings.createTag} open={createTagModalOpen} onClose={() => {
        setCreateTagModalOpen(false)
      }} onTagName={(tagName) => {
        setSelectedGroup(tagName)
      }} tagName={''} contentList={contentList} groupMap={groupMap} sortType={sortType}/>
      <ModalTag title={localization.strings.modifyTag} open={editTagModalOpen} onClose={() => {
        setEditTagModalOpen(false)
      }} onTagName={(tagName) => {
        setSelectedGroup(tagName)
      }} tagName={selectedGroup} contentItemList={contentItemList} contentList={contentList} groupMap={groupMap} sortType={sortType}/>
      <ModalAlert title={localization.strings.alertDialog} content={alertModalContent} hint={alertModalHint} open={alertModalOpen} onCancel={() => {
        setAlertModalOpen(false)
      }} onConfirm={alertModalCallback}/>
      <ModalCreateMenu open={createMenuModalOpen} onClose={() => {
        setCreateMenuModalOpen(false)
      }} onClick={(contentType) => {
        setCreateMenuModalOpen(false)
        setContentModalType(contentType)
        switch (contentType) {
          case ContentUtils.getPasswordType():
            setContentModalTitle(localization.strings.createPassword)
            break
          case ContentUtils.getTextType():
            setContentModalTitle(localization.strings.createText)
            break
          case ContentUtils.getTotpType():
            setContentModalTitle(localization.strings.createTotp)
            break
          case ContentUtils.getBlockChainType():
            setContentModalTitle(localization.strings.createBlockchain)
            break
        }
        setContentModalItem({})
        setContentModalOpen(true)
      }}/>
      <ModalContent open={contentModalOpen} title={contentModalTitle} contentType={contentModalType} onClose={() => {
          setContentModalOpen(false)
        }} tag={[localization.strings.allPassword, localization.strings.allText, localization.strings.allTotp,
          localization.strings.allDigitalWallet].includes(selectedGroup) ? '' : selectedGroup}
        onCreate={(contentInfo) => {
          setSelectedItem(contentInfo)
          if ([localization.strings.allPassword, localization.strings.allText, localization.strings.allTotp,
            localization.strings.allDigitalWallet].includes(selectedGroup)) {
            switch (contentInfo.type) {
              case ContentUtils.getPasswordType():
                setSelectedGroup(localization.strings.allPassword)
                break
              case ContentUtils.getTextType():
                setSelectedGroup(localization.strings.allText)
                break
              case ContentUtils.getTotpType():
                setSelectedGroup(localization.strings.allTotp)
                break
              case ContentUtils.getBlockChainType():
                setSelectedGroup(localization.strings.allDigitalWallet)
                break
            }
          }
        }} contentInfo={contentModalItem}/>

      { /* WalletDetail Master Password Dialog */}
      <Dialog open={masterPassOpen} onClose={(event) => {}}>
        <DialogTitle>{localization.strings.masterPassword}</DialogTitle>
        <DialogContent>
          <div style={{
            margin: '1rem 0rem 1rem',
          }}>
            <form autoComplete="on" action="javascript:void(0);">
              <FormInput type="password" start_icon="icon-lock" label={localization.strings.masterPassword}
                placeholder={localization.strings.inputMasterPasswordHint} error={masterPasswordErr} onChange={(event) => {
                  setMasterPassword(event.target.value)
                  setMasterPasswordErr('')
                }} onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    checkMasterPass()
                    return
                  }
                }}/>
            </form>
          </div>
          <div style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}>
            <Button variant="outlined" size="small" color="warning" onClick={() => {
              setMasterPassOpen(false)
            }}>{localization.strings.cancel}</Button>
            <div style={{flex:1}}></div>
            <Button variant="outlined" size="small" color="primary" onClick={checkMasterPass}>{localization.strings.confirm}</Button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default MainContent;