import LocalizedStrings from "react-localization";

function getUrlVars() {
  let vars = {};
  window.location.href.replace(
    /[?&]+([^=&]+)=([^&]*)/gi,
    function (m, key, value) {
      vars[key] = value;
      return "";
    },
  );
  return vars;
}

function getUrlParam(parameter, defaultValue) {
  var urlParameter = defaultValue;
  if (window.location.href.indexOf(parameter) > -1) {
    urlParameter = getUrlVars()[parameter];
  }
  return urlParameter;
}
var lang = getUrlParam("lang", "");

if (lang === "") {
  var localLang = navigator.language;
  if (typeof localLang === "string" && localLang.indexOf("zh") >= 0) {
    lang = "zh";
  }
} else {
  if (lang === "cn") {
    lang = "zh";
  }
}

let strings = new LocalizedStrings({
  en: {
    language: "Language",
    indexTitle: " manage all your accounts and passwords",
    indexTitleDesc:
      "Internet security is more important than ever. EasyPass guarantees the security of accounts and passwords, making the Internet simple and accessible.",
    secure: "Securely",
    download: "Download APP",
    indexSection1Title: "Why choose EasyPass",
    indexSection1SubTitle: "Key Features",
    indexKeyFeature1Title: "Simple",
    indexKeyFeature1Desc:
      "EasyPass supports one-click generation of passwords, one-click copying, fast search, and supports Android, Web and Wechat miniprogram. Support both fingerprint and face id authentication.",
    indexKeyFeature2Title: "Convenience",
    indexKeyFeature2Desc:
      "EasyPass supports cloud synchronization, allowing users to synchronize their data between mobile phones, computers anytime, anywhere. It is amazing for user to manage password with EasyPass.",
    indexKeyFeature3Title: "Safe",
    indexKeyFeature3Desc:
      "EasyPass uses the most popular and advanced AES encryption algorithm available today (no one can decrypt data without master password). Data is saved and transmitted encrypted by the master password, and EasyPass does not transfer the master password.",
    indexKeyFeature4Title: "Digital Wallet",
    indexKeyFeature4Desc:
      "Support digital wallet manager. User can save their digital wallet and query the balance of wallet in EasyPass. In addition, user also can query the price of digital coin and the price of their digital wallet.",
    indexKeyFeature5Title: "Encrypted Message",
    indexKeyFeature5Desc:
      "Encrypted Message use AES encryption algorithm and Validity period to ensure that users can send data safely.",
    indexSection2Title: "Realtime Online Created Password",
    indexSection2SubTitle:
      "The encryption algorithm of EasyPass is powerful and cannot be cracked. The encrypted password is shown as follows.",
    refresh: "Refresh",
    networkErr: "Network Error",
    encryptedData: "Encrypted Password",
    passwordCreateTime: "Password Create Time",
    pricing: "Pricing",
    freeUser: "Free User",
    editPassword: "Create, edit and delete password",
    editText: "Create, edit and delete text",
    editTotp: "Create, edit and delete google auth",
    editBlockchain: "Create, edit and delete digital wallet",
    editMessage: "Send and receive encrypted message",
    editTag: "Create, edit and delete tag",
    addExtraKeys: "Add extra keys",
    tagAddContent:
      "Add exists password, text, google auth and digital wallet to tag",
    newUserVipTime: "7 days VIP time free for new user",
    vipUser: "VIP User",
    vipPayInfo: "19.9CNY / Forever",
    login: "Login",
    register: "Register",
    account: "Account",
    masterPassword: "Master Password",
    goRegister: "Go Register",
    goLogin: "Go Login",
    masterPasswordAlert:
      "Attention: All data of you will be encrypted by Master Password and AES encryption algorithm. If Master Password is lost, no one can decrypt your data. So please keep your Master Password safe carefully !",
    inputAccountHint: "Please input account",
    inputMasterPasswordHint: "Please input Master Password",
    inputMasterPasswordHintRepeat: "Please input MasterPassword again",
    captcha: "Captcha",
    inputCaptchaHint: "Please input captcha solution",
    inviteCode: "Invite Code (Optional)",
    inputInviteCodeHint: "Please input invite code",
    required: "Required",
    masterPasswordNotMatch: "MasterPasswords is different",
    errorAccountOrMasterPassword: "Account or MasterPassword error",
    accountExists: "Account exists",
    captchaSolutionWrong: "Captcha solution wrong, please input again",
    successRegister: "Register success",
    allContent: "All Content",
    encryptedMessage: "Encrypted Message",
    logout: "Logout",
    confirm: "Confirm",
    errorMasterPassword: "Master password wrong",
    keyword: "Keyword",
    keywordHint: "Please input keyword",
    allPassword: "All Password",
    allText: "All Text",
    allTotp: "All Google Auth",
    allDigitalWallet: "All Digital Wallet",
    copySuccess: "Copy Success",
    password: "Password",
    text: "Text",
    totp: "GoogleAuth",
    blockchain: "Digital Wallet",
    totpSecret: "GoogleAuth Secret",
    modifyTime: "Modify Time",
    googleAuthCode: "GoogleAuth Code",
    showGoogleAuthCode: "Show GoogleAuth Code",
    clickCopy: "Click to copy",
    coinName: "Coin Name",
    address: "Address",
    walletDetail: "Wallet Detail",
    showWalletDetail: "Show Wallet Detail",
    title: "Title",
    pubkey: "Public Key",
    privKey: "Private Key",
    keyStore: "Key Store",
    mnemonic: "Mnemonic Word",
    createTag: "Create Tag",
    modifyTag: "Modify Tag",
    tagName: "Tag Name",
    inputTagNameHint: "Please input tag name",
    cancel: "Cancel",
    exists: "Exists",
    contentRequired: "Please select at least one password",
    successModify: "Modify Success",
    alertDialog: "Alert Dialog",
    deleteTag: "Are you sure to delete the tag?",
    deleteTagHint:
      "It will delete tag but not contents. You can find contents in all password, all text, all GoogleAuth and all Digital Wallet",
    createPassword: "Create New Password",
    modifyPassword: "Modify Password",
    createText: "Create New Text",
    modifyText: "Modify Text",
    createTotp: "Create New GoogleAuth",
    modifyTotp: "Modify GoogleAuth",
    createBlockchain: "Create New DigitalWallet",
    modifyBlockchain: "Modify DigitalWallet",
    inputTitleHint: "Please input title",
    inputPasswordHint: "Please input password",
    inputTextHint: "Please input text",
    inputTotpHint: "Please input GoogleAuth Secret",
    length: "Length",
    genPassword: "Generate",
    optional: "Optional",
    keyName: "Key Name",
    inputKeyNameHint: "Please input key name",
    addKey: "Add Key",
    value: "Value",
    inputValueHint: "Please input value",
    successCreate: "Create Success",
    successDelete: "Delete Success",
    deleteContent: "Are you sure to delete?",
    inputCoinNameHint: "Please input coin name",
    inputAddressHint: "Please input address",
    inputPubKeyHint: "Please input public key",
    inputPriKeyHint: "Please input private key",
    inputKeyStoreHint: "Please input key store",
    inputMnemonicHint: "Please input mnemonic words",
    sortByTimeDesc: "Sort by time descending",
    sortByTimeAsc: "Sort by time ascending",
    sortByAlphaDesc: "Sort by alpha descending",
    sortByAlphaAsc: "Sort by alpha descending",
    sendExistPassword: "Send exist password",
    sendNewPassword: "Send new password",
    validityPeriod: "Validity Period",
    minute: "Minute",
    hour: "Hour",
    day: "Day",
    tempPassword: "Temporary Password",
    inputTempPassHint: "Please input temporary password",
    messageHintWord: "Hint Word",
    inputMessageHintWordHint: "Please input hint word",
    goBack: "Go Back",
    goNext: "Go Next",
    pack: "Pack",
    packing: "Packing",
    packResult:
      "Message has be packed. Please click to copy the url and send to receiver.",
    contentType: "Content Type",
    unlock: "Unlock",
    passwordWrong: "password wrong",
    unknownError: "Unknown Error",
    messageNotFound: "Message not found or expired",
    save: "Save",
    currAccount: "Current Account",
    switchAccount: "Switch Account",
    saveSuccess: "Save Success",
    userTotal: "User Total",
    userYesterday: "Yesterday New User Total",
    contentYesterday: "Yesterday New and Modify Content Total",
    contentTotal: "Content Total",
    accountExpired: "Account is expired, Please pay for use",
    easypassPromise: "EasyPass Promise",
    promiseItem1:
      "1. All data will be encrypted by Master Password and AES algorithm before saved and sent.",
    promiseItem2: "2. EasyPass will never send Master Password in network.",
    promiseItem3: "3. Without Master Password, no one can decrypt user's data.",
    promiseItem4:
      "4. EasyPass App will save encrypted data in local, so users can read their data without network.",
    userNotice: "User Notice",
    userNoticeItem1: "1. Users must keep their account and master password.",
    userNoticeItem2:
      "2. Users must not tell others about their account and master password.",
    agree: "Agree",
    goPay: "Go Pay",
    loading: "Loading",
    easypassBinancePayTitle: "EasyPass Binance Pay Qrcode",
    easypassBinancePrice: "2.9 USDT / Forever",
    easypassBinaceHint: "Please use binance to scan the qrcode and pay.",
    easypassWechatPayTitle: "EasyPass Wechat Pay Qrcode",
    easypassWechatPrice: "19.9CNY / Forever",
    easypassWechatPayHint: "Please use wechat to scan the qrcode and pay.",
    easypassBody: "EasyPass Pay",
    payed: "Payed",
    userInfo: "User Info",
    expiryTime: "Expiry Time",
    contactUs: "Contact us",
    email: "Email",
    wechat: "Wechat",
    help: "Help",
    feedback: "Feedback",
    feedbackContent: "Feedback Content",
    inputFeedbackContentHint: "Please input feedback content",
    thankFeedback: "Thank you for your feedback",
    changeMasterPassword: "Change Master Password",
    currMasterPassword: "Current Master Password",
    inputCurrMasterPasswordHint: "Please input current master password",
    newMasterPassword: "New Master Password",
    inputNewMasterPasswordHint: "Please input new master password",
    inputNewMasterPasswordAgainHint: "Please input new master password again",
    newMasterPasswordNotMatch: "New master passwords is different",
    masterPasswordSame:
      "The current master password is the same as the new password",
    scratchPayBody: "ScratchPaper Pay",
    easynotePayBody: "EasyNote Pay",
    pageNotFound: "Page Not Found",
    hasPaidTitle: "You had paid. Thank you!",
    contentMaxLimit: "Content Length exceeds limit",
    autocomplete: "Autocomplete",
    extension: "Browser Extension",
  },
  zh: {
    language: "语言",
    indexTitle: "地管理您的所有帐户和密码",
    indexTitleDesc:
      "随着购物、社交、工作、娱乐、资产的逐步互联网化，互联网安全空前重要。EasyPass保证账户、密码、资产的安全，让互联网简单且触手可及。",
    secure: "安全",
    download: "下载APP",
    indexSection1Title: "为什么选择EasyPass",
    indexSection1SubTitle: "关键特性",
    indexKeyFeature1Title: "简单",
    indexKeyFeature1Desc:
      "EasyPass支持一键生成密码、一键复制、快速搜索，同时支持IOS、Android、Web和微信小程序。支持指纹识别和人脸识别。",
    indexKeyFeature2Title: "方便",
    indexKeyFeature2Desc:
      "EasyPass支持云端同步，用户能够随时随地在手机、电脑、平板之间同步自己的数据，让用户体验极致的密码管理。",
    indexKeyFeature3Title: "安全",
    indexKeyFeature3Desc:
      "EasyPass采用目前最流行且最先进的AES加密算法（无法被暴力破解）。数据的保存和传输都会经过主密码加密，且EasyPass不会传输主密码。",
    indexKeyFeature4Title: "数字钱包",
    indexKeyFeature4Desc:
      "支持数字钱包管理。用户可以保存自己的数字钱包，并查询数字钱包中的数字货币金额。此外，用户也可以查询到数字货币的单价和钱包的总价值。",
    indexKeyFeature5Title: "密信",
    indexKeyFeature5Desc:
      "密信使用AES256加密算法和有效期双重保障，使得用户能够相互之间放心地传输数据而不用担心内容泄露。",
    indexSection2Title: "实时最新创建的密码",
    indexSection2SubTitle:
      "EasyPass的加密算法无比强大，且无法被破解。加密后的密码如下所示",
    refresh: "刷新",
    networkErr: "网络错误",
    encryptedData: "加密后的密码",
    passwordCreateTime: "密码创建时间",
    pricing: "定价",
    freeUser: "免费用户",
    editPassword: "创建、修改和删除密码",
    editText: "创建、修改和删除文本",
    editTotp: "创建、修改和删除谷歌认证",
    editBlockchain: "创建、修改和删除数字钱包",
    editMessage: "发送和接收密信",
    editTag: "创建、修改和删除标签",
    addExtraKeys: "添加自定义字段",
    tagAddContent: "添加现有密码、文本、谷歌认证和数字钱包到标签",
    newUserVipTime: "新用户将获赠7天的免费VIP时间",
    vipUser: "VIP用户",
    vipPayInfo: "19.9元 / 永久",
    login: "登录",
    register: "注册",
    account: "账号",
    masterPassword: "主密码",
    goRegister: "去注册",
    goLogin: "去登录",
    masterPasswordAlert:
      "注意：您的所有数据都将通过AES加密算法和主密码加密。如果主密码遗失，您的数据将无法恢复。请妥善保管您的主密码！",
    inputAccountHint: "请输入帐号",
    inputMasterPasswordHint: "请输入主密码",
    inputMasterPasswordHintRepeat: "请再次输入主密码",
    captcha: "验证码",
    inputCaptchaHint: "请输入图片验证码",
    inviteCode: "邀请码 (选填)",
    inputInviteCodeHint: "请输入邀请码",
    required: "必填",
    masterPasswordNotMatch: "两次主密码不同",
    errorAccountOrMasterPassword: "帐号或主密码错误",
    accountExists: "帐号已存在",
    captchaSolutionWrong: "图片校验码错误，请重新输入",
    successRegister: "注册成功",
    allContent: "所有内容",
    encryptedMessage: "密信",
    logout: "退出",
    confirm: "确认",
    errorMasterPassword: "主密码错误",
    keyword: "关键字",
    keywordHint: "请输入关键字",
    allPassword: "所有密码",
    allText: "所有文本",
    allTotp: "所有Google验证",
    allDigitalWallet: "所有数字钱包",
    copySuccess: "复制成功",
    password: "密码",
    text: "文本",
    totp: "谷歌验证",
    blockchain: "数字钱包",
    totpSecret: "谷歌验证Secret",
    modifyTime: "修改时间",
    googleAuthCode: "谷歌验证码",
    showGoogleAuthCode: "显示谷歌验证码",
    clickCopy: "点击复制",
    coinName: "币名",
    address: "Address",
    walletDetail: "钱包详情",
    showWalletDetail: "显示钱包详情",
    title: "标题",
    pubkey: "公钥",
    privKey: "私钥",
    keyStore: "Key Store",
    mnemonic: "助记词",
    createTag: "创建标签",
    modifyTag: "编辑标签",
    tagName: "标签名",
    inputTagNameHint: "请输入标签名",
    cancel: "取消",
    exists: "已存在",
    contentRequired: "请至少选择一个密码",
    successModify: "修改成功",
    alertDialog: "告警对话框",
    deleteTag: "确认是否删除该标签？",
    deleteTagHint:
      "它将删除标签而不是内容。 您可以在所有密码，所有文本，所有GoogleAuth和所有数字钱包中找到内容",
    createPassword: "创建新密码",
    modifyPassword: "修改密码",
    createText: "创建新文本",
    modifyText: "修改文本",
    createTotp: "创建谷歌验证",
    modifyTotp: "修改谷歌验证",
    createBlockchain: "创建数字钱包",
    modifyBlockchain: "修改数字钱包",
    inputTitleHint: "请输入标题",
    inputPasswordHint: "请输入密码",
    inputTextHint: "请输入文本",
    inputTotpHint: "请输入谷歌验证Secret",
    length: "位",
    genPassword: "生成密码",
    optional: "选填",
    keyName: "字段名",
    inputKeyNameHint: "请输入字段名",
    addKey: "添加字段",
    value: "值",
    inputValueHint: "请输入值",
    successCreate: "创建成功",
    successDelete: "删除成功",
    deleteContent: "是否确定删除？",
    inputCoinNameHint: "请输入币名",
    inputAddressHint: "请输入地址",
    inputPubKeyHint: "请输入公钥",
    inputPriKeyHint: "请输入私钥",
    inputKeyStoreHint: "请输入KeyStore",
    inputMnemonicHint: "请输入助记词",
    sortByTimeDesc: "按照时间降序",
    sortByTimeAsc: "按照时间升序",
    sortByAlphaDesc: "按照字母降序",
    sortByAlphaAsc: "按照字母升序",
    sendExistPassword: "发送现有密码",
    sendNewPassword: "发送新密码",
    validityPeriod: "有效期",
    minute: "分钟",
    hour: "小时",
    day: "天",
    tempPassword: "临时加密密码",
    inputTempPassHint: "请输入临时密码",
    messageHintWord: "提示语",
    inputMessageHintWordHint: "请输入提示语",
    goBack: "上一步",
    goNext: "下一步",
    pack: "打包",
    packing: "打包中",
    packResult: "消息已经打包完成，请点击按钮复制链接，然后发送给对方。",
    contentType: "内容类型",
    unlock: "解锁",
    passwordWrong: "密码错误",
    unknownError: "未知错误",
    messageNotFound: "消息未找到或者已过期",
    save: "保存",
    currAccount: "当前帐号",
    switchAccount: "切换帐号",
    saveSuccess: "保存成功",
    userTotal: "用户总数",
    userYesterday: "昨日新增用户数",
    contentYesterday: "昨日新增和修改密码数",
    contentTotal: "密码总数",
    accountExpired: "账户已过期，请支付",
    easypassPromise: "EasyPass承诺",
    promiseItem1: "1、所有数据都是用主密码和AES算法加密后传输和保存。",
    promiseItem2: "2、EasyPass不会在任何情况下传输主密码。",
    promiseItem3: "3、没有主密码的情况下，无人能解密用户数据。",
    promiseItem4:
      "4、EasyPass App会本地保存一份加密的数据，因此即使在无网络情况下用户也能获取自己的数据。",
    userNotice: "用户须知",
    userNoticeItem1: "1、用户必须妥善保管自己的账户和主密码。",
    userNoticeItem2: "2、用户不能泄露自己的账户和主密码。",
    agree: "同意",
    goPay: "去支付",
    loading: "加载中",
    easypassBinancePayTitle: "EasyPass币安支付二维码",
    easypassBinancePrice: "2.9 USDT / 永久",
    easypassBinancePayHint: "请用币安扫描二维码支付",
    easypassWechatPayTitle: "EasyPass微信支付二维码",
    easypassWechatPrice: "19.9元 / 永久",
    easypassWechatPayHint: "请用微信扫描二维码支付",
    easypassBody: "EasyPass支付",
    payed: "支付完成",
    userInfo: "用户信息",
    expiryTime: "过期时间",
    contactUs: "联系我们",
    email: "Email",
    wechat: "微信",
    help: "帮助",
    feedback: "反馈",
    feedbackContent: "反馈内容",
    inputFeedbackContentHint: "请输入反馈内容",
    thankFeedback: "感谢您的反馈",
    changeMasterPassword: "修改主密码",
    currMasterPassword: "当前主密码",
    inputCurrMasterPasswordHint: "请输入当前主密码",
    newMasterPassword: "新主密码",
    inputNewMasterPasswordHint: "请输入新主密码",
    inputNewMasterPasswordAgainHint: "请再次输入新主密码",
    newMasterPasswordNotMatch: "新主密码不一致",
    masterPasswordSame: "当前主密码和新密码一样",
    scratchPayBody: "草稿本支付",
    easynotePayBody: "随手写EasyNote支付",
    pageNotFound: "页面未找到",
    hasPaidTitle: "您已经支付，感谢！",
    contentMaxLimit: "内容长度超出限制",
    autocomplete: "自动填充",
    extension: "浏览器插件",
  },
});

strings.setLanguage(lang);

const langDescMap = {
  en: "English",
  zh: "中文",
};

export default {
  strings: strings,
  language: {
    lang: lang,
    langDesc: langDescMap[lang],
  },
};
