import React, {useState, useEffect} from 'react';
import './MainTab.css'
import ColorUtils from '../utils/colors'

function MainTab(props) {
  return (
    <div className={`easypass-maintab ${props.active?'easypass-maintab-active':''}`} style={{
      fontSize: '1rem',
      height: `calc(100% - 1px)`,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      cursor: 'pointer',
      padding: '0rem 1rem 0px',
      color: props.active?ColorUtils.purple:ColorUtils.textColorGrey,
      borderBottom: `2px solid ${props.active?ColorUtils.purple:ColorUtils.transparent}`
    }} onClick={props.onClick}>
      <i className={`iconfont ${props.icon}`} style={{
        marginRight: '0.5rem',
        fontSize: '1rem',
        display: 'block',
      }}></i>
      <div style={{
        display: 'block',
      }}>
        {props.text}
      </div>
    </div>
  )
}

export default MainTab