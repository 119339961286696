import React, {useState, useEffect} from 'react';
import logo256 from '../assets/logo_256.png'
import ColorUtils from '../utils/colors'
import localization from '../utils/localization'
import MainTab from '../components/MainTab'
import { useNavigate, Outlet, useLocation } from "react-router-dom";
import { Menu, FormControl, InputLabel, Select, OutlinedInput, MenuItem, Divider, Dialog, DialogTitle, DialogContent,
  Button, CircularProgress, ButtonBase } from '@mui/material';
import './Main.css'
import ServerUtils from '../utils/server'
import UserUtils from '../utils/user'
import toast from 'react-hot-toast'
import MasterPassUtils from '../utils/masterpass'
import FormInput from '../components/FormInput'
import AesUtils from '../utils/aes'
import ContentUtils from '../utils/content'
import ConstUtils from '../utils/const'
import ModalUserInfo from '../components/ModalUserInfo'
import ModalHelp from '../components/ModalHelp'
import ModalFeedback from '../components/ModalFeedback'
import ModalChangeMasterPassword from '../components/ModalChangeMasterPassword'
import ModalDashboard from '../components/ModalDashboard'
import binanceLogo from '../assets/binance.png'
import wechatLogo from '../assets/social-wechat.png'

function Main() {
  const navigate = useNavigate();
  let location = useLocation();
  const [admin, setAdmin] = useState(false)
  const [userInfoDialogOpen, setUserInfoDialogOpen] = useState(false)
  const [helpDialogOpen, setHelpDialogOpen] = useState(false)
  const [feedbackDialogOpen, setFeedbackDialogOpen] = useState(false)
  const [changeMasterPasswordDialogOpen, setChangeMasterPasswordDialogOpen] = useState(false)
  const [dashboardDialogOpen, setDashboardDialogOpen] = useState(false)

  const [masterPassOpen, setMasterPassOpen] = useState(false)
  const [masterPassword, setMasterPassword] = useState('')
  const [masterPasswordErr, setMasterPasswordErr] = useState('')

  // user info
  const [userInfo, setUserInfo] = useState({})
  useEffect(() => {
    ServerUtils.userInfo().then(async (resp) => {
      if (!resp.ok) {
        navigate('/')
        return
      }
      let respJson = await resp.json()
      if (respJson.errno !== 0) {
        toast.error(ServerUtils.handleError(respJson))
        return
      }
      let userInfo = respJson.data
      UserUtils.setUserInfo(userInfo)
      setUserInfo(userInfo)
      let masterPass = MasterPassUtils.getMasterPass(userInfo.account)
      if (masterPass) {
        UserUtils.setMasterPass(masterPass)
        ContentUtils.getContent()
        navigate(`/main.html/content?lang=${localization.language.lang}`)
      } else {
        setMasterPassOpen(true)
      }
    })

    ServerUtils.admin().then(async (resp) => {
      if (!resp.ok) {
        return
      }
      let respJson = await resp.json()
      if (respJson.errno !== 0) {
        return
      }
      setAdmin(true)
    })
  }, [])

  function logout() {
    ServerUtils.logout().then(async (resp) => {
      navigate(`/login.html?lang=${localization.language.lang}`)
    })
  }

  function checkMasterPass() {
    if (masterPassword === '') {
      setMasterPasswordErr(localization.strings.required)
      return
    }
    let decryptedAccount = AesUtils.decryptData(userInfo.account, masterPassword, userInfo.signed_account)
    if (decryptedAccount !== userInfo.account) {
      setMasterPasswordErr(localization.strings.errorMasterPassword)
      return
    }
    setMasterPassOpen(false)
    UserUtils.setMasterPass(masterPassword)
    ContentUtils.getContent()
    navigate(`/main.html/content?lang=${localization.language.lang}`)
  }

  // menu
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  // payjs
  const [payStep, setPayStep] = useState(0)
  const [payUrl, setPayUrl] = useState(false)
  const [payTitle, setPayTitle] = useState('')
  const [payPrice, setPayPrice] = useState('')
  const [payHint, setPayHint] = useState('')
  function loadBinancePayUrl() {
    if (payStep > 1) return
    setPayStep(2)
    setPayUrl('')
    let userInfo = UserUtils.getUserInfo()
    ServerUtils.postBinancePay({
      platform_type: '',
      app_id: ConstUtils.appID,
      account: userInfo.account,
    }).then(async (resp) => {
      if (!resp.ok) {
        try {
          let respJson = await resp.json()
          if (respJson.errno !== 0) {
            toast.error(ServerUtils.handleError(respJson))
            return
          }
        } catch(e) {

        }
        toast.error(localization.strings.networkErr)
        return
      }
      let respJson = await resp.json()
      if (respJson.errno !== 0) {
        toast.error(ServerUtils.handleError(respJson))
        return
      }
      setPayTitle(localization.strings.easypassBinancePayTitle)
      setPayPrice(localization.strings.easypassBinancePrice)
      setPayHint(localization.strings.easypassBinancePrice)
      setPayUrl(respJson.data.qrcodeLink)
      setPayStep(3)
    })
  }
  function loadWechatPayUrl() {
    if (payStep > 1) return
    setPayStep(2)
    setPayUrl('')
    let userInfo = UserUtils.getUserInfo()
    ServerUtils.postPayJs({
      payjs_type: '',
      app_id: ConstUtils.appID,
      account: userInfo.account,
      body: localization.strings.easypassBody,
    }).then(async (resp) => {
      if (!resp.ok) {
        try {
          let respJson = await resp.json()
          if (respJson.errno !== 0) {
            toast.error(ServerUtils.handleError(respJson))
            return
          }
        } catch(e) {

        }
        toast.error(localization.strings.networkErr)
        return
      }
      let respJson = await resp.json()
      if (respJson.errno !== 0) {
        toast.error(ServerUtils.handleError(respJson))
        return
      }
      setPayTitle(localization.strings.easypassWechatPayTitle)
      setPayPrice(localization.strings.easypassWechatPrice)
      setPayHint(localization.strings.easypassWechatPrice)
      setPayUrl(respJson.data.qrcode)
      setPayStep(3)
    })
  }

  return (
    <div style={{
      height: '100%',
      backgroundColor: ColorUtils.white,
      display: 'flex',
      flexDirection: 'column',
    }}>
      <div style={{
        height: '4.5rem',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        borderBottom: `1px solid ${ColorUtils.divider}`,
      }}>
        <a href="/" style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          textDecoration: 'none',
        }}>
          <img src={logo256} alt="EasyPass Logo" className="brand-image img-circle elevation-3" style={{
            display: 'block',
            height: '3rem',
            width: '3rem',
            marginLeft: '0.5rem',
          }}/>
          <div style={{
            fontSize: '1.2rem',
            fontWeight: 'bold',
            color: ColorUtils.textColor,
            margin: '0rem 3rem 0rem 0.6rem',
            textAlign: 'left',
          }}>EasyPass</div>
        </a>
        <div style={{width: '5rem'}}></div>
        <div style={{
          height: '100%',
          display: 'flex',
          flexDirection: 'row',
        }}>
          <MainTab icon="icon-all" text={localization.strings.allContent} onClick={() => {
            navigate(`/main.html/content?lang=${localization.language.lang}`)
          }} active={location.pathname=='/main.html/content'}/>
          <MainTab icon="icon-envelope" text={localization.strings.encryptedMessage} onClick={() => {
            navigate(`/main.html/message?lang=${localization.language.lang}`)
          }} active={location.pathname=='/main.html/message'}/>
        </div>
        <div style={{flex: 1}}></div>
        <FormControl size="small" sx={{minWidth: 120 }}>
          <InputLabel id="demo-dialog-select-label" style={{
            color: ColorUtils.textColor,
          }}>{localization.strings.language}</InputLabel>
          <Select
            labelId="demo-dialog-select-label"
            id="demo-dialog-select"
            defaultValue={localization.language.lang}
            input={<OutlinedInput label={localization.language.lang}/>}
            onChange={(event)=>{
              ServerUtils.updateLanguage({
                language: event.target.value,
              }).then(async (resp) => {
                if (!resp.ok) {
                  try {
                    let respJson = await resp.json()
                    if (respJson.errno !== 0) {
                      toast.error(ServerUtils.handleError(respJson))
                      return
                    }
                  } catch(e) {
      
                  }
                  toast.error(localization.strings.networkErr)
                  return
                }
                let respJson = await resp.json()
                if (respJson.errno !== 0) {
                  toast.error(ServerUtils.handleError(respJson))
                  return
                }
                window.location.href = '?lang='+event.target.value
              })
            }}
            size='small'
            style={{
              color: ColorUtils.textColor,
            }}>
            <MenuItem value="en">English</MenuItem>
            <MenuItem value="zh">中文</MenuItem>
          </Select>
        </FormControl>
        <div id="easypass-avatar" className="easypass-avatar" style={{
          height: '3rem',
          margin: '0rem 1rem 0rem 2rem',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          padding: '0rem 1rem',
          borderRadius: '3rem',
          cursor: 'pointer',
        }} onClick={(event) => {
          setAnchorEl(event.currentTarget);
        }}>
          <div style={{
            marginRight: '0.5rem',
            fontSize: '1.1rem',
          }}>{userInfo.account}</div>
          <i className="iconfont icon-setting" style={{
            fontSize: '1.5rem',
            display: 'block',
          }}></i>
        </div>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={() => {
            setAnchorEl(null);
          }}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}>
          <MenuItem onClick={() => {
            setPayStep(1);
            setAnchorEl(null);
          }} style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}>
            <i className="iconfont icon-payment" style={{
              fontSize: '1.2rem',
              display: 'inline-block',
              marginRight: '0.5rem',
            }}></i>
            <div>{localization.strings.goPay}</div>
          </MenuItem>
          <MenuItem onClick={() => {
            setUserInfoDialogOpen(true)
            setAnchorEl(null);
          }} style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}>
            <i className="iconfont icon-person" style={{
              fontSize: '1.2rem',
              display: 'inline-block',
              marginRight: '0.5rem',
            }}></i>
            <div>{localization.strings.userInfo}</div>
          </MenuItem>
          <MenuItem onClick={() => {
            setChangeMasterPasswordDialogOpen(true)
            setAnchorEl(null);
          }} style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}>
            <i className="iconfont icon-lock" style={{
              fontSize: '1.2rem',
              display: 'inline-block',
              marginRight: '0.5rem',
            }}></i>
            <div>{localization.strings.changeMasterPassword}</div>
          </MenuItem>
          <MenuItem onClick={() => {
            setHelpDialogOpen(true)
            setAnchorEl(null);
          }} style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}>
            <i className="iconfont icon-help" style={{
              fontSize: '1.2rem',
              display: 'inline-block',
              marginRight: '0.5rem',
            }}></i>
            <div>{localization.strings.help}</div>
          </MenuItem>
          <MenuItem onClick={() => {
            setFeedbackDialogOpen(true)
            setAnchorEl(null);
          }} style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}>
            <i className="iconfont icon-chat" style={{
              fontSize: '1.2rem',
              display: 'inline-block',
              marginRight: '0.5rem',
            }}></i>
            <div>{localization.strings.feedback}</div>
          </MenuItem>
          <MenuItem onClick={() => {
            logout()
            setAnchorEl(null);
          }} style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}>
            <i className="iconfont icon-logout" style={{
              fontSize: '1.2rem',
              display: 'inline-block',
              marginRight: '0.5rem',
            }}></i>
            <div>{localization.strings.logout}</div>
          </MenuItem>
          {
            admin && (
              <MenuItem onClick={() => {
                setDashboardDialogOpen(true)
                setAnchorEl(null);
              }}>
                <i className="iconfont icon-dashboard" style={{
                  fontSize: '1.2rem',
                  display: 'inline-block',
                  marginRight: '0.5rem',
                }}></i>
                Dashboard
              </MenuItem>
            )
          }
        </Menu>
      </div>
      <div style={{
        flex: 1,
        overflowY: 'hidden',
      }}><Outlet/></div>

      { /* Master Password Dialog */}
      <Dialog open={masterPassOpen} onClose={(event) => {}}>
        <DialogTitle>{localization.strings.masterPassword}</DialogTitle>
        <DialogContent>
          <div style={{
            margin: '1rem 0rem 1rem',
          }}>
            <form autoComplete="on" action="javascript:void(0);">
              <FormInput type="password" start_icon="icon-lock" label={localization.strings.masterPassword}
                placeholder={localization.strings.inputMasterPasswordHint} error={masterPasswordErr} onChange={(event) => {
                  setMasterPassword(event.target.value)
                  setMasterPasswordErr('')
                }} onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    checkMasterPass()
                    return
                  }
                }}/>
            </form>
          </div>
          <div style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}>
            <Button variant="outlined" size="small" color="warning" onClick={logout}>{localization.strings.logout}</Button>
            <div style={{flex:1}}></div>
            <Button variant="outlined" size="small" color="primary" onClick={checkMasterPass}>{localization.strings.confirm}</Button>
          </div>
        </DialogContent>
      </Dialog>

      { /* Pay Dialog */}
      <Dialog open={payStep > 0} onClose={(event) => {
        setPayStep(0)
      }}>
        <DialogContent style={{
          minWidth: '300px',
          overflowY: 'visible',
        }}>
          {
            payStep==1 && (
              <div style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
              }}>
                <ButtonBase centerRipple={true} onClick={() => {
                  loadBinancePayUrl()
                }}>
                  <div>
                    <img src={binanceLogo} width="100px" height="100px"/>
                    <div style={{
                      marginTop: '0.8rem',
                      fontSize: '1.2rem',
                    }}>Binance Pay</div>
                  </div>
                </ButtonBase>
                <div style={{
                  width: '3rem',
                }}></div>
                <ButtonBase centerRipple={true} onClick={() => {
                  loadWechatPayUrl()
                }}>
                  <div>
                    <img src={wechatLogo} width="100px" height="100px"/>
                    <div style={{
                      marginTop: '0.8rem',
                      fontSize: '1.2rem',
                    }}>Wechat Pay</div>
                  </div>
                </ButtonBase>
              </div>
            )
          }
          {
            payStep==2 && (
              <div style={{
                width: '100%',
              }}>
                <CircularProgress size="5rem" style={{
                  display: 'block',
                  margin: '0rem auto',
                }}/>
                <div style={{
                  textAlign: 'center',
                  color: ColorUtils.textColor,
                  marginTop: '1rem',
                  fontSize: '1.2rem',
                }}>{localization.strings.loading}</div>
              </div>
            )
          }
          {
            payStep==3 && (
              <div style={{
                width: '100%',
              }}>
                <div style={{
                  fontSize: '1.4rem',
                  color: ColorUtils.textColor,
                }}>
                  {payTitle}
                  <span style={{
                    color: ColorUtils.darkYellow,
                    marginLeft: '0.5rem',
                  }}>{payPrice}</span>
                </div>
                <div style={{
                  margin: '0.5rem 0rem',
                  fontSize: '1rem',
                  color: ColorUtils.textColor,
                }}>{payHint}</div>
                <img src={payUrl} style={{
                  display: 'block',
                  width: '100%',
                }}/>
                <Button variant="outlined" fullWidth color="primary" onClick={() => {
                  setPayStep(0)

                  // refresh user info
                  ServerUtils.userInfo().then(async (resp) => {
                    if (!resp.ok) {
                      navigate('/')
                      return
                    }
                    let respJson = await resp.json()
                    if (respJson.errno !== 0) {
                      toast.error(ServerUtils.handleError(respJson))
                      return
                    }
                    let userInfo = respJson.data
                    UserUtils.setUserInfo(userInfo)
                    setUserInfo(userInfo)
                  })
                }} style={{
                  marginTop: '0.5rem',
                }}>{localization.strings.payed}</Button>
              </div>
            )
          }
        </DialogContent>
      </Dialog>

      <ModalUserInfo open={userInfoDialogOpen} onClose={() => {
        setUserInfoDialogOpen(false)
      }}/>
      <ModalHelp open={helpDialogOpen} onClose={() => {
        setHelpDialogOpen(false)
      }}/>
      <ModalFeedback open={feedbackDialogOpen} onClose={() => {
        setFeedbackDialogOpen(false)
      }}/>
      <ModalChangeMasterPassword open={changeMasterPasswordDialogOpen} onClose={() => {
        setChangeMasterPasswordDialogOpen(false)
      }} logout={logout}/>
      <ModalDashboard open={dashboardDialogOpen} onClose={() => {
        setDashboardDialogOpen(false)
      }}/>
    </div>
  )
}

export default Main;