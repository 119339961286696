import React, {useState, useEffect} from 'react'
import { Dialog, DialogTitle, DialogContent, Table, TableBody, TableRow, TableCell } from '@mui/material'
import localization from '../utils/localization'
import TextLine from './TextLine'

function ModalWallet(props) {

  useEffect(() => {
    
  }, [])

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogTitle>{localization.strings.walletDetail}</DialogTitle>
      <DialogContent>
        <table>
          <tbody>
            <tr>
              <td style={{
                width: '1rem',
                height: '2rem',
                whiteSpace: 'nowrap',
              }}>{localization.strings.title}</td>
              <td style={{
                textAlign: 'left',
                paddingLeft: '2rem',
              }}><pre style={{
                fontSize: '1rem',
                margin: '0rem',
                width: '20rem',
              }}>{props.contentInfo.title}</pre></td>
            </tr>
            <tr>
              <td style={{
                width: '1rem',
                height: '2rem',
                paddingRight: '1rem',
                whiteSpace: 'nowrap',
              }}>{localization.strings.coinName}</td>
              <td style={{
                textAlign: 'left',
                paddingLeft: '2rem',
              }}><pre style={{
                fontSize: '1rem',
                margin: '0rem',
              }}>{props.contentInfo.detail.coinName}</pre></td>
            </tr>
            <tr>
              <td style={{
                width: '1rem',
                height: '2rem',
                paddingRight: '1rem',
                whiteSpace: 'nowrap',
              }}>{localization.strings.address}</td>
              <td style={{
                textAlign: 'left',
                paddingLeft: '2rem',
              }}><TextLine text={props.contentInfo.detail.address}/></td>
            </tr>
            <tr>
              <td style={{
                width: '1rem',
                height: '2rem',
                paddingRight: '1rem',
                whiteSpace: 'nowrap',
              }}>{localization.strings.pubkey}</td>
              <td style={{
                textAlign: 'left',
                paddingLeft: '2rem',
              }}><TextLine text={props.contentInfo.detail.pubKey}/></td>
            </tr>
            <tr>
              <td style={{
                width: '1rem',
                height: '2rem',
                paddingRight: '1rem',
                whiteSpace: 'nowrap',
              }}>{localization.strings.privKey}</td>
              <td style={{
                textAlign: 'left',
                paddingLeft: '2rem',
              }}><TextLine text={props.contentInfo.detail.priKey}/></td>
            </tr>
            <tr>
              <td style={{
                width: '1rem',
                height: '2rem',
                paddingRight: '1rem',
                whiteSpace: 'nowrap',
              }}>{localization.strings.keyStore}</td>
              <td style={{
                textAlign: 'left',
                paddingLeft: '2rem',
              }}><TextLine text={props.contentInfo.detail.keyStore}/></td>
            </tr>
            <tr>
              <td style={{
                width: '1rem',
                height: '2rem',
                paddingRight: '1rem',
                whiteSpace: 'nowrap',
              }}>{localization.strings.mnemonic}</td>
              <td style={{
                textAlign: 'left',
                paddingLeft: '2rem',
              }}><TextLine text={props.contentInfo.detail.hintWord}/></td>
            </tr>
          </tbody>
        </table>
      </DialogContent>
    </Dialog>
  )
}

export default ModalWallet