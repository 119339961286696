import React from 'react';
import ColorUtils from '../utils/colors'
import localization from '../utils/localization';
import { Paper } from '@mui/material';
import moment from 'moment'

export function IndexCard(props) {
  return (
    <Paper elevation={5} style={{
      backgroundColor: ColorUtils.white,
      width: '17rem',
      margin: '0rem auto',
    }}>
      <div style={{
        padding: '1.5rem 1.5rem 0.5rem',
        fontSize: '1.4rem',
        color: ColorUtils.textColor,
      }}>{localization.strings.encryptedData}</div>
      <div style={{
        padding: '0rem 1.5rem 0.75rem',
        wordWrap: 'break-word',
        color: ColorUtils.textColor,
        lineHeight: 1.5,
      }}>{props.pwd.encrypted_data}</div>
      <div style={{
        padding: '0.75rem 1.5rem',
        backgroundColor: 'rgba(0,0,0,.03)',
        borderTop: '1px solid rgba(0,0,0,.05)',
        fontSize: '0.8rem',
      }}>{localization.strings.passwordCreateTime}: {moment(props.pwd.last_modify_time*1000).format('YYYY-MM-DD HH:mm:ss')}</div>
    </Paper>
  )
}

export default IndexCard;