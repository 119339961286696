import React, {useState, useEffect, forwardRef, useImperativeHandle} from 'react'
import { Divider } from '@mui/material'
import FormInput from './FormInput'
import localization from '../utils/localization'
import ExtraForm from './ExtraForm'
import ContentUtils from '../utils/content'

const TextForm = forwardRef((props, ref) => {
  const [title, setTitle] = useState(props.contentInfo.id ? props.contentInfo.title : '')
  const [titleErr, setTitleErr] = useState('')
  const [text, setText] = useState(props.contentInfo.id ? props.contentInfo.content : '')
  const [textErr, setTextErr] = useState('')
  const [extra, setExtra] = useState(props.contentInfo.id ? props.contentInfo.extra : {})

  useEffect(() => {
    if (props.contentInfo.id) {
      setTitle(props.contentInfo.title)
      setTitleErr('')
      setText(props.contentInfo.content)
      setTextErr('')
      setExtra(props.contentInfo.extra)
    }
  }, [props.contentInfo])

  useImperativeHandle(ref, () => ({
    checkForm() {
      let hasErr = false
      if (title === '') {
        setTitleErr(localization.strings.required)
        hasErr = true
      }
      if (text === '') {
        setTextErr(localization.strings.required)
        hasErr = true
      }
      return hasErr
    },
    getContentData() {
      return {
        title: title,
        content: text,
        type: ContentUtils.getTextType(),
        color: 'blue',
        account: '',
        extra: extra,
      }
    },
  }))

  return (
    <div>
      <div style={{
        margin: '0rem 0rem 0rem',
      }}>
        <FormInput label={localization.strings.title} value={title}
          placeholder={localization.strings.inputTitleHint} error={titleErr} onChange={(event) => {
            setTitle(event.target.value)
            setTitleErr('')
          }}/>
      </div>
      <div style={{
        margin: '1rem 0rem 0rem',
      }}>
        <FormInput multiline={true} label={localization.strings.text} value={text} error={textErr}
          placeholder={localization.strings.inputTextHint} onChange={(event) => {
            setText(event.target.value)
            setTextErr('')
          }}/>
      </div>
      <Divider style={{
        margin: '1rem 0rem',
      }}/>
      <ExtraForm extra={extra} onChange={(e) => {
        setExtra(e)
      }}/>
    </div>
  )
})

export default TextForm