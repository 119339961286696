import React, {useState, useEffect} from 'react';
import { Button, FormControl, Select, MenuItem, InputLabel, OutlinedInput, CircularProgress, Dialog, DialogTitle, DialogContent, Grid } from '@mui/material';
import localization from '../utils/localization'
import ToolsUtils from '../utils/tools'
import ServerUtils from '../utils/server'
import toast from 'react-hot-toast'
import ColorUtils from '../utils/colors'
import logo512 from '../assets/logo_512.png'
import FormInput from '../components/FormInput'
import AesUtils from '../utils/aes'
import ContentUtils from '../utils/content'
import ContentIcon from '../components/ContentIcon'
import TextLine from '../components/TextLine'
import TotpUtils from '../utils/totp'
import {BorderLinearProgress} from '../components/ModalTotp'
import copy from 'copy-to-clipboard'
import UserUtils from '../utils/user'

let globalTotpSecret = ''

function Message() {
  const [exists, setExists] = useState(false)
  const [contentKey, setContentKey] = useState(ToolsUtils.getParameterByName('key'))
  const [messageData, setMessageData] = useState({})
  const [unlock, setUnLock] = useState(false)
  const [tempPassword, setTempPassword] = useState('')
  const [tempPasswordErr, setTempPasswordErr] = useState('')
  const [contentInfo, setContentInfo] = useState({})
  const [walletInfo, setWalletInfo] = useState({})
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (contentInfo.content) {
      globalTotpSecret = contentInfo.content
    }
  }, [contentInfo])

  useEffect(() => {
    setLoading(true)
    ServerUtils.getMessage(contentKey).then(async (resp) => {
      setLoading(false)
      if (!resp.ok) {
        return
      }
      let respJson = await resp.json()
      if (respJson.errno !== 0) {
        return
      }
      setMessageData(respJson.data)
      setExists(true)
    })
  }, [])

  const [totpToken, setTotpToken] = useState(0)
  const [totpNumbers, setTotpNumbers] = useState([])
  const [progress, setProgress] = useState(0)
  function calcTotpToken() {
    let totpToken = 0
    try {
      totpToken = TotpUtils.generate(globalTotpSecret)
    } catch(e) {
      
    }
    setTotpToken(totpToken)
    let modalTotpTime = new Date().getTime()
    setProgress((modalTotpTime/1000%30)*100/30)
    setTotpNumbers([
      parseInt(totpToken / 100000),
      parseInt(totpToken % 100000 / 10000),
      parseInt(totpToken % 10000 / 1000),
      parseInt(totpToken % 1000 / 100),
      parseInt(totpToken % 100 / 10),
      parseInt(totpToken % 10),
    ])
  }
  useEffect(() => {
    calcTotpToken()
    let tickerID = setInterval(calcTotpToken, 1000)
    return () => {
      clearInterval(tickerID)
    }
  }, [])

  // user info
  const [userInfo, setUserInfo] = useState({})
  useEffect(() => {
    ServerUtils.userInfo().then(async (resp) => {
      if (!resp.ok) {
        return
      }
      let respJson = await resp.json()
      if (respJson.errno !== 0) {
        return
      }
      let userInfo = respJson.data
      UserUtils.setUserInfo(userInfo)
      setUserInfo(userInfo)
    })
  }, [])

  // save
  const [saveDialogOpen, setSaveDialogOpen] = useState(false)
  const [masterPassword, setMasterPassword] = useState('')
  const [masterPasswordErr, setMasterPasswordErr] = useState('')
  function saveContent() {
    if (masterPassword === '') {
      setMasterPasswordErr(localization.strings.required)
      return
    }
    let decryptedAccount = AesUtils.decryptData(userInfo.account, masterPassword, userInfo.signed_account)
    if (decryptedAccount !== userInfo.account) {
      setMasterPasswordErr(localization.strings.errorMasterPassword)
      return
    }
    ServerUtils.createContent({
      version: userInfo.version,
      encrypted_data: AesUtils.encryptData(userInfo.account, masterPassword, JSON.stringify({
        title: contentInfo.title,
        content: contentInfo.content,
        type: contentInfo.type,
        color: contentInfo.color,
        account: contentInfo.account,
        extra: contentInfo.extra,
      })),
    }).then(async (resp) => {
      if (!resp.ok) {
        try {
          let respJson = await resp.json()
          if (respJson.errno !== 0) {
            toast.error(ServerUtils.handleError(respJson))
            return
          }
        } catch(e) {

        }
        toast.error(localization.strings.networkErr)
        return
      }
      let respJson = await resp.json()
      if (respJson.errno !== 0) {
        toast.error(ServerUtils.handleError(respJson))
        return
      }
      toast.success(localization.strings.saveSuccess)
      setSaveDialogOpen(false)
    })
  }

  // login and register
  const [loginDialogOpen, setLoginDialogOpen] = useState(false)
  const [mode, setMode] = useState('login')
  const [captcha, setCaptcha] = useState('')

  function refreshCaptcha() {
    ServerUtils.newCaptcha().then(async (resp) => {
      if (!resp.ok) {
        toast.error(localization.strings.networkErr)
        return
      }
      let respJson = await resp.json()
      if (respJson.errno !== 0) {
        toast.error(ServerUtils.handleError(respJson))
        return
      }
      setCaptcha(respJson.data.captcha_id)
    })
  }

  // login params
  const [loginAccount, setLoginAccount] = useState('')
  const [loginAccountErr, setLoginAccountErr] = useState('')
  const [loginMasterPassword, setLoginMasterPassword] = useState('')
  const [loginMasterPasswordErr, setLoginMasterPasswordErr] = useState('')
  function login() {
    let hasErr = false;
    if (loginAccount === '') {
      setLoginAccountErr(localization.strings.required)
      hasErr = true;
    }
    if (loginMasterPassword === '') {
      setLoginMasterPasswordErr(localization.strings.required)
      hasErr = true;
    }
    if (hasErr) return
    let currTime = parseInt(new Date().getTime() / 1000)
    let signedAccount = AesUtils.encryptData(loginAccount, loginMasterPassword, loginAccount)
    let text = loginAccount + '-' + signedAccount + '-' + currTime
    ServerUtils.login({
      account: loginAccount,
      time: currTime,
      token: AesUtils.sha256(text),
    }).then(async (resp) => {
      if (!resp.ok) {
        try {
          let respJson = await resp.json()
          if (respJson.errno !== 0) {
            toast.error(ServerUtils.handleError(respJson))
            return
          }
        } catch(e) {

        }
        toast.error(localization.strings.networkErr)
        return
      }
      let respJson = await resp.json()
      if (respJson.errno !== 0) {
        toast.error(ServerUtils.handleError(respJson))
        return
      }
      ServerUtils.userInfo().then(async (resp) => {
        if (!resp.ok) {
          return
        }
        let respJson = await resp.json()
        if (respJson.errno !== 0) {
          return
        }
        let userInfo = respJson.data
        UserUtils.setUserInfo(userInfo)
        setUserInfo(userInfo)
        setLoginDialogOpen(false)
        setSaveDialogOpen(true)
      })
    })
  }

  // register params
  const [registerAccount, setRegisterAccount] = useState('')
  const [registerAccountErr, setRegisterAccountErr] = useState('')
  const [registerMasterPassword, setRegisterMasterPassword] = useState('')
  const [registerMasterPasswordErr, setRegisterMasterPasswordErr] = useState('')
  const [registerMasterRePassword, setRegisterMasterRePassword] = useState('')
  const [registerMasterRePasswordErr, setRegisterMasterRePasswordErr] = useState('')
  const [registerCaptcha, setRegisterCaptcha] = useState('')
  const [registerCaptchaErr, setRegisterCaptchaErr] = useState('')
  const [registerVisitCode, setRegisterVisitCode] = useState('')
  let isRegister = false;
  function register() {
    if (isRegister) return
    isRegister = true
    let hasErr = false;
    if (registerAccount === '') {
      setRegisterAccountErr(localization.strings.required)
      hasErr = true;
    }
    if (registerMasterPassword === '') {
      setRegisterMasterPasswordErr(localization.strings.required)
      hasErr = true;
    }
    if (registerMasterRePassword === '') {
      setRegisterMasterRePasswordErr(localization.strings.required)
      hasErr = true;
    } else if (registerMasterPassword !== registerMasterRePassword) {
      setRegisterMasterRePasswordErr(localization.strings.masterPasswordNotMatch)
      hasErr = true;
    }
    if (registerCaptcha === '') {
      setRegisterCaptchaErr(localization.strings.required)
      hasErr = true;
    }
    if (hasErr) {
      isRegister = false
      return
    }
    let signedAccount = AesUtils.encryptData(registerAccount, registerMasterPassword, registerAccount)
    ServerUtils.register({
      account: registerAccount,
      signed_account: signedAccount,
      be_invited_code: registerVisitCode,
      language: localization.language.lang,
      captcha_id: captcha,
      captcha_solution: registerCaptcha,
    }).then(async (resp) => {
      isRegister = false
      if (!resp.ok) {
        try {
          let respJson = await resp.json()
          if (respJson.errno !== 0) {
            if (respJson.errno === 4000030) refreshCaptcha()
            toast.error(ServerUtils.handleError(respJson))
            return
          }
        } catch(e) {

        }
        toast.error(localization.strings.networkErr)
        return
      }
      toast.success(localization.strings.successRegister)
      setRegisterAccount('')
      setRegisterMasterPassword('')
      setRegisterMasterRePassword('')
      setRegisterCaptcha('')
      setRegisterVisitCode('')
      setMode('login')
    })
  }
  
  return (
    <div style={{
      backgroundColor: ColorUtils.messageBg,
      height: '100%',
    }}>
      <div style={{
        position: 'relative',
        height: '100%',
        width: '100%',
        maxWidth: '20cm',
        overflowY: 'scroll',
        overflowX: 'hidden',
        backgroundColor: ColorUtils.white,
        margin: '0px auto',
      }}>
        {
          loading && (
            <div style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}>
              <CircularProgress size="5rem" style={{
                margin: '0rem auto',
              }}/>
            </div>
          )
        }
        {
          !loading && !exists && (
            <div style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}>
              <i className="iconfont icon-envelope-open" style={{
                display: 'block',
                margin: '0rem auto',
                fontSize: '5rem',
                color: ColorUtils.purple,
              }}></i>
              <div style={{
                marginTop: '1rem',
                textAlign: 'center',
                color: ColorUtils.textColor,
                fontSize: '1.5rem',
              }}>{localization.strings.messageNotFound}</div>
            </div>
          )
        }
        {
          !unlock && !loading && exists && (
            <div>
              <FormControl size="small" sx={{minWidth: 120 }} style={{
                position: 'absolute',
                top: '1rem',
                right: '1rem',
              }}>
                <InputLabel id="demo-dialog-select-label" style={{
                  color: ColorUtils.textColor,
                }}>{localization.strings.language}</InputLabel>
                <Select
                  labelId="demo-dialog-select-label"
                  id="demo-dialog-select"
                  defaultValue={localization.language.lang}
                  input={<OutlinedInput label={localization.language.lang}/>}
                  onChange={(event)=>{
                    window.location.href = '?lang='+event.target.value
                  }}
                  size='small'
                  style={{
                    color: ColorUtils.textColor,
                  }}>
                  <MenuItem value="en">English</MenuItem>
                  <MenuItem value="zh">中文</MenuItem>
                </Select>
              </FormControl>
              <img src={logo512} style={{
                display: 'block',
                height: '12rem',
                margin: '2rem auto 0rem',
              }}/>
              <div style={{
                textAlign: 'center',
                color: ColorUtils.textColor,
                fontSize: '2rem',
              }}>EasyPass-{localization.strings.encryptedMessage}</div>
              <div style={{
                margin: '2rem auto 0rem',
                padding: '0rem 1rem',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                maxWidth: '480px',
              }}>
                <FormInput type="password" wrapContent={true} label={localization.strings.tempPassword} value={tempPassword}
                  placeholder={localization.strings.inputTempPassHint} error={tempPasswordErr} onChange={(event) => {
                    setTempPassword(event.target.value)
                    setTempPasswordErr('')
                  }} style={{
                    flex: 1,
                  }}/>
                <Button variant="outlined" color="primary" style={{
                  marginLeft: '1rem',
                }} onClick={() => {
                  if (AesUtils.sha256(tempPassword) !== messageData.sign) {
                    setTempPasswordErr(localization.strings.passwordWrong)
                    return
                  }
                  let tmpContentInfo = {}
                  try {
                    tmpContentInfo = JSON.parse(AesUtils.decryptData('', tempPassword, messageData.encrypted_data))
                    setContentInfo(tmpContentInfo)
                    if (tmpContentInfo.type === ContentUtils.getBlockChainType()) {
                      setWalletInfo(JSON.parse(tmpContentInfo.content))
                    }
                  } catch (e) {
                    toast.error(localization.strings.unknownError)
                  }
                  setUnLock(true)
                }}>
                  {localization.strings.unlock}
                </Button>
              </div>
              <div style={{
                margin: '1rem auto 0rem',
                padding: '0rem 1rem',
                maxWidth: '480px',
                color: ColorUtils.textColorGrey,
              }}>
                {localization.strings.messageHintWord}: {messageData.hint_word}
              </div>
            </div>
          )
        }
        {
          unlock && (
            <div style={{
              padding: '2rem 1rem',
            }}>
              <div style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                overflowX: 'hidden',
                overflowY: 'hidden',
              }}>
                <ContentIcon contentType={contentInfo.type} size="3rem" fontSize="2rem"/>
                <div style={{
                  marginLeft: '1rem',
                  fontSize: '1.8rem',
                  color: ColorUtils.textColor,
                  flex: 1,
                  overflowX: 'hidden',
                  textOverflow: 'ellipsis',
                }}>{contentInfo.title}</div>
                <Button variant="outlined" color="primary" style={{
                  marginLeft: '1rem',
                }} onClick={() => {
                  if (userInfo.account) {
                    setSaveDialogOpen(true)
                  } else {
                    setLoginDialogOpen(true)
                  }
                }}>
                  {localization.strings.save}
                </Button>
              </div>
              <table style={{
                margin: '2rem auto',
                fontSize: '1.2rem',
                width: '100%',
                overflowX: 'hidden',
                tableLayout: 'fixed',
              }}>
                <tbody style={{
                  width: '100%',
                  overflowX: 'hidden',
                }}>
                  <tr style={{
                    width: '100%',
                    overflowX: 'hidden',
                  }}>
                    <td style={{
                      width: '25%',
                      overflowX: 'hidden',
                      paddingRight: '0.5rem',
                      textOverflow: 'ellipsis',
                      height: '2rem',
                      whiteSpace: 'nowrap',
                    }}>{localization.strings.title}</td>
                    <td style={{
                      width: '75%',
                      textAlign: 'left',
                      overflowX: 'hidden',
                      whiteSpace: 'nowrap',
                    }}><pre style={{
                      margin: '1rem 0rem',
                      overflowX: 'hidden',
                      textOverflow: 'ellipsis',
                    }}>{contentInfo.title}</pre></td>
                  </tr>
                  {
                    contentInfo.type === ContentUtils.getPasswordType() && (
                      <>
                        <tr>
                          <td style={{
                            maxWidth: '30%',
                            overflowX: 'hidden',
                            textOverflow: 'ellipsis',
                            height: '2rem',
                            paddingRight: '0.5rem',
                            whiteSpace: 'nowrap',
                          }}>{localization.strings.account}</td>
                          <td style={{
                            maxWidth: '70%',
                            textAlign: 'left',
                          }}><TextLine text={contentInfo.account}/></td>
                        </tr>
                        <tr>
                          <td style={{
                            maxWidth: '30%',
                            overflowX: 'hidden',
                            textOverflow: 'ellipsis',
                            height: '2rem',
                            paddingRight: '0.5rem',
                            whiteSpace: 'nowrap',
                          }}>{localization.strings.password}</td>
                          <td style={{
                            maxWidth: '70%',
                            textAlign: 'left',
                          }}><TextLine text={contentInfo.content}/></td>
                        </tr>
                      </>
                    )
                  }
                  {
                    contentInfo.type === ContentUtils.getTextType() && (
                      <>
                        <tr>
                          <td style={{
                            maxWidth: '30%',
                            overflowX: 'hidden',
                            textOverflow: 'ellipsis',
                            height: '2rem',
                            paddingRight: '0.5rem',
                            whiteSpace: 'nowrap',
                          }}>{localization.strings.text}</td>
                          <td style={{
                            maxWidth: '70',
                            textAlign: 'left',
                          }}><TextLine text={contentInfo.content}/></td>
                        </tr>
                      </>
                    )
                  }
                  {
                    contentInfo.type === ContentUtils.getTotpType() && (
                      <>
                        <tr>
                          <td style={{
                            maxWidth: '30%',
                            overflowX: 'hidden',
                            textOverflow: 'ellipsis',
                            height: '2rem',
                            paddingRight: '0.5rem',
                            whiteSpace: 'nowrap',
                          }}>{localization.strings.totpSecret}</td>
                          <td style={{
                            maxWidth: '70%',
                            textAlign: 'left',
                          }}><TextLine text={contentInfo.content}/></td>
                        </tr>
                        <tr>
                          <td colspan="2" style={{
                            padding: '1rem 0rem 2rem',
                          }}>
                            <div style={{
                              margin: '1rem 0rem 0rem',
                              padding: '0rem 0rem 1rem',
                            }}>
                              <BorderLinearProgress variant="determinate" value={progress} />
                            </div>
                            <div style={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',
                              justifyContent: 'center',
                              paddingLeft: '0.5rem',
                            }}>
                              {
                                totpNumbers.map((num, index) => {
                                  return (
                                    <div key={`${index}`} style={{
                                      textAlign: 'center',
                                      width: '2rem',
                                      fontSize: '3rem',
                                      backgroundColor: ColorUtils.grey,
                                      borderRadius: '0.5rem',
                                      padding: '0.5rem',
                                      marginRight: '0.5rem',
                                      color: ColorUtils.textColor,
                                    }}>{num}</div>
                                  )
                                })
                              }
                            </div>
                            <Button variant="outlined" fullWidth color="info" onClick={() => {
                              copy(`${totpToken}`)
                              toast.success(localization.strings.copySuccess)
                            }} style={{
                              margin: '1rem 0rem 0rem',
                            }}>{localization.strings.clickCopy}</Button>
                          </td>
                        </tr>
                      </>
                    )
                  }
                  {
                    contentInfo.type === ContentUtils.getBlockChainType() && (
                      <>
                        <tr>
                          <td style={{
                            maxWidth: '30%',
                            overflowX: 'hidden',
                            textOverflow: 'ellipsis',
                            height: '2rem',
                            paddingRight: '0.5rem',
                            whiteSpace: 'nowrap',
                          }}>{localization.strings.coinName}</td>
                          <td style={{
                            maxWidth: '70%',
                            textAlign: 'left',
                          }}><pre style={{
                            margin: '1rem 0rem',
                            width: '20rem',
                          }}>{walletInfo.coinName}</pre></td>
                        </tr>
                        <tr>
                          <td style={{
                            maxWidth: '30%',
                            overflowX: 'hidden',
                            textOverflow: 'ellipsis',
                            height: '2rem',
                            paddingRight: '0.5rem',
                            whiteSpace: 'nowrap',
                          }}>{localization.strings.address}</td>
                          <td style={{
                            maxWidth: '70%',
                            textAlign: 'left',
                          }}><TextLine text={walletInfo.address}/></td>
                        </tr>
                        <tr>
                          <td style={{
                            maxWidth: '30%',
                            overflowX: 'hidden',
                            textOverflow: 'ellipsis',
                            height: '2rem',
                            paddingRight: '0.5rem',
                            whiteSpace: 'nowrap',
                          }}>{localization.strings.pubkey}</td>
                          <td style={{
                            maxWidth: '70%',
                            textAlign: 'left',
                          }}><TextLine text={walletInfo.pubKey}/></td>
                        </tr>
                        <tr>
                          <td style={{
                            maxWidth: '30%',
                            overflowX: 'hidden',
                            textOverflow: 'ellipsis',
                            height: '2rem',
                            paddingRight: '0.5rem',
                            whiteSpace: 'nowrap',
                          }}>{localization.strings.privKey}</td>
                          <td style={{
                            maxWidth: '70%',
                            textAlign: 'left',
                          }}><TextLine text={walletInfo.priKey}/></td>
                        </tr>
                        <tr>
                          <td style={{
                            maxWidth: '30%',
                            overflowX: 'hidden',
                            textOverflow: 'ellipsis',
                            height: '2rem',
                            paddingRight: '0.5rem',
                            whiteSpace: 'nowrap',
                          }}>{localization.strings.keyStore}</td>
                          <td style={{
                            maxWidth: '70%',
                            textAlign: 'left',
                          }}><TextLine text={walletInfo.keyStore}/></td>
                        </tr>
                        <tr>
                          <td style={{
                            maxWidth: '30%',
                            overflowX: 'hidden',
                            textOverflow: 'ellipsis',
                            height: '2rem',
                            paddingRight: '0.5rem',
                            whiteSpace: 'nowrap',
                          }}>{localization.strings.mnemonic}</td>
                          <td style={{
                            maxWidth: '70%',
                            textAlign: 'left',
                          }}><TextLine text={walletInfo.hintWord}/></td>
                        </tr>
                      </>
                    )
                  }
                  {
                    typeof contentInfo.extra === 'object' && Object.keys(contentInfo.extra).map((key) => {
                      return (
                        <tr key={key}>
                          <td style={{
                            maxWidth: '30%',
                            overflowX: 'hidden',
                            textOverflow: 'ellipsis',
                            height: '2rem',
                            paddingRight: '0.5rem',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                          }}>{key}</td>
                          <td style={{
                            maxWidth: '70%',
                            textAlign: 'left',
                          }}><TextLine text={contentInfo.extra[key]}/></td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </table>
            </div>
          )
        }
      </div>

      { /* Save Dialog */}
      <Dialog open={saveDialogOpen} onClose={(event) => {
        setSaveDialogOpen(false)
      }}>
        <DialogTitle>{localization.strings.save} {ContentUtils.getContentTypeName(contentInfo.type)}</DialogTitle>
        <DialogContent>
          <div style={{
            margin: '0.5rem 0rem',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}>
            <div style={{
              marginRight: '1rem',
            }}>
              {localization.strings.currAccount}: {userInfo.account}
            </div>
            <Button variant="outlined" size="small" color="warning" onClick={() => {
              setSaveDialogOpen(false)
              setLoginDialogOpen(true)
            }}>{localization.strings.switchAccount}</Button>
          </div>
          <div style={{
            margin: '1rem 0rem 1rem',
          }}>
            <FormInput type="password" start_icon="icon-lock" label={localization.strings.masterPassword}
              placeholder={localization.strings.inputMasterPasswordHint} error={masterPasswordErr} onChange={(event) => {
                setMasterPassword(event.target.value)
                setMasterPasswordErr('')
              }}/>
          </div>
          <div style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}>
            <Button fullWidth variant="outlined" size="small" color="secondary" onClick={() => {
              setSaveDialogOpen(false)
            }}>{localization.strings.cancel}</Button>
            <div style={{width: '1rem'}}></div>
            <Button fullWidth variant="outlined" size="small" color="primary" onClick={saveContent}>{localization.strings.save}</Button>
          </div>
        </DialogContent>
      </Dialog>

      { /* Login and Register Dialog */}
      <Dialog open={loginDialogOpen} onClose={(event) => {
        setLoginDialogOpen(false)
      }}>
        <DialogTitle>{mode === 'login' ? localization.strings.login : localization.strings.register} EasyPass</DialogTitle>
        <DialogContent style={{
          overflowY: 'visible',
        }}>
        {
          mode === 'login' && <div style={{
            marginTop: '1rem',
          }}>
            <div style={{
              margin: '0rem 0rem 1rem',
            }}>
              <FormInput start_icon="icon-person" label={localization.strings.account} placeholder={localization.strings.inputAccountHint}
                error={loginAccountErr} onChange={(event) => {
                  setLoginAccount(event.target.value)
                  setLoginAccountErr('')
                }}/>
            </div>
            <div style={{
              margin: '1rem 0rem 1rem',
            }}>
              <FormInput type="password" start_icon="icon-lock" label={localization.strings.masterPassword}
                placeholder={localization.strings.inputMasterPasswordHint} error={loginMasterPasswordErr} onChange={(event) => {
                  setLoginMasterPassword(event.target.value)
                  setLoginMasterPasswordErr('')
                }}/>
            </div>
            <p style={{
              color: ColorUtils.red,
            }}>{localization.strings.masterPasswordAlert}</p>
            <Button variant="contained" color="primary" fullWidth style={{
              margin: '0rem 0rem 0rem',
            }} onClick={login}>
              {localization.strings.login}
            </Button>
            <Button variant="outlined" color="info" fullWidth style={{
              margin: '1rem 0rem 0rem',
            }} onClick={() => {
              setMode('register')
              refreshCaptcha()
            }}>
              {localization.strings.goRegister}
            </Button>
          </div>
        }
        {
          mode === 'register' && <div style={{
            marginTop: '1rem',
          }}>
            <div style={{
              margin: '0rem 0rem 1rem',
            }}>
              <FormInput start_icon="icon-person" label={localization.strings.account} placeholder={localization.strings.inputAccountHint}
                error={registerAccountErr} onChange={(event) => {
                  setRegisterAccount(event.target.value)
                  setRegisterAccountErr('')
                }}/>
            </div>
            <div style={{
              margin: '1rem 0rem 1rem',
            }}>
              <FormInput type="password" start_icon="icon-lock" label={localization.strings.masterPassword}
                placeholder={localization.strings.inputMasterPasswordHint} error={registerMasterPasswordErr} onChange={(event) => {
                  setRegisterMasterPassword(event.target.value)
                  setRegisterMasterPasswordErr('')
                  setRegisterMasterRePasswordErr('')
                }}/>
            </div>
            <div style={{
              margin: '1rem 0rem 1rem',
            }}>
              <FormInput type="password" start_icon="icon-lock" label={localization.strings.masterPassword}
                placeholder={localization.strings.inputMasterPasswordHintRepeat} error={registerMasterRePasswordErr} onChange={(event) => {
                  setRegisterMasterRePassword(event.target.value)
                  setRegisterMasterRePasswordErr('')
                }}/>
            </div>
            <img src={`/openapi/captcha/${captcha}.png`} style={{
              margin: '0rem 0rem',
            }} onClick={refreshCaptcha}/>
            <div style={{
              margin: '1rem 0rem 1rem',
            }}>
              <FormInput start_icon="icon-image" label={localization.strings.captcha}
                placeholder={localization.strings.inputCaptchaHint} error={registerCaptchaErr} onChange={(event) => {
                  setRegisterCaptcha(event.target.value)
                  setRegisterCaptchaErr('')
                }}/>
            </div>
            {/* <div style={{
              margin: '1rem 0rem 1rem',
            }}>
              <FormInput start_icon="icon-friends" label={localization.strings.inviteCode}
                placeholder={localization.strings.inputInviteCodeHint} onChange={(event) => {
                  setRegisterVisitCode(event.target.value)
                }}/>
            </div> */}
            <p style={{
              color: ColorUtils.red,
            }}>{localization.strings.masterPasswordAlert}</p>
            <Button variant="contained" color="primary" fullWidth style={{
              margin: '0rem 0rem 0rem',
            }} onClick={register}>
              {localization.strings.register}
            </Button>
            <Button variant="outlined" color="info" fullWidth style={{
              margin: '1rem 0rem 0rem',
            }} onClick={() => {
              setMode('login')
            }}>
              {localization.strings.goLogin}
            </Button>
          </div>
        }
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default Message;