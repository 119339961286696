import React, {useState} from 'react';
import { FormControl, InputLabel, OutlinedInput, InputAdornment, IconButton, FormHelperText } from '@mui/material';
import ColorUtils from '../utils/colors'

export function FormInput(props) {
  const [hide, setHide] = useState(props.type === 'password' ? true : false)
  return (
    <FormControl fullWidth={!props.wrapContent} variant="outlined" error={!props.error === false} size={props.size} style={props.style}>
      <InputLabel htmlFor="component-simple">{props.label}</InputLabel>
      <OutlinedInput type={props.type === 'password' ? (hide ? 'password' : 'text') : props.type} startAdornment={
        props.start_icon && <InputAdornment position="start">
          <i className={`iconfont ${props.start_icon ? props.start_icon : ''}`} style={{
            marginRight: '0rem',
            fontSize: '1.6rem',
          }}></i>
        </InputAdornment>
      } endAdornment={
        props.type === 'password' && <InputAdornment position="start">
          <IconButton onClick={() => {
            setHide(!hide)
          }}>
            <i className={`iconfont ${hide ? 'icon-eyeslash' : 'icon-eye'}`} style={{
              fontSize: '1.5rem',
            }}></i>
          </IconButton>
        </InputAdornment>
      } label={props.label} style={{
        backgroundColor: ColorUtils.formBg,
        color: ColorUtils.textColor,
      }} defaultValue={props.defaultValue} value={props.value} placeholder={props.placeholder} onChange={(event) => {
        if (!event) return
        props.onChange(event)
      }} multiline={props.multiline} minRows={props.minRows ? props.minRows : (props.multiline ? 3 : 1)} onKeyDown={props.onKeyDown}/>
      {
        props.error && <FormHelperText id="component-error-text">{props.error}</FormHelperText>
      }
    </FormControl>
  )
}

export default FormInput;