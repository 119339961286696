let colors = {
	primaryDark: '#1E88E5',
	transparent: 'rgba(0,0,0,0)',
	blue: '#1E88E5',
	darkBlue: '#1E88E5',
	green: '#8bc34a',
	greenBg: 'rgba(139,195,74,0.2)',
	textColor: '#212B36',
	white: '#ffffff',
	grey: '#f5f5f5',
	divider: '#e0e0e0',
	button: '#304ffe',
	textColorLight: 'white',
	deepGrey: '#929292',
	textColorGrey: '#637381',
	lightGrey: '#F9FAFB',
	lightBlue: 'rgb(227, 242, 253)',
	purple: '#673AB7',
	purpleBg: 'rgba(103,58,183,0.2)',
	lightPurple: '#EDE7F6',
	formBg: '#fafafa',
	itemBg: '#F8F9FA',
	activeItemBg: '#DAE0E4',
	hoverItemBg: '#E3E9EF',
	activeItemBorder: '#D3D9DF',
  yellow: '#FFE57F',
  lightYellow: '#fff8e1',
  darkYellow: '#FFC107',
  red: '#F44336',
  lightRed: '#ffcdd2',
  darkRed: '#C62828',
  messageBg: '#f4f6f9',

	indexGreen: 'rgb(76, 175, 80)',
	indexGreenBg: 'rgba(76, 175, 80, 0.2)',
	indexBlue: 'rgb(0, 123, 255)',
	indexBlueBg: 'rgba(0, 123, 255, 0.2)',
	indexRed: 'rgb(233, 30, 99)',
	indexRedBg: 'rgba(233, 30, 99, 0.2)',
	indexOrange: 'rgb(255, 152, 0)',
	indexOrangeBg: 'rgba(255, 152, 0, 0.2)',
	indexPurple: 'rgb(63, 81, 181)',
	indexPurpleBg: 'rgba(63, 81, 181, 0.2)',

	passwordIconColor: '#6590F1',
	passwordIconColorBg: 'rgba(101,144,241,0.2)',
	textIconColor: 'rgb(68,160,71)',
	textIconColorBg: 'rgba(68,160,71,0.2)',
	totpIconColor: 'rgb(245,127,22)',
	totpIconColorBg: 'rgba(245,127,22,0.2)',
	blockchainIconColor: 'rgb(216,67,21)',
	blockchainIconColorBg: 'rgba(216,67,21,0.2)',
	tagIconColor: 'rgb(33,33,33)',
	tagIconColorBg: 'rgba(33,33,33,0.2)',
};

export default colors;