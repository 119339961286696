import React, {useState, useEffect} from 'react'
import ColorUtils from '../utils/colors'
import copy from 'copy-to-clipboard'
import toast from 'react-hot-toast'
import localization from '../utils/localization'

function TextLine(props) {
  const [hide, setHide] = useState(true)

  useEffect(() => {
    setHide(true)
  }, [props.text])

  return (
    <div style={{
      fontSize: '1rem',
      color: ColorUtils.textColor,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      overflowX: 'hidden',
    }}>
      <pre style={{
        maxWidth: '60%',
        overflowX: 'hidden',
        whiteSpace: 'pre-wrap',
        wordWrap: 'break-word',
      }}>
        {
          hide ? (
            [1,2,3,4,5,6,7,8].map((item) => {
              return (
                <i key={`${item}`} className="iconfont icon-circle" style={{
                  fontSize: '1rem',
                }}></i>
              )
            })
          ) : props.text
        }
      </pre>
      <div style={{
        backgroundColor: ColorUtils.lightYellow,
        marginLeft: '0.6rem',
        color: ColorUtils.darkYellow,
        padding: '0.5rem',
        borderRadius: '0.5rem',
        cursor: 'pointer',
        width: '1.2rem',
        height: '1.2rem',
        lineHeight: '1.2rem',
        textAlign: 'center',
      }} onClick={() => {
        setHide(!hide)
      }}>
        <i className={`iconfont ${hide ? 'icon-eyeslash' : 'icon-eye'}`} style={{
          fontSize: '1.3rem',
        }}></i>
      </div>
      <div style={{
        backgroundColor: ColorUtils.lightBlue,
        marginLeft: '0.6rem',
        color: ColorUtils.darkBlue,
        padding: '0.5rem',
        borderRadius: '0.5rem',
        cursor: 'pointer',
        width: '1.2rem',
        height: '1.2rem',
        lineHeight: '1.2rem',
        textAlign: 'center',
      }} onClick={() => {
        copy(props.text)
        toast.success(localization.strings.copySuccess)
      }}>
        <i className="iconfont icon-copy1" style={{
          fontSize: '1.1rem',
        }}></i>
      </div>
    </div>
  )
}

export default TextLine;