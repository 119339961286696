var userInfo = {};
var masterPassword = '';

export default {
  setUserInfo: function(data) {
    userInfo = data
  },
  getUserInfo: function() {
    return userInfo
  },
  setMasterPass: function(mp) {
    masterPassword = mp
  },
  getMasterPass: function() {
    return masterPassword
  },
  getExpiryTime: function() {
    return userInfo.expiry_time === undefined ? 0 : userInfo.expiry_time
  }
}