import React from 'react';
import ColorUtils from '../utils/colors'

export function KeyFeature(props) {
  return (
    <div style={{
      textAlign: 'center',
      color: ColorUtils.textColor,
      margin: '0rem auto',
      maxWidth: '18rem',
    }}>
      <div style={{
        color: props.iconColor,
        width: '3rem',
        height: '3rem',
        margin: '0rem auto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        backgroundColor: props.iconColorBg,
        borderRadius: '2rem',
      }}><i className={`iconfont ${props.icon}`} style={{
        fontSize: '1.5rem',
        display: 'block',
      }}></i></div>
      <h2>{props.title}</h2>
      <p>{props.desc}</p>
    </div>
  )
}

export default KeyFeature;