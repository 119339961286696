import localization from "./localization";

export default {
  handleError: function (resp) {
    switch (resp.errno) {
      case 4000010:
      case 4000012:
        return localization.strings.errorAccountOrMasterPassword;
      case 4000011:
        return localization.strings.accountExists;
      case 4000030:
        return localization.strings.captchaSolutionWrong;
      case 4000018:
        return localization.strings.accountExpired;
      case 4000016:
        return localization.strings.errorMasterPassword;
      case 4000029:
        return localization.strings.contentMaxLimit;
    }
    return resp.errmsg;
  },
  listNewest: function () {
    return fetch(`/openapi/content/list_newest`, {
      method: "GET",
    });
  },
  newCaptcha: function () {
    return fetch(`/openapi/captcha/new`, {
      method: "GET",
    });
  },
  login: function (params) {
    return fetch(`/openapi/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(params),
    });
  },
  register: function (params) {
    return fetch(`/openapi/register`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(params),
    });
  },
  userInfo: function () {
    return fetch("/api/userinfo");
  },
  logout: function () {
    return fetch("/api/logout");
  },
  getContent: function () {
    return fetch("/api/content");
  },
  createContent: function (params) {
    return fetch(`/api/content`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(params),
    });
  },
  updateContent: function (params) {
    return fetch(`/api/content`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(params),
    });
  },
  deleteContent: function (id, version) {
    return fetch(`/api/content?content_id=${id}&version=${version}`, {
      method: "DELETE",
    });
  },
  sendMessage: function (params) {
    return fetch(`/api/content/send`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(params),
    });
  },
  getMessage: function (key) {
    return fetch(`/openapi/content/get?content_key=${key}`);
  },
  admin: function (key) {
    return fetch("/adminapi/admin");
  },
  getStatistics: function (success, fail) {
    return fetch("/adminapi/statistics");
  },
  postBinancePay: function (params) {
    return fetch(`/openapi/binance/order`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(params),
    });
  },
  postPayJs: function (params) {
    return fetch(`/api/payjs`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(params),
    });
  },
  postPayJsCashier: function (params) {
    return fetch(`/openapi/payjs/cashier`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(params),
    });
  },
  feedback: function (params) {
    return fetch(`/openapi/codeutils`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(params),
    });
  },
  changeMasterPassword: function (params) {
    return fetch(`/api/version`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(params),
    });
  },
  updateLanguage: function (params) {
    return fetch("/api/language", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(params),
    });
  },
};
