import { pinyin } from 'pinyin-pro'

function getParameterByName(name) {
  let urlParams = new URLSearchParams(window.location.search)
  return urlParams.get(name)
}

export default {
  getParameterByName: getParameterByName,
  sortTagList: (tagList, sortType) => {
    let tmpTagList = [...tagList]
    switch (sortType) {
      case 'sortByTimeDesc':
        break
      case 'sortByTimeAsc':
        tmpTagList = tmpTagList.reverse()
        break
      case 'sortByAlphaDesc':
        tmpTagList = tmpTagList.map((item) => {
          if (!item._sortKey) {
            item._sortKey = pinyin(item.text, { toneType: 'none' }).replaceAll(' ', '').toLowerCase()
          }
          return item
        }).sort((a, b) => {
          let at = a._sortKey
          let bt = b._sortKey
          if (at > bt) {
            return -1
          } else if (at < bt) {
            return 1
          }
          return 0
        })
        break
      case 'sortByAlphaAsc':
        tmpTagList = tmpTagList.map((item) => {
          if (!item._sortKey) {
            item._sortKey = pinyin(item.text, { toneType: 'none' }).replaceAll(' ', '').toLowerCase()
          }
          return item
        }).sort((a, b) => {
          let at = a._sortKey
          let bt = b._sortKey
          if (at > bt) {
            return 1
          } else if (at < bt) {
            return -1
          }
          return 0
        })
        break
    }
    return tmpTagList
  },
  sortContentList: (contentItemList, sortType) => {
    let tmpItemList = [...contentItemList]
    switch (sortType) {
      case 'sortByTimeDesc':
        tmpItemList = tmpItemList.sort((a, b) => {
          if (a.last_modify_time > b.last_modify_time) {
            return -1
          } else if (a.last_modify_time < b.last_modify_time) {
            return 1
          }
          return 0
        })
        break
      case 'sortByTimeAsc':
        tmpItemList = tmpItemList.sort((a, b) => {
          if (a.last_modify_time > b.last_modify_time) {
            return 1
          } else if (a.last_modify_time < b.last_modify_time) {
            return -1
          }
          return 0
        })
        break
      case 'sortByAlphaDesc':
        tmpItemList = tmpItemList.map((item) => {
          if (!item._sortKey) {
            item._sortKey = pinyin(item.title, { toneType: 'none' }).replaceAll(' ', '').toLowerCase()
          }
          return item
        }).sort((a, b) => {
          let at = a._sortKey
          let bt = b._sortKey
          if (at > bt) {
            return -1
          } else if (at < bt) {
            return 1
          }
          return 0
        })
        break
      case 'sortByAlphaAsc':
        tmpItemList = tmpItemList.map((item) => {
          if (!item._sortKey) {
            item._sortKey = pinyin(item.title, { toneType: 'none' }).replaceAll(' ', '').toLowerCase()
          }
          return item
        }).sort((a, b) => {
          let at = a._sortKey
          let bt = b._sortKey
          if (at > bt) {
            return 1
          } else if (at < bt) {
            return -1
          }
          return 0
        })
        break
    }
    return tmpItemList
  }
}