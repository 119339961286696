import React, {useState, useEffect, forwardRef, useImperativeHandle} from 'react'
import { Button, Slider, FormControl, FormGroup, FormControlLabel, Checkbox, Divider } from '@mui/material'
import FormInput from './FormInput'
import localization from '../utils/localization'
import ExtraForm from './ExtraForm'
import ContentUtils from '../utils/content'
import {BorderLinearProgress} from './ModalTotp'
import TotpUtils from '../utils/totp'
import ColorUtils from '../utils/colors'
import copy from 'copy-to-clipboard'
import toast, { Toaster } from 'react-hot-toast'

let globalTotpSecret = ''

const TextForm = forwardRef((props, ref) => {
  const [title, setTitle] = useState(props.contentInfo.id ? props.contentInfo.title : '')
  const [titleErr, setTitleErr] = useState('')
  const [totpSecret, setTotpSecret] = useState(props.contentInfo.id ? props.contentInfo.content : '')
  const [totpSecretErr, setTotpSecretErr] = useState('')
  const [extra, setExtra] = useState(props.contentInfo.id ? props.contentInfo.extra : {})

  useEffect(() => {
    if (props.contentInfo.id) {
      setTitle(props.contentInfo.title)
      setTitleErr('')
      setTotpSecret(props.contentInfo.content)
      setTotpSecretErr('')
      setExtra(props.contentInfo.extra)
    }
  }, [props.contentInfo])

  const [totpToken, setTotpToken] = useState(0)
  const [totpNumbers, setTotpNumbers] = useState([])
  const [progress, setProgress] = useState(0)
  function calcTotpToken() {
    let totpToken = 0
    try {
      totpToken = TotpUtils.generate(globalTotpSecret)
    } catch(e) {
      
    }
    setTotpToken(totpToken)
    let modalTotpTime = new Date().getTime()
    setProgress((modalTotpTime/1000%30)*100/30)
    setTotpNumbers([
      parseInt(totpToken / 100000),
      parseInt(totpToken % 100000 / 10000),
      parseInt(totpToken % 10000 / 1000),
      parseInt(totpToken % 1000 / 100),
      parseInt(totpToken % 100 / 10),
      parseInt(totpToken % 10),
    ])
  }
  useEffect(() => {
    calcTotpToken()
    let tickerID = setInterval(calcTotpToken, 1000)
    return () => {
      clearInterval(tickerID)
    }
  }, [])

  useImperativeHandle(ref, () => ({
    checkForm() {
      let hasErr = false
      if (title === '') {
        setTitleErr(localization.strings.required)
        hasErr = true
      }
      if (totpSecret === '') {
        setTotpSecretErr(localization.strings.required)
        hasErr = true
      }
      return hasErr
    },
    getContentData() {
      return {
        title: title,
        content: totpSecret,
        type: ContentUtils.getTotpType(),
        color: 'blue',
        account: '',
        extra: extra,
      }
    },
  }))

  return (
    <div>
      <div style={{
        margin: '0rem 0rem 0rem',
      }}>
        <FormInput label={localization.strings.title} value={title}
          placeholder={localization.strings.inputTitleHint} error={titleErr} onChange={(event) => {
            setTitle(event.target.value)
            setTitleErr('')
          }}/>
      </div>
      <div style={{
        margin: '1rem 0rem 0rem',
      }}>
        <FormInput label={localization.strings.totpSecret} value={totpSecret} error={totpSecretErr}
          placeholder={localization.strings.inputTotpHint} onChange={(event) => {
            setTotpSecret(event.target.value)
            setTotpSecretErr('')
            globalTotpSecret = event.target.value
            calcTotpToken()
          }}/>
      </div>
      <div style={{
        margin: '1rem 0rem 0rem',
        padding: '0rem 0rem 1rem',
      }}>
        <BorderLinearProgress variant="determinate" value={progress} />
      </div>
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        paddingLeft: '0.5rem',
      }}>
        {
          totpNumbers.map((num, index) => {
            return (
              <div key={`${index}`} style={{
                textAlign: 'center',
                width: '2rem',
                fontSize: '3rem',
                backgroundColor: ColorUtils.grey,
                borderRadius: '0.5rem',
                padding: '0.5rem',
                marginRight: '0.5rem',
                color: ColorUtils.textColor,
              }}>{num}</div>
            )
          })
        }
      </div>
      <Button variant="outlined" fullWidth color="info" onClick={() => {
        copy(`${totpToken}`)
        toast.success(localization.strings.copySuccess)
      }} style={{
        margin: '1rem 0rem 0rem',
      }}>{localization.strings.clickCopy}</Button>
      <Divider style={{
        margin: '1rem 0rem',
      }}/>
      <ExtraForm extra={extra} onChange={(e) => {
        setExtra(e)
      }}/>
    </div>
  )
})

export default TextForm