import './App.css';
import {Routes, Route} from "react-router-dom";
import Index from './routers/Index'
import Login from './routers/Login'
import Main from './routers/Main'
import MainContent from './routers/MainContent'
import MainMessage from './routers/MainMessage'
import Message from './routers/Message'
import PayJs from './routers/PayJs'
import { Toaster } from 'react-hot-toast'

function App() {
  return (
    <div style={{
      width: '100%',
      height: '100%',
      overflow: 'hidden',
    }}>
      <Routes>
        <Route path="/" element={<Index></Index>} />
        <Route path="/login.html" element={<Login></Login>} />
        <Route path="/main.html" element={<Main></Main>}>
          <Route path="content" element={<MainContent></MainContent>}/>
          <Route path="message" element={<MainMessage></MainMessage>}/>
        </Route>
        <Route path="/message.html" element={<Message></Message>} />
        <Route path="/payjs.html" element={<PayJs></PayJs>} />
      </Routes>
      <Toaster/>
    </div>
  );
}

export default App;
