import React, {useState, useEffect} from 'react'
import { Dialog, DialogContent } from '@mui/material'
import localization from '../utils/localization'

function ModalHelp(props) {

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogContent>
        <h3>{localization.strings.contactUs}</h3>
        <table>
          <tbody>
            <tr>
              <td style={{
                width: '1rem',
                height: '2rem',
                whiteSpace: 'nowrap',
              }}>{localization.strings.email}</td>
              <td style={{
                textAlign: 'left',
                paddingLeft: '2rem',
              }}><pre style={{
                fontSize: '1rem',
                margin: '0rem',
                width: '20rem',
              }}>easypass.inc@gmail.com</pre></td>
            </tr>
            <tr>
              <td style={{
                width: '1rem',
                height: '2rem',
                whiteSpace: 'nowrap',
              }}>{localization.strings.wechat}</td>
              <td style={{
                textAlign: 'left',
                paddingLeft: '2rem',
              }}><pre style={{
                fontSize: '1rem',
                margin: '0rem',
                width: '20rem',
              }}>appmakerZXQ</pre></td>
            </tr>
          </tbody>
        </table>
        <h3>{localization.strings.easypassPromise}</h3>
        <p>{localization.strings.promiseItem1}</p>
        <p>{localization.strings.promiseItem2}</p>
        <p>{localization.strings.promiseItem3}</p>
        <p>{localization.strings.promiseItem4}</p>
        <h3>{localization.strings.userNotice}</h3>
        <p>{localization.strings.userNoticeItem1}</p>
        <p>{localization.strings.userNoticeItem2}</p>
      </DialogContent>
    </Dialog>
  )
}

export default ModalHelp