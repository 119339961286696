import React, {useState, useEffect} from 'react'
import { Dialog, DialogTitle, DialogContent, Button } from '@mui/material'
import localization from '../utils/localization'
import ColorUtils from '../utils/colors'

function ModalAlert(props) {
  return (
    <Dialog open={props.open} onClose={props.onCancel}>
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent style={{
        minWidth: '300px',
        overflowY: 'visible',
      }}>
        <div>{props.content}</div>
        {
          props.hint && (
            <div style={{
              color: ColorUtils.red,
              fontSize: '0.8rem',
              marginTop: '0.5rem',
            }}>
              {props.hint}
            </div>
          )
        }
        <div style={{
          marginTop: '0.8rem',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
          <Button variant="outlined" size="small" color="secondary" onClick={props.onCancel} style={{
            width: '0px',
            flex: 1,
          }}>
            {localization.strings.cancel}
          </Button>
          <div style={{width: '0.5rem'}}></div>
          <Button variant="contained" size="small" color="error" style={{
            width: '0px',
            flex: 1,
          }} onClick={props.onConfirm}>
            {localization.strings.confirm}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default ModalAlert