import React, {useState, useEffect} from 'react'
import { styled } from '@mui/material/styles'
import { Dialog, DialogTitle, DialogContent, Button } from '@mui/material'
import TotpUtils from '../utils/totp'
import ColorUtils from '../utils/colors'
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress'
import copy from 'copy-to-clipboard'
import toast from 'react-hot-toast'
import localization from '../utils/localization'

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
  },
}));

let globalTotpSecret = ''

function ModalTotp(props) {
  globalTotpSecret = props.secret
  const [totpToken, setTotpToken] = useState(0)
  const [totpNumbers, setTotpNumbers] = useState([])
  const [progress, setProgress] = useState(0)
  function calcTotpToken() {
    let totpToken = 0
    try {
      totpToken = TotpUtils.generate(globalTotpSecret)
    } catch(e) {
      
    }
    setTotpToken(totpToken)
    let modalTotpTime = new Date().getTime()
    setProgress((modalTotpTime/1000%30)*100/30)
    setTotpNumbers([
      parseInt(totpToken / 100000),
      parseInt(totpToken % 100000 / 10000),
      parseInt(totpToken % 10000 / 1000),
      parseInt(totpToken % 1000 / 100),
      parseInt(totpToken % 100 / 10),
      parseInt(totpToken % 10),
    ])
  }
  useEffect(() => {
    calcTotpToken()
    let tickerID = setInterval(calcTotpToken, 1000)
    return () => {
      clearInterval(tickerID)
    }
  }, [])
  useEffect(() => {
    globalTotpSecret = props.secret
    calcTotpToken()
  }, [props.secret])

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogContent>
        <div style={{
          padding: '0rem 0rem 1rem',
        }}>
          <BorderLinearProgress variant="determinate" value={progress} />
        </div>
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          paddingLeft: '0.5rem',
        }}>
          {
            totpNumbers.map((num, index) => {
              return (
                <div key={`${index}`} style={{
                  textAlign: 'center',
                  width: '2rem',
                  fontSize: '3rem',
                  backgroundColor: ColorUtils.grey,
                  borderRadius: '0.5rem',
                  padding: '0.5rem',
                  marginRight: '0.5rem',
                  color: ColorUtils.textColor,
                }}>{num}</div>
              )
            })
          }
        </div>
        <Button variant="outlined" fullWidth color="info" onClick={() => {
          copy(`${totpToken}`)
          toast.success(localization.strings.copySuccess)
        }} style={{
          margin: '1rem 0rem 0rem',
        }}>{localization.strings.clickCopy}</Button>
      </DialogContent>
    </Dialog>
  )
}

export {BorderLinearProgress}
export default ModalTotp