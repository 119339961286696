import React, {useState, useEffect} from 'react'
import { Dialog, DialogTitle, DialogContent, Button } from '@mui/material'
import ContentItem from './ContentItem'
import ContentUtils from '../utils/content'
import localization from '../utils/localization'

function ModalCreateMenu(props) {
  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogContent style={{
        minWidth: '300px',
        overflowY: 'visible',
      }}>
        <div style={{
          padding: '0rem 0rem 0.5rem',
        }}>
          <ContentItem contentType={ContentUtils.getPasswordType()} text={localization.strings.createPassword} onClick={() => {
            props.onClick(ContentUtils.getPasswordType())
          }}/>
        </div>
        <div style={{
          padding: '0rem 0rem 0.5rem',
        }}>
          <ContentItem contentType={ContentUtils.getTextType()} text={localization.strings.createText} onClick={() => {
            props.onClick(ContentUtils.getTextType())
          }}/>
        </div>
        <div style={{
          padding: '0rem 0rem 0.5rem',
        }}>
          <ContentItem contentType={ContentUtils.getTotpType()} text={localization.strings.createTotp} onClick={() => {
            props.onClick(ContentUtils.getTotpType())
          }}/>
        </div>
        <div style={{
          padding: '0rem 0rem 0rem',
        }}>
          <ContentItem contentType={ContentUtils.getBlockChainType()} text={localization.strings.createBlockchain} onClick={() => {
            props.onClick(ContentUtils.getBlockChainType())
          }}/>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default ModalCreateMenu