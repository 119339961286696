import React, {useState, useEffect} from 'react';
import { Dialog, DialogTitle, DialogContent, Button } from '@mui/material'
import ServerUtils from '../utils/server'
import toast, { Toaster } from 'react-hot-toast'
import localization from '../utils/localization'
import ColorUtils from '../utils/colors'

function ModalDashboard(props) {
  const [userCount, setUserCount] = useState(0)
  const [yesterdayUserCount, setYesterdayUserCount] = useState(0)
  const [contentCount, setContentCount] = useState(0)
  const [yesterdayContentCount, setYesterdayContentCount] = useState(0)

  useEffect(() => {
    if (!props.open) return
    ServerUtils.getStatistics().then(async (resp) => {
      if (!resp.ok) {
        try {
          let respJson = await resp.json()
          if (respJson.errno !== 0) {
            toast.error(ServerUtils.handleError(respJson))
            return
          }
        } catch(e) {

        }
        toast.error(localization.strings.networkErr)
        return
      }
      let respJson = await resp.json()
      if (respJson.errno !== 0) {
        toast.error(ServerUtils.handleError(respJson))
        return
      }
      setUserCount(respJson.data.total_user_num)
      setYesterdayUserCount(respJson.data.yesterday_user_num)
      setContentCount(respJson.data.total_content_num)
      setYesterdayContentCount(respJson.data.yesterday_content_num)
    })
  }, [props.open])

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogTitle>Dashboard</DialogTitle>
      <DialogContent style={{
        minWidth: '300px',
        overflowY: 'visible',
      }}>
        <div style={{
          backgroundColor: ColorUtils.purpleBg,
          color: ColorUtils.purple,
          padding: '0.5rem',
          borderRadius: '0.5rem',
          cursor: 'pointer',
          textAlign: 'right',
        }}>
          <div style={{
            fontSize: '2rem',
          }}>{userCount}</div>
          <div>{localization.strings.userTotal}</div>
        </div>
        <div style={{
          flex: 1,
          backgroundColor: ColorUtils.lightBlue,
          color: ColorUtils.blue,
          padding: '0.5rem',
          borderRadius: '0.5rem',
          cursor: 'pointer',
          textAlign: 'right',
          marginTop: '0.5rem',
        }}>
          <div style={{
            fontSize: '2rem',
          }}>{yesterdayUserCount}</div>
          <div>{localization.strings.userYesterday}</div>
        </div>
        <div style={{
          backgroundColor: ColorUtils.lightYellow,
          color: ColorUtils.darkYellow,
          padding: '0.5rem',
          borderRadius: '0.5rem',
          cursor: 'pointer',
          textAlign: 'right',
          marginTop: '0.5rem',
        }}>
          <div style={{
            fontSize: '2rem',
          }}>{contentCount}</div>
          <div>{localization.strings.contentTotal}</div>
        </div>
        <div style={{
          backgroundColor: ColorUtils.lightRed,
          color: ColorUtils.red,
          padding: '0.5rem',
          borderRadius: '0.5rem',
          cursor: 'pointer',
          textAlign: 'right',
          marginTop: '0.5rem',
        }}>
          <div style={{
            fontSize: '2rem',
          }}>{yesterdayContentCount}</div>
          <div>{localization.strings.contentYesterday}</div>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default ModalDashboard