import React, {useState, useEffect} from 'react';
import ColorUtils from '../utils/colors'
import logo256 from '../assets/logo_256.png'
import localization from '../utils/localization'
import FormInput from '../components/FormInput'
import { Button, FormControl, Select, MenuItem, InputLabel, OutlinedInput, Dialog, DialogContent } from '@mui/material';
import ServerUtils from '../utils/server'
import toast from 'react-hot-toast';
import AesUtils from '../utils/aes'
import MasterPassUtils from '../utils/masterpass'
import { useNavigate } from "react-router-dom";

function Login() {
  const navigate = useNavigate();
  const [agreementDialogOpen, setAgreementDialogOpen] = useState(true)
  const [mode, setMode] = useState('login')
  const [captcha, setCaptcha] = useState('')

  function refreshCaptcha() {
    ServerUtils.newCaptcha().then(async (resp) => {
      if (!resp.ok) {
        toast.error(localization.strings.networkErr)
        return
      }
      let respJson = await resp.json()
      if (respJson.errno !== 0) {
        toast.error(ServerUtils.handleError(respJson))
        return
      }
      setCaptcha(respJson.data.captcha_id)
    })
  }

  // login params
  const [loginAccount, setLoginAccount] = useState('')
  const [loginAccountErr, setLoginAccountErr] = useState('')
  const [loginMasterPassword, setLoginMasterPassword] = useState('')
  const [loginMasterPasswordErr, setLoginMasterPasswordErr] = useState('')
  function login() {
    let hasErr = false;
    if (loginAccount === '') {
      setLoginAccountErr(localization.strings.required)
      hasErr = true;
    }
    if (loginMasterPassword === '') {
      setLoginMasterPasswordErr(localization.strings.required)
      hasErr = true;
    }
    if (hasErr) return
    let currTime = parseInt(new Date().getTime() / 1000)
    let signedAccount = AesUtils.encryptData(loginAccount, loginMasterPassword, loginAccount)
    let text = loginAccount + '-' + signedAccount + '-' + currTime
    ServerUtils.login({
      account: loginAccount,
      time: currTime,
      token: AesUtils.sha256(text),
    }).then(async (resp) => {
      if (!resp.ok) {
        try {
          let respJson = await resp.json()
          if (respJson.errno !== 0) {
            toast.error(ServerUtils.handleError(respJson))
            return
          }
        } catch(e) {

        }
        toast.error(localization.strings.networkErr)
        return
      }
      let respJson = await resp.json()
      if (respJson.errno !== 0) {
        toast.error(ServerUtils.handleError(respJson))
        return
      }
      MasterPassUtils.setMasterPass(loginAccount, loginMasterPassword)
      navigate(`/main.html?lang=${localization.language.lang}`)
    })
  }

  // register params
  const [registerAccount, setRegisterAccount] = useState('')
  const [registerAccountErr, setRegisterAccountErr] = useState('')
  const [registerMasterPassword, setRegisterMasterPassword] = useState('')
  const [registerMasterPasswordErr, setRegisterMasterPasswordErr] = useState('')
  const [registerMasterRePassword, setRegisterMasterRePassword] = useState('')
  const [registerMasterRePasswordErr, setRegisterMasterRePasswordErr] = useState('')
  const [registerCaptcha, setRegisterCaptcha] = useState('')
  const [registerCaptchaErr, setRegisterCaptchaErr] = useState('')
  const [registerVisitCode, setRegisterVisitCode] = useState('')
  let isRegister = false;
  function register() {
    if (isRegister) return
    isRegister = true
    let hasErr = false;
    if (registerAccount === '') {
      setRegisterAccountErr(localization.strings.required)
      hasErr = true;
    }
    if (registerMasterPassword === '') {
      setRegisterMasterPasswordErr(localization.strings.required)
      hasErr = true;
    }
    if (registerMasterRePassword === '') {
      setRegisterMasterRePasswordErr(localization.strings.required)
      hasErr = true;
    } else if (registerMasterPassword !== registerMasterRePassword) {
      setRegisterMasterRePasswordErr(localization.strings.masterPasswordNotMatch)
      hasErr = true;
    }
    if (registerCaptcha === '') {
      setRegisterCaptchaErr(localization.strings.required)
      hasErr = true;
    }
    if (hasErr) {
      isRegister = false
      return
    }
    let signedAccount = AesUtils.encryptData(registerAccount, registerMasterPassword, registerAccount)
    ServerUtils.register({
      account: registerAccount,
      signed_account: signedAccount,
      be_invited_code: registerVisitCode,
      language: localization.language.lang,
      captcha_id: captcha,
      captcha_solution: registerCaptcha,
    }).then(async (resp) => {
      isRegister = false
      if (!resp.ok) {
        try {
          let respJson = await resp.json()
          if (respJson.errno !== 0) {
            if (respJson.errno === 4000030) refreshCaptcha()
            toast.error(ServerUtils.handleError(respJson))
            return
          }
        } catch(e) {

        }
        toast.error(localization.strings.networkErr)
        return
      }
      toast.success(localization.strings.successRegister)
      setRegisterAccount('')
      setRegisterMasterPassword('')
      setRegisterMasterRePassword('')
      setRegisterCaptcha('')
      setRegisterVisitCode('')
      setMode('login')
    })
  }

  useEffect(() => {
    ServerUtils.userInfo().then(async (resp) => {
      if (!resp.ok) {
        return
      }
      let respJson = await resp.json()
      if (respJson.errno !== 0) {
        if (respJson.errno === 4000030) refreshCaptcha()
        toast.error(ServerUtils.handleError(respJson))
        return
      }
      window.location.href = `/main.html?lang=${respJson.data.language}`
    })
  }, [])

  return (
    <div style={{
      height: '100%',
      overflowY: 'scroll',
      backgroundColor: ColorUtils.lightBlue,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    }}>
      {
        mode === 'login' && <div style={{
          margin: '1rem auto 0rem',
          width: '30%',
          minWidth: '20rem',
          maxWidth: '30rem',
          backgroundColor: ColorUtils.white,
          border: '1px solid rgba(144, 202, 249, 0.46)',
          padding: '2rem 2rem',
          borderRadius: '1rem',
          position: 'relative',
        }}>
          <form autoComplete="on">
            <FormControl size="small" sx={{minWidth: 120 }} style={{
              position: 'absolute',
              top: '1rem',
              right: '1rem',
            }}>
              <InputLabel id="demo-dialog-select-label" style={{
                color: ColorUtils.textColor,
              }}>{localization.strings.language}</InputLabel>
              <Select
                labelId="demo-dialog-select-label"
                id="demo-dialog-select"
                defaultValue={localization.language.lang}
                input={<OutlinedInput label={localization.language.lang}/>}
                onChange={(event)=>{
                  window.location.href = '?lang='+event.target.value
                }}
                size='small'
                style={{
                  color: ColorUtils.textColor,
                }}>
                <MenuItem value="en">English</MenuItem>
                <MenuItem value="zh">中文</MenuItem>
              </Select>
            </FormControl>
            <a style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              textDecoration: 'none',
            }} href="/">
              <img src={logo256} style={{
                display: 'block',
                height: '6rem',
                margin: '0rem auto 0rem',
              }}/>
              <h2 style={{
                color: ColorUtils.purple,
                textAlign: 'center',
                margin: '0.5rem 0rem'
              }}>{localization.strings.login} EasyPass</h2>
            </a>
            <div style={{
              margin: '2rem 0rem 1rem',
            }}>
              <FormInput start_icon="icon-person" label={localization.strings.account} placeholder={localization.strings.inputAccountHint}
                error={loginAccountErr} onChange={(event) => {
                  setLoginAccount(event.target.value)
                  setLoginAccountErr('')
                }}/>
            </div>
            <div style={{
              margin: '1rem 0rem 1rem',
            }}>
              <FormInput type="password" start_icon="icon-lock" label={localization.strings.masterPassword}
                placeholder={localization.strings.inputMasterPasswordHint} error={loginMasterPasswordErr} onChange={(event) => {
                  setLoginMasterPassword(event.target.value)
                  setLoginMasterPasswordErr('')
                }} onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    login()
                    return
                  }
                }}/>
            </div>
            <p style={{
              color: ColorUtils.red,
            }}>{localization.strings.masterPasswordAlert}</p>
            <Button variant="contained" color="primary" fullWidth style={{
              margin: '0rem 0rem 0rem',
            }} onClick={login}>
              {localization.strings.login}
            </Button>
            <Button variant="outlined" color="info" fullWidth style={{
              margin: '1rem 0rem 0rem',
            }} onClick={() => {
              setMode('register')
              refreshCaptcha()
            }}>
              {localization.strings.goRegister}
            </Button>
          </form>
        </div>
      }
      {
        mode === 'register' && <div style={{
          margin: '1rem auto 0rem',
          width: '30%',
          minWidth: '20rem',
          maxWidth: '30rem',
          backgroundColor: ColorUtils.white,
          border: '1px solid rgba(144, 202, 249, 0.46)',
          padding: '2rem 2rem',
          borderRadius: '1rem',
          position: 'relative',
        }}>
          <FormControl size="small" sx={{minWidth: 120 }} style={{
            position: 'absolute',
            top: '1rem',
            right: '1rem',
          }}>
            <InputLabel id="demo-dialog-select-label" style={{
              color: ColorUtils.textColor,
            }}>{localization.strings.language}</InputLabel>
            <Select
              labelId="demo-dialog-select-label"
              id="demo-dialog-select"
              defaultValue={localization.language.lang}
              input={<OutlinedInput label={localization.language.lang}/>}
              onChange={(event)=>{
                window.location.href = '?lang='+event.target.value
              }}
              size='small'
              style={{
                color: ColorUtils.textColor,
              }}>
              <MenuItem value="en">English</MenuItem>
              <MenuItem value="zh">中文</MenuItem>
            </Select>
          </FormControl>
          <img src={logo256} style={{
            display: 'block',
            height: '6rem',
            margin: '0rem auto 0rem',
          }}/>
          <h2 style={{
            color: ColorUtils.purple,
            textAlign: 'center',
            margin: '0.5rem 0rem'
          }}>{localization.strings.register} EasyPass</h2>
          <div style={{
            margin: '2rem 0rem 1rem',
          }}>
            <FormInput start_icon="icon-person" label={localization.strings.account} placeholder={localization.strings.inputAccountHint}
              error={registerAccountErr} onChange={(event) => {
                setRegisterAccount(event.target.value)
                setRegisterAccountErr('')
              }}/>
          </div>
          <div style={{
            margin: '1rem 0rem 1rem',
          }}>
            <FormInput type="password" start_icon="icon-lock" label={localization.strings.masterPassword}
              placeholder={localization.strings.inputMasterPasswordHint} error={registerMasterPasswordErr} onChange={(event) => {
                setRegisterMasterPassword(event.target.value)
                setRegisterMasterPasswordErr('')
                setRegisterMasterRePasswordErr('')
              }}/>
          </div>
          <div style={{
            margin: '1rem 0rem 1rem',
          }}>
            <FormInput type="password" start_icon="icon-lock" label={localization.strings.masterPassword}
              placeholder={localization.strings.inputMasterPasswordHintRepeat} error={registerMasterRePasswordErr} onChange={(event) => {
                setRegisterMasterRePassword(event.target.value)
                setRegisterMasterRePasswordErr('')
              }}/>
          </div>
          <img src={`/openapi/captcha/${captcha}.png`} style={{
            margin: '0rem 0rem',
          }} onClick={refreshCaptcha}/>
          <div style={{
            margin: '1rem 0rem 1rem',
          }}>
            <FormInput start_icon="icon-image" label={localization.strings.captcha}
              placeholder={localization.strings.inputCaptchaHint} error={registerCaptchaErr} onChange={(event) => {
                setRegisterCaptcha(event.target.value)
                setRegisterCaptchaErr('')
              }} onKeyDown={(e) => {
                if (e.keyCode === 13) {
                  register()
                  return
                }
              }}/>
          </div>
          {/* <div style={{
            margin: '1rem 0rem 1rem',
          }}>
            <FormInput start_icon="icon-friends" label={localization.strings.inviteCode}
              placeholder={localization.strings.inputInviteCodeHint} onChange={(event) => {
                setRegisterVisitCode(event.target.value)
              }}/>
          </div> */}
          <p style={{
            color: ColorUtils.red,
          }}>{localization.strings.masterPasswordAlert}</p>
          <Button variant="contained" color="primary" fullWidth style={{
            margin: '0rem 0rem 0rem',
          }} onClick={register}>
            {localization.strings.register}
          </Button>
          <Button variant="outlined" color="info" fullWidth style={{
            margin: '1rem 0rem 0rem',
          }} onClick={() => {
            setMode('login')
          }}>
            {localization.strings.goLogin}
          </Button>
        </div>
      }
      <div style={{maxHeight:'20rem', minHeight: '1rem',flex:1}}></div>

      {/* Agreement Dialog */}
      <Dialog open={agreementDialogOpen} onClose={(event) => {}}>
        <DialogContent style={{
          minWidth: '300px',
          overflowY: 'visible',
        }}>
          <h3>{localization.strings.easypassPromise}</h3>
          <p>{localization.strings.promiseItem1}</p>
          <p>{localization.strings.promiseItem2}</p>
          <p>{localization.strings.promiseItem3}</p>
          <p>{localization.strings.promiseItem4}</p>
          <h3>{localization.strings.userNotice}</h3>
          <p>{localization.strings.userNoticeItem1}</p>
          <p>{localization.strings.userNoticeItem2}</p>
          <div style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'end',
          }}>
            <Button variant="contained" size="small" color="primary" onClick={() => {
              setAgreementDialogOpen(false)
            }}>
              {localization.strings.agree}
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default Login;