import React, {useState, useEffect, useRef} from 'react';
import { Button, Dialog, DialogTitle, DialogContent, FormControl, InputLabel, Select, MenuItem, CircularProgress } from '@mui/material'
import localization from '../utils/localization'
import ContentUtils from '../utils/content'
import ContentItem from '../components/ContentItem'
import FormInput from '../components/FormInput'
import ColorUtils from '../utils/colors'
import UserUtils from '../utils/user'
import AesUtils from '../utils/aes'
import copy from 'copy-to-clipboard'
import toast from 'react-hot-toast'
import ServerUtils from '../utils/server'
import PasswordForm from '../components/PasswordForm'
import TextForm from '../components/TextForm'
import TotpForm from '../components/TotpForm'
import BlockchainForm from '../components/BlockchainForm'

const ValidityPeriods = [{
  value: 10*60,
  desc: '10' + localization.strings.minute,
}, {
  value: 30*60,
  desc: '30' + localization.strings.minute,
}, {
  value: 60*60,
  desc: '1' + localization.strings.hour,
}, {
  value: 3*60*60,
  desc: '3' + localization.strings.hour,
}, {
  value: 6*60*60,
  desc: '6' + localization.strings.hour,
}, {
  value: 12*60*60,
  desc: '12' + localization.strings.hour,
}, {
  value: 24*60*60,
  desc: '1' + localization.strings.day,
}]

function MainMessage() {
  const [contentList, setContentList] = useState([])
  const [dialogTitle, setDialogTitle] = useState('')
  const [dialogOpen, setDialogOpen] = useState(false)
  const [step, setStep] = useState(0)
  const [keyword, setKeyword] = useState('')
  const [mode, setMode] = useState('')
  const [contentType, setContentType] = useState(ContentUtils.getPasswordType())
  const [contentInfo, setContentInfo] = useState({})
  const [validityPeriod, setValidityPeriod] = useState(600)
  const [tempPassword, setTempPassword] = useState('')
  const [tempPasswordErr, setTempPasswordErr] = useState('')
  const [hintWord, setHintWord] = useState('')
  const [submit, setSubmit] = useState(false)
  const [link, setLink] = useState(false)
  const passwordFormRef = useRef();
  const textFormRef = useRef();
  const totpFormRef = useRef();
  const blockchainFormRef = useRef();

  useEffect(() => {
    if (dialogOpen) {
      setContentType(ContentUtils.getPasswordType())
      setStep(0)
    }
  }, [dialogOpen])

  useEffect(() => {
    ContentUtils.setContentListener(async (rawContentList) => {
      for (var i=0;i<rawContentList.length;i++) {
        if (rawContentList[i].type === ContentUtils.getBlockChainType()) {
          rawContentList[i].detail = JSON.parse(rawContentList[i].content)
        }
      }
      setContentList(rawContentList)
    })
  }, [])

  function doPack() {
    if (tempPassword === '') {
      setTempPasswordErr(localization.strings.required)
      return
    }
    if (submit) return
    setSubmit(true)
    setStep(2)
    let userInfo = UserUtils.getUserInfo()
    ServerUtils.sendMessage({
      encrypted_data: AesUtils.encryptData(userInfo.account, tempPassword, JSON.stringify(contentInfo)),
      expire_time: validityPeriod,
      sign: AesUtils.sha256(tempPassword),
      color: contentInfo.color,
      hint_word: hintWord,
    }).then(async (resp) => {
      setSubmit(false)
      if (!resp.ok) {
        try {
          let respJson = await resp.json()
          if (respJson.errno !== 0) {
            toast.error(ServerUtils.handleError(respJson))
            return
          }
        } catch(e) {

        }
        toast.error(localization.strings.networkErr)
        return
      }
      let respJson = await resp.json()
      if (respJson.errno !== 0) {
        toast.error(ServerUtils.handleError(respJson))
        return
      }
      setLink(`${window.origin}/message.html?key=${respJson.data.content_key}`)
      setStep(3)
      setTempPassword('')
      setHintWord('')
    })
  }

  return (
    <div style={{
      height: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    }}>
      <Button variant="outlined" color="primary" onClick={() => {
        setStep(0)
        setContentInfo({})
        setDialogOpen(true)
        setMode('sendExistPassword')
        setDialogTitle(localization.strings.sendExistPassword)
      }}>
        {localization.strings.sendExistPassword}
      </Button>
      <div style={{
        width: '20%'
      }}></div>
      <Button variant="outlined" color="success" onClick={() => {
        setStep(0)
        setContentInfo({})
        setDialogOpen(true)
        setMode('sendNewPassword')
        setDialogTitle(localization.strings.sendNewPassword)
      }}>
        {localization.strings.sendNewPassword}
      </Button>

      { /* Crypto Message Dialog */}
      <Dialog open={dialogOpen} onClose={(event) => {
        setStep(0)
        setDialogOpen(false)
      }}>
        <DialogTitle>{dialogTitle}</DialogTitle>
        <DialogContent style={{
          minWidth: '480px',
          overflowY: 'visible',
        }}>
          {
            step === 0 && mode === 'sendExistPassword' && (
              <div>
                <FormInput label={localization.strings.keyword} placeholder={localization.strings.keywordHint} size="small" onChange={(event) => {
                  setKeyword(event.target.value)
                }}/>
                <div style={{
                  margin: '1rem 0rem 0rem',
                }}>
                  {
                    contentList.filter((item) => {
                      if (keyword === '') return true
                      return item.title.indexOf(keyword) >= 0
                    }).map((item) => {
                      return (
                        <div key={item.id} style={{
                          padding: '0rem 0rem 0.5rem',
                        }}>
                          <ContentItem contentType={item.type} text={item.title} onClick={() => {
                            setContentInfo({
                              title: item.title,
                              content: item.content,
                              type: item.type,
                              color: item.color,
                              account: item.account,
                              extra: item.extra,
                            })
                            setStep(1)
                          }}/>
                        </div>
                      )
                    })
                  }
                </div>
              </div>
            )
          }
          {
            step === 0 && mode === 'sendNewPassword' && (
              <div style={{
                margin: '1rem 0rem 0rem'
              }}>
                <FormControl fullWidth>
                  <InputLabel>{localization.strings.contentType}</InputLabel>
                  <Select
                    value={contentType}
                    label={localization.strings.contentType}
                    onChange={(event) => {
                      setContentType(parseInt(event.target.value))
                    }}>
                    <MenuItem value={ContentUtils.getPasswordType()}>{localization.strings.password}</MenuItem>
                    <MenuItem value={ContentUtils.getTextType()}>{localization.strings.text}</MenuItem>
                    <MenuItem value={ContentUtils.getTotpType()}>{localization.strings.totp}</MenuItem>
                    <MenuItem value={ContentUtils.getBlockChainType()}>{localization.strings.blockchain}</MenuItem>
                  </Select>
                </FormControl>
                <div style={{
                  margin: '1rem 0rem 2rem',
                }}>
                  {
                    contentType === ContentUtils.getPasswordType() && (<PasswordForm ref={passwordFormRef} contentInfo={contentInfo}/>)
                  }
                  {
                    contentType === ContentUtils.getTextType() && (<TextForm ref={textFormRef} contentInfo={contentInfo}/>)
                  }
                  {
                    contentType === ContentUtils.getTotpType() && (<TotpForm ref={totpFormRef} contentInfo={contentInfo}/>)
                  }
                  {
                    contentType === ContentUtils.getBlockChainType() && (<BlockchainForm ref={blockchainFormRef} contentInfo={contentInfo}/>)
                  }
                </div>
                <div style={{
                  marginTop: '1rem',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}>
                  <Button fullWidth variant="outlined" color="secondary" onClick={() => {
                    setDialogOpen(false)
                  }}>{localization.strings.cancel}</Button>
                  <div style={{width:'1rem'}}></div>
                  <Button fullWidth variant="outlined" color="primary" onClick={() => {
                    let contentData = {}
                    switch (contentType) {
                      case ContentUtils.getPasswordType():
                        if (passwordFormRef.current.checkForm()) {
                          setSubmit(false)
                          return
                        }
                        contentData = passwordFormRef.current.getContentData()
                        break
                      case ContentUtils.getTextType():
                        if (textFormRef.current.checkForm()) {
                          setSubmit(false)
                          return
                        }
                        contentData = textFormRef.current.getContentData()
                        break
                      case ContentUtils.getTotpType():
                        if (totpFormRef.current.checkForm()) {
                          setSubmit(false)
                          return
                        }
                        contentData = totpFormRef.current.getContentData()
                        break
                      case ContentUtils.getBlockChainType():
                        if (blockchainFormRef.current.checkForm()) {
                          setSubmit(false)
                          return
                        }
                        contentData = blockchainFormRef.current.getContentData()
                        break
                    }
                    contentData.tags = []
                    setContentInfo(contentData)
                    setStep(1)
                  }}>{localization.strings.goNext}</Button>
                </div>
              </div>
            )
          }
          {
            step === 1 && (
              <div>
                <FormControl fullWidth>
                  <InputLabel>{localization.strings.validityPeriod}</InputLabel>
                  <Select
                    value={validityPeriod}
                    label={localization.strings.validityPeriod}
                    onChange={(event) => {
                      setValidityPeriod(parseInt(event.target.value))
                    }}>
                    {
                      ValidityPeriods.map((item) => {
                        return (
                          <MenuItem key={item.value} value={item.value}>{item.desc}</MenuItem>
                        )
                      })
                    }
                  </Select>
                </FormControl>
                <div style={{
                  marginTop: '1rem',
                }}>
                  <FormInput type="password" label={localization.strings.tempPassword} placeholder={localization.strings.keywordHint} onChange={(event) => {
                    setTempPassword(event.target.value)
                  }} error={tempPasswordErr}/>
                </div>
                <div style={{
                  marginTop: '1rem',
                }}>
                  <FormInput label={`${localization.strings.messageHintWord} (${localization.strings.optional})`} placeholder={localization.strings.inputMessageHintWordHint} onChange={(event) => {
                    setHintWord(event.target.value)
                  }}/>
                </div>
                <div style={{
                  marginTop: '1rem',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}>
                  <Button fullWidth variant="outlined" color="secondary" onClick={() => {
                    setStep(0)
                  }}>{localization.strings.goBack}</Button>
                  <div style={{width:'1rem'}}></div>
                  <Button fullWidth variant="outlined" color="primary" onClick={doPack}>{localization.strings.pack}</Button>
                </div>
              </div>
            )
          }
          {
            step === 2 && (
              <div>
                <CircularProgress size="5rem" style={{
                  display: 'block',
                  margin: '0rem auto',
                }}/>
                <div style={{
                  textAlign: 'center',
                  color: ColorUtils.textColor,
                  marginTop: '1rem',
                  fontSize: '1.2rem',
                }}>{localization.strings.packing}</div>
              </div>
            )
          }
          {
            step === 3 && (
              <div>
                <div>{localization.strings.packResult}</div>
                <div style={{
                  backgroundColor: ColorUtils.lightBlue,
                  margin: '1rem auto 0rem',
                  color: ColorUtils.blue,
                  width: '5rem',
                  height: '5rem',
                  lineHeight: '5rem',
                  textAlign: 'center',
                  borderRadius: '1rem',
                  cursor: 'pointer',
                }} onClick={() => {
                  copy(link)
                  toast.success(localization.strings.copySuccess)
                }}>
                  <i className="iconfont icon-link" style={{
                    fontSize: '3rem',
                  }}></i>
                </div>
              </div>
            )
          }
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default MainMessage;