import React, {useState, useEffect, useRef} from 'react'
import { Dialog, DialogTitle, DialogContent, Button } from '@mui/material'
import localization from '../utils/localization'
import ContentUtils from '../utils/content'
import ContentIcon from './ContentIcon'
import PasswordForm from '../components/PasswordForm'
import ServerUtils from '../utils/server'
import AesUtils from '../utils/aes'
import toast from 'react-hot-toast'
import UserUtils from '../utils/user'
import TextForm from '../components/TextForm'
import TotpForm from '../components/TotpForm'
import BlockchainForm from '../components/BlockchainForm'

function ModalContent(props) {
  const [submit, setSubmit] = useState(false)
  const passwordFormRef = useRef();
  const textFormRef = useRef();
  const totpFormRef = useRef();
  const blockchainFormRef = useRef();

  function submitContent() {
    if (submit) return
    setSubmit(true)
    let contentData = {}
    switch (props.contentType) {
      case ContentUtils.getPasswordType():
        if (passwordFormRef.current.checkForm()) {
          setSubmit(false)
          return
        }
        contentData = passwordFormRef.current.getContentData()
        break
      case ContentUtils.getTextType():
        if (textFormRef.current.checkForm()) {
          setSubmit(false)
          return
        }
        contentData = textFormRef.current.getContentData()
        break
      case ContentUtils.getTotpType():
        if (totpFormRef.current.checkForm()) {
          setSubmit(false)
          return
        }
        contentData = totpFormRef.current.getContentData()
        break
      case ContentUtils.getBlockChainType():
        if (blockchainFormRef.current.checkForm()) {
          setSubmit(false)
          return
        }
        contentData = blockchainFormRef.current.getContentData()
        break
    }
    contentData.tags = []
    if (props.contentInfo.id) {
      contentData.tags = props.contentInfo.tags
    } else if (props.tag) {
      contentData.tags.push(props.tag)
    }
    let userInfo = UserUtils.getUserInfo()
    if (props.contentInfo.id) {
      ServerUtils.updateContent({
        content_id: props.contentInfo.id,
        version: userInfo.version,
        encrypted_data: AesUtils.encryptData(userInfo.account, UserUtils.getMasterPass(), JSON.stringify(contentData)),
      }).then(async (resp) => {
        setSubmit(false)
        if (!resp.ok) {
          try {
            let respJson = await resp.json()
            if (respJson.errno !== 0) {
              toast.error(ServerUtils.handleError(respJson))
              return
            }
          } catch(e) {
  
          }
          toast.error(localization.strings.networkErr)
          return
        }
        let respJson = await resp.json()
        if (respJson.errno !== 0) {
          toast.error(ServerUtils.handleError(respJson))
          return
        }
        ContentUtils.updateContent(Object.assign({
          id: props.contentInfo.id,
          version: userInfo.version,
          last_modify_time: new Date().getTime() / 1000,
        }, contentData))
        toast.success(localization.strings.successModify)
        props.onClose()
      })
    } else {
      ServerUtils.createContent({
        version: userInfo.version,
        encrypted_data: AesUtils.encryptData(userInfo.account, UserUtils.getMasterPass(), JSON.stringify(contentData)),
      }).then(async (resp) => {
        setSubmit(false)
        if (!resp.ok) {
          try {
            let respJson = await resp.json()
            if (respJson.errno !== 0) {
              toast.error(ServerUtils.handleError(respJson))
              return
            }
          } catch(e) {
  
          }
          toast.error(localization.strings.networkErr)
          return
        }
        let respJson = await resp.json()
        if (respJson.errno !== 0) {
          toast.error(ServerUtils.handleError(respJson))
          return
        }
        ContentUtils.addContent(Object.assign({
          id: respJson.data.content_id,
          version: userInfo.version,
          last_modify_time: new Date().getTime() / 1000,
        }, contentData))
        toast.success(localization.strings.successCreate)
        props.onClose()
        props.onCreate(Object.assign({
          id: respJson.data.content_id,
          version: userInfo.version,
          last_modify_time: new Date().getTime() / 1000,
        }, contentData))
      })
    }
  }

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogTitle style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
      }}>
        <ContentIcon contentType={props.contentType} size="2.5rem" fontSize="1.7rem"/>
        <div style={{
          marginLeft: '1rem',
        }}>{props.title}</div>
      </DialogTitle>
      <DialogContent style={{
        minWidth: '450px',
        overflowY: 'visible',
      }}>
        <div style={{
          margin: '1rem 0rem 2rem',
        }}>
          {
            props.contentType === ContentUtils.getPasswordType() && (<PasswordForm ref={passwordFormRef} contentInfo={props.contentInfo}/>)
          }
          {
            props.contentType === ContentUtils.getTextType() && (<TextForm ref={textFormRef} contentInfo={props.contentInfo}/>)
          }
          {
            props.contentType === ContentUtils.getTotpType() && (<TotpForm ref={totpFormRef} contentInfo={props.contentInfo}/>)
          }
          {
            props.contentType === ContentUtils.getBlockChainType() && (<BlockchainForm ref={blockchainFormRef} contentInfo={props.contentInfo}/>)
          }
        </div>
        <div style={{
          marginTop: '0.8rem',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
          <Button variant="outlined" color="secondary" onClick={props.onClose} style={{
            width: '0px',
            flex: 1,
          }}>
            {localization.strings.cancel}
          </Button>
          <div style={{width: '0.5rem'}}></div>
          <Button variant="contained" color="primary" style={{
            width: '0px',
            flex: 1,
          }} onClick={submitContent}>
            {localization.strings.confirm}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default ModalContent