import React, {useState, useEffect} from 'react'
import { Dialog, DialogTitle, DialogContent, Button } from '@mui/material'
import localization from '../utils/localization'
import ColorUtils from '../utils/colors'
import FormInput from './FormInput'
import ServerUtils from '../utils/server'
import ContentUtils from '../utils/content'
import toast from 'react-hot-toast'
import UserUtils from '../utils/user'
import AesUtils from '../utils/aes'

function ModalChangeMasterPassword(props) {
  const [changing, setChanging] = useState(false)
  const [currMasterPassword, setCurrMasterPassword] = useState('')
  const [currMasterPasswordErr, setCurrMasterPasswordErr] = useState('')
  const [newMasterPassword, setNewMasterPassword] = useState('')
  const [newMasterPasswordErr, setNewMasterPasswordErr] = useState('')
  const [newMasterPasswordAgain, setNewMasterPasswordAgain] = useState('')
  const [newMasterPasswordAgainErr, setNewMasterPasswordAgainErr] = useState('')

  function changeMasterPassword() {
    if (changing) return
    setChanging(true)
    let userInfo = UserUtils.getUserInfo()
    let hasErr = false
    if (currMasterPassword === '') {
      setCurrMasterPasswordErr(localization.strings.required)
      hasErr = true
    } else {
      let decryptedAccount = AesUtils.decryptData(userInfo.account, currMasterPassword, userInfo.signed_account)
      if (decryptedAccount !== userInfo.account) {
        setCurrMasterPasswordErr(localization.strings.errorMasterPassword)
        hasErr = true
      }
    }
    if (newMasterPassword === '') {
      setNewMasterPasswordErr(localization.strings.required)
      hasErr = true
    } else if (newMasterPassword === currMasterPassword) {
      setNewMasterPasswordErr(localization.strings.masterPasswordSame)
      hasErr = true
    }
    if (newMasterPasswordAgain === '') {
      setNewMasterPasswordAgainErr(localization.strings.required)
      hasErr = true
    } else if (newMasterPassword !== newMasterPasswordAgain) {
      setNewMasterPasswordAgainErr(localization.strings.newMasterPasswordNotMatch)
      hasErr = true
    }
    if (hasErr) {
      setChanging(false)
      return
    }
    ServerUtils.changeMasterPassword({
      old_signed_account: AesUtils.encryptData(userInfo.account, currMasterPassword, userInfo.account),
      signed_account: AesUtils.encryptData(userInfo.account, newMasterPassword, userInfo.account),
      contents: ContentUtils.mapContents(function(item) {
        return {
          content_id: item.id,
          encrypted_data: ContentUtils.encryptContent(userInfo.account, newMasterPassword, item),
        }
      }),
    }).then(async (resp) => {
      if (!resp.ok) {
        try {
          let respJson = await resp.json()
          if (respJson.errno !== 0) {
            toast.error(ServerUtils.handleError(respJson))
            return
          }
        } catch(e) {

        }
        toast.error(localization.strings.networkErr)
        return
      }
      let respJson = await resp.json()
      if (respJson.errno !== 0) {
        toast.error(ServerUtils.handleError(respJson))
        return
      }
      toast.success(localization.strings.successModify)
      props.logout()
      props.onClose()
    })
  }

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogTitle>{localization.strings.changeMasterPassword}</DialogTitle>
      <DialogContent style={{
        minWidth: '300px',
        overflowY: 'visible',
      }}>
        <h4>{localization.strings.currMasterPassword}</h4>
        <div style={{
          margin: '1rem 0rem 0rem',
        }}>
          <FormInput label={`${localization.strings.currMasterPassword}`} value={currMasterPassword}
            placeholder={localization.strings.inputCurrMasterPasswordHint} onChange={(event) => {
              setCurrMasterPassword(event.target.value)
              setCurrMasterPasswordErr('')
            }} error={currMasterPasswordErr}/>
        </div>
        <h4>{localization.strings.newMasterPassword}</h4>
        <div style={{
          margin: '1rem 0rem 0rem',
        }}>
          <FormInput label={`${localization.strings.newMasterPassword}`} value={newMasterPassword}
            placeholder={localization.strings.inputNewMasterPasswordHint} onChange={(event) => {
              setNewMasterPassword(event.target.value)
              setNewMasterPasswordErr('')
            }} error={newMasterPasswordErr}/>
        </div>
        <div style={{
          margin: '1rem 0rem 0rem',
        }}>
          <FormInput label={`${localization.strings.newMasterPassword}`} value={newMasterPasswordAgain}
            placeholder={localization.strings.inputNewMasterPasswordAgainHint} onChange={(event) => {
              setNewMasterPasswordAgain(event.target.value)
              setNewMasterPasswordAgainErr('')
            }} error={newMasterPasswordAgainErr}/>
        </div>
        <div style={{
          marginTop: '1rem',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
          <Button variant="outlined" color="secondary" onClick={props.onClose} style={{
            width: '0px',
            flex: 1,
          }}>
            {localization.strings.cancel}
          </Button>
          <div style={{width: '0.5rem'}}></div>
          <Button variant="contained" color="primary" style={{
            width: '0px',
            flex: 1,
          }} onClick={changeMasterPassword}>
            {localization.strings.confirm}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default ModalChangeMasterPassword