import AesUtils from './aes.js'

let masterpassEncrypted = ''

export default {
  setMasterPass: function(account, masterpass) {
    if (!masterpass || masterpass === '') {
        masterpassEncrypted = ''
        return
    }
    masterpassEncrypted = AesUtils.encryptData(account, account, masterpass)
  },
  getMasterPass: function(account) {
    if (masterpassEncrypted === '') {
        return ''
    }
    return AesUtils.decryptData(account, account, masterpassEncrypted)
  },
}