import React, {useState, useEffect} from 'react'
import ColorUtils from '../utils/colors'
import ContentUtils from '../utils/content'
import ContentIcon from './ContentIcon'

function ContentItem(props) {
  const [hover, setHover] = useState(false)

  return (
    <div style={{
      backgroundColor: props.active ? ColorUtils.activeItemBg : (hover ? ColorUtils.hoverItemBg : ColorUtils.itemBg),
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      borderRadius: '0.5rem',
      padding: '0.5rem 0.4rem',
      cursor: 'pointer',
      border: `1px solid ${props.active || hover ? ColorUtils.activeItemBorder : ColorUtils.itemBg}`,
      overflowX: 'hidden',
    }} onMouseOver={() => {
      setHover(true)
    }} onMouseLeave={() => {
      setHover(false)
    }} onClick={props.onClick}>
      {
        props.checkBox && (
          <i className={`iconfont ${props.active ? 'icon-check-box-outline' : 'icon-check-box-outline-bl'}`} style={{
            display: 'block',
            width: '2rem',
            lineHeight: '2rem',
            fontSize: '1.3rem',
            marginLeft: '0.5rem',
          }}></i>
        )
      }
      <ContentIcon contentType={props.contentType} size="2rem" fontSize="1.3rem"/>
      <div style={{
        flex: 1,
        color: ColorUtils.textColor,
        marginLeft: '0.5rem',
        overflowX: 'hidden',
        textOverflow: 'ellipsis',
      }}>{props.text}</div>
    </div>
  )
}

export default ContentItem;