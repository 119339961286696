import React, {useState, useEffect, forwardRef, useImperativeHandle} from 'react'
import localization from '../utils/localization'
import FormInput from './FormInput'
import { Button } from '@mui/material'
import ColorUtils from '../utils/colors'

function ExtraForm(props) {
  const [extra, setExtra] = useState(props.extra)
  const [keyName, setKeyName] = useState('')
  const [keyNameErr, setKeyNameErr] = useState('')

  useEffect(() => {
    props.onChange(extra)
  }, [extra])

  return (
    <div>
      {
        typeof extra === 'object' && Object.keys(extra).map((key) => {
          return (
            <div key={key} style={{
              margin: '1rem 0rem 0rem',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}>
              <div style={{
                marginRight: '1rem',
              }}>{key}</div>
              <FormInput wrapContent={true} multiline={true} minRows={1} label={localization.strings.value} value={extra[key]} size="small"
                placeholder={localization.strings.inputValueHint} error={''} onChange={(event) => {
                  let newExtra = Object.assign({}, extra)
                  newExtra[key] = event.target.value
                  setExtra(newExtra)
                }} style={{
                  flex: 1,
                }}/>
              <div style={{
                backgroundColor: ColorUtils.lightRed,
                marginLeft: '1rem',
                color: ColorUtils.red,
                padding: '0.5rem',
                borderRadius: '0.5rem',
                cursor: 'pointer',
              }} onClick={() => {
                let newExtra = Object.assign({}, extra)
                delete newExtra[key]
                setExtra(newExtra)
              }}>
                <i className="iconfont icon-close" style={{
                  fontSize: '1.3rem',
                }}></i>
              </div>
            </div>
          )
        })
      }
      <div style={{
        margin: '1rem 0rem 0rem',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      }}>
        <FormInput wrapContent={true} label={localization.strings.keyName} value={keyName} size="small"
          placeholder={localization.strings.inputKeyNameHint} error={keyNameErr} onChange={(event) => {
            setKeyNameErr('')
            setKeyName(event.target.value)
          }} style={{
            flex: 1,
          }}/>
        <Button variant="outlined" color="secondary" style={{
          marginLeft: '1rem',
        }} onClick={() => {
          if (keyName === '') {
            setKeyNameErr(localization.strings.required)
            return
          } else if (keyName in extra) {
            setKeyNameErr(localization.strings.exists)
            return
          }
          let newExtra = Object.assign({}, extra)
          newExtra[keyName] = ''
          setExtra(newExtra)
          setKeyName('')
        }}>
          {localization.strings.addKey}
        </Button>
      </div>
    </div>
  )
}

export default ExtraForm