import * as OTPAuth from "otpauth";

export default {
  generate: function(secret) {
    let totp = new OTPAuth.TOTP({
      // Algorithm used for the HMAC function.
      algorithm: "SHA1",
      // Length of the generated tokens.
      digits: 6,
      // Interval of time for which a token is valid, in seconds.
      period: 30,
      // Arbitrary key encoded in Base32 or OTPAuth.Secret instance.
      secret: secret.replace(new RegExp(' ', 'g'), ''), // or 'OTPAuth.Secret.fromBase32("NB2W45DFOIZA")'
    });
    return totp.generate();
  },
}