import React, {useState, useEffect, forwardRef, useImperativeHandle} from 'react'
import { Divider } from '@mui/material'
import FormInput from './FormInput'
import localization from '../utils/localization'
import ExtraForm from './ExtraForm'
import ContentUtils from '../utils/content'
import ColorUtils from '../utils/colors'
import toast, { Toaster } from 'react-hot-toast'

const BlockchainForm = forwardRef((props, ref) => {
  let walletDetail = props.contentInfo.detail
  if (!walletDetail) {
    walletDetail = {
      coinName: '',
      address: '',
      pubKey: '',
      priKey: '',
      keyStore: '',
      hintWord: '',
    }
  }
  const [title, setTitle] = useState(props.contentInfo.id ? props.contentInfo.title : '')
  const [titleErr, setTitleErr] = useState('')
  const [coinName, setCoinName] = useState(props.contentInfo.id ? walletDetail.coinName : '')
  const [address, setAddress] = useState(props.contentInfo.id ? walletDetail.address : '')
  const [pubKey, setPubKey] = useState(props.contentInfo.id ? walletDetail.pubKey : '')
  const [priKey, setPriKey] = useState(props.contentInfo.id ? walletDetail.priKey : '')
  const [keyStore, setKeyStore] = useState(props.contentInfo.id ? walletDetail.keyStore : '')
  const [mnemonicWord, setMnemonicWord] = useState(props.contentInfo.id ? walletDetail.hintWord : '')
  const [extra, setExtra] = useState(props.contentInfo.id ? props.contentInfo.extra : {})

  useEffect(() => {
    let walletDetail = props.contentInfo.detail
    if (!walletDetail) {
      walletDetail = {
        coinName: '',
        address: '',
        pubKey: '',
        priKey: '',
        keyStore: '',
        hintWord: '',
      }
    }
    if (props.contentInfo.id) {
      setTitle(props.contentInfo.title)
      setTitleErr('')
      setCoinName(walletDetail.coinName)
      setAddress(walletDetail.address)
      setPubKey(walletDetail.pubKey)
      setPriKey(walletDetail.priKey)
      setKeyStore(walletDetail.keyStore)
      setMnemonicWord(walletDetail.hintWord)
      setExtra(props.contentInfo.extra)
    }
  }, [props.contentInfo])

  useImperativeHandle(ref, () => ({
    checkForm() {
      let hasErr = false
      if (title === '') {
        setTitleErr(localization.strings.required)
        hasErr = true
      }
      return hasErr
    },
    getContentData() {
      return {
        title: title,
        content: JSON.stringify({
          coinName: coinName,
          address: address,
          pubKey: pubKey,
          priKey: priKey,
          keyStore: keyStore,
          hintWord: mnemonicWord,
        }),
        detail: {
          coinName: coinName,
          address: address,
          pubKey: pubKey,
          priKey: priKey,
          keyStore: keyStore,
          hintWord: mnemonicWord,
        },
        type: ContentUtils.getBlockChainType(),
        color: 'blue',
        account: '',
        extra: extra,
      }
    },
  }))

  return (
    <div>
      <form autoComplete="off">
        <div style={{
          margin: '0rem 0rem 0rem',
        }}>
          <FormInput label={localization.strings.title} value={title}
            placeholder={localization.strings.inputTitleHint} error={titleErr} onChange={(event) => {
              setTitle(event.target.value)
              setTitleErr('')
            }}/>
        </div>
        <div style={{
          margin: '1rem 0rem 0rem',
        }}>
          <FormInput label={`${localization.strings.coinName} (${localization.strings.optional})`} value={coinName}
            placeholder={localization.strings.inputCoinNameHint} onChange={(event) => {
              setCoinName(event.target.value)
            }}/>
        </div>
        <div style={{
          margin: '1rem 0rem 0rem',
        }}>
          <FormInput label={`${localization.strings.address} (${localization.strings.optional})`} value={address}
            placeholder={localization.strings.inputAddressHint} onChange={(event) => {
              setAddress(event.target.value)
            }}/>
        </div>
        <div style={{
          margin: '1rem 0rem 0rem',
        }}>
          <FormInput type="password" label={`${localization.strings.pubkey} (${localization.strings.optional})`} value={pubKey}
            placeholder={localization.strings.inputPubKeyHint} onChange={(event) => {
              setPubKey(event.target.value)
            }}/>
        </div>
        <div style={{
          margin: '1rem 0rem 0rem',
        }}>
          <FormInput type="password" label={`${localization.strings.privKey} (${localization.strings.optional})`} value={priKey}
            placeholder={localization.strings.inputPriKeyHint} onChange={(event) => {
              setPriKey(event.target.value)
            }}/>
        </div>
        <div style={{
          margin: '1rem 0rem 0rem',
        }}>
          <FormInput type="password" label={`KeyStore (${localization.strings.optional})`} value={keyStore}
            placeholder={localization.strings.inputKeyStoreHint} onChange={(event) => {
              setKeyStore(event.target.value)
            }}/>
        </div>
        <div style={{
          margin: '1rem 0rem 0rem',
        }}>
          <FormInput type="password" label={`${localization.strings.mnemonic} (${localization.strings.optional})`} value={mnemonicWord}
            placeholder={localization.strings.inputMnemonicHint} onChange={(event) => {
              setMnemonicWord(event.target.value)
            }}/>
        </div>
        <Divider style={{
          margin: '1rem 0rem',
        }}/>
        <ExtraForm extra={extra} onChange={(e) => {
          setExtra(e)
        }}/>
      </form>
    </div>
  )
})

export default BlockchainForm