import React, {useState, useEffect} from 'react';
import { CircularProgress } from '@mui/material';
import ColorUtils from '../utils/colors'
import localization from '../utils/localization'
import ToolsUtils from '../utils/tools'
import easypassLogo from '../assets/logo_256.png'
import scratchpaperLogo from '../assets/logo_scratch_paper.png'
import easynoteLogo from '../assets/icon_easynote.png'
import ServerUtils from '../utils/server'
import toast from 'react-hot-toast'

const logoMap = {
  1: easypassLogo,
  3: scratchpaperLogo,
  6: easynoteLogo,
}

const bodyMap = {
  1: localization.strings.easypassBody,
  3: localization.strings.scratchPayBody,
  6: localization.strings.easynotePayBody,
}

function PayJs(props) {
  const [appID, setAppID] = useState(0)
  const [step, setStep] = useState(0)

  useEffect(() => {
    let appID = parseInt(ToolsUtils.getParameterByName("app_id"))
    let platfromType = ToolsUtils.getParameterByName("platform_type")
    let account = ToolsUtils.getParameterByName("account")
    setAppID(appID)

    if (!appID) {
      setStep(1)
      return
    }

    ServerUtils.postPayJsCashier({
      app_id: appID,
      platform_type: platfromType,
      account: account,
      body: bodyMap[appID],
    }).then(async (resp) => {
      if (!resp.ok) {
        try {
          let respJson = await resp.json()
          if (respJson.errno !== 0) {
            toast.error(ServerUtils.handleError(respJson))
            return
          }
        } catch(e) {

        }
        toast.error(localization.strings.networkErr)
        return
      }
      let respJson = await resp.json()
      if (respJson.errno !== 0) {
        toast.error(ServerUtils.handleError(respJson))
        return
      }
      if (respJson.data === undefined || respJson.data === null) {
        setStep(2)
        return
      }
      window.location.href = respJson.data.pay_url
    })
  }, [])

  return <div style={{
    backgroundColor: ColorUtils.white,
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  }}>
    {
      step === 0 && (
        <>
          <CircularProgress size="5rem" style={{
            display: 'block',
            margin: '0rem auto',
          }}/>
          <div style={{
            textAlign: 'center',
            color: ColorUtils.textColor,
            marginTop: '1rem',
            fontSize: '1.2rem',
          }}>{localization.strings.loading}</div>
        </>
      )
    }
    {
      step === 1 && (
        <>
          <h1 style={{
            textAlign: 'center',
            fontSize: '5rem',
          }}>
            4 0 4
          </h1>
          <h2 style={{
            textAlign: 'center',
          }}>{localization.strings.pageNotFound}...</h2>
        </>
      )
    }
    {
      step === 2 && (
        <>
          <div style={{
            textAlign: 'center',
            margin: '0rem 0rem 1rem',
            fontSize: '5rem',
          }}>
            <img src={logoMap[appID]}/>
          </div>
          <h2 style={{
            textAlign: 'center',
          }}>{localization.strings.hasPaidTitle}</h2>
        </>
      )
    }
  </div>
}

export default PayJs